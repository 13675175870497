import type { TFunction } from "i18next";
import type { InputHTMLAttributes } from "react";
import React, { useMemo } from "react";
import {
  type Control,
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
  type ValidationRule
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { BooleanFieldProps } from "../../../DynamicForm/FormItems/FormField/BooleanField/BooleanField";
import type { TsDropdownProps } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type { FormFieldProps } from "../FormField/FormField";
import { FormField } from "../FormField/FormField";
import type { AsyncSelectProps } from "../FormFields/AsyncSelect/AsyncSelect";
import type { DateInputProps } from "../FormFields/DateInput/DateInput";
import type { ExpressionEditorProps } from "../FormFields/ExpressionEditor/ExpressionEditor";
import type { FileUploadProps } from "../FormFields/FileUpload/FileUpload";
import type { MonthDayDateInputProps } from "../FormFields/MonthDayDateInput/MonthDayDateInput";
import type { NumberInputProps } from "../FormFields/NumberInput/NumberInput";
import type { RadioFieldProps } from "../FormFields/RadioField/RadioField";
import type { TextareaFieldProps } from "../FormFields/TextareaField/TextareaField";
import type { TextInputProps } from "../FormFields/TextInput/TextInput";
import { validateBoolean } from "../utils/validateBoolean";
import { validateExpression } from "../utils/validateExpression";

export type FormInputData<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  name: TName;
  /** alias for name. can be used for buildFieldNameToLabelMap */
  alias?: string;
  required?: boolean;
} & Omit<FormFieldProps, "onChange" | "onBlur" | "onFocus"> &
  (
    | ({ type: "text" | "email" } & Omit<
        TextInputProps,
        "invalid" | "warned" | "onChange"
      >)
    | ({ type: "number" } & Omit<
        NumberInputProps,
        "invalid" | "warned" | "onChange"
      >)
    | ({ type: "textarea" } & Omit<TextareaFieldProps, "invalid" | "warned">)
    | ({ type: "password" } & InputHTMLAttributes<HTMLInputElement>)
    | ({ type: "date" } & Omit<
        DateInputProps,
        "invalid" | "warned" | "onChange"
      >)
    | ({ type: "month-day-date" } & Omit<
        MonthDayDateInputProps,
        "invalid" | "warned" | "onChange"
      >)
    | ({ type: "dropdown" } & Omit<
        TsDropdownProps,
        "invalid" | "warned" | "onChange"
      >)
    | ({ type: "async-select" } & Omit<
        AsyncSelectProps,
        "invalid" | "onChange"
      >)
    | ({ type: "radio" } & Omit<
        RadioFieldProps,
        "invalid" | "warned" | "onChange"
      >)
    | ({ type: "boolean" } & Omit<BooleanFieldProps, "onInput">)
    | ({ type: "file-upload" } & Omit<FileUploadProps, "onChange">)
    | ({ type: "expression" } & Omit<ExpressionEditorProps, "onChange">)
  );

export type FormFieldData<T extends FieldValues> = {
  [key in keyof T extends FieldPath<T> ? keyof T : never]?: FormInputData<
    T,
    key
  > & {
    /** e.g. for Tab-specific error tracking */
    group?: string;
  };
};

interface FormFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends Pick<ControllerProps<TFieldValues, TName>, "rules">,
    Pick<FormFieldProps, "error"> {
  control: Control<TFieldValues>;
  data: FormInputData<TFieldValues, TName>;
}

function getValidateFunctionFromFieldType({
  data,
  rulesRequired,
  t
}: {
  data: FormInputData<FieldValues, FieldPath<FieldValues>>;
  rulesRequired?: string | ValidationRule<boolean>;
  t: TFunction;
}) {
  if (data.type === "boolean" && (rulesRequired || data.required)) {
    return (value: unknown) => validateBoolean(value, t("errors.Required"));
  }

  if (data.type === "expression") {
    return (value: ExpressionEditorProps["value"]) =>
      validateExpression(
        value,
        rulesRequired || data.required ? true : false,
        t
      );
  }

  return undefined;
}

function FormFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  data,
  error,
  rules
}: FormFieldControllerProps<TFieldValues, TName>) {
  const { t } = useTranslation();
  const completeRules = useMemo(
    () => ({
      ...rules,
      required:
        rules?.validate ||
        data?.type === "boolean" ||
        data?.type === "expression"
          ? undefined
          : rules?.required || data?.required,
      min:
        data && data.type === "number" && typeof data.min === "number"
          ? {
              value: data.min,
              message: t("errors.Number.MinNumber", { min: data.min })
            }
          : undefined,
      max:
        data && data.type === "number" && typeof data.max === "number"
          ? {
              value: data.max,
              message: t("errors.Number.MaxNumber", { max: data.max })
            }
          : undefined,
      validate: rules?.validate
        ? rules.validate
        : getValidateFunctionFromFieldType({
            data,
            rulesRequired: rules?.required,
            t
          })
    }),
    [rules, data, t]
  );

  if (!data) {
    console.error(
      "No data was provided to FormFieldController. Check that data exists for this field."
    );
    return null;
  }

  return (
    <Controller<TFieldValues, TName>
      control={control}
      name={data.name}
      render={({ field }) => (
        <FormField {...data} {...field} error={error} name={data.name} />
      )}
      rules={completeRules}
    />
  );
}

export { FormFieldController, FormFieldControllerProps };
