import { useQuery } from "@tanstack/react-query";
import api from "../api";
import type { ParkabrechnungDataResponse } from "../components/Parkabrechnung/ParkabrechnungOverview/ParkabrechnungTable/ParkabrechnungTable";
import urls from "../urls";
import type { Frequency, Unit } from "../utils/enums";

export interface EnergyDisplayDataPayload {
  energyDataAcquisitions: number[] | undefined;
  firstDate: string | null | undefined;
  lastDate: string | null | undefined;
  frequency: Frequency;
  unit: Unit;
}

export function useEnergyDisplayData({
  enabled,
  payload
}: {
  enabled: boolean;
  payload: EnergyDisplayDataPayload;
}) {
  return useQuery({
    queryKey: ["energy-data-display-data", payload],
    queryFn: () => fetchEnergyDataDisplayData(payload),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled
  });
}

async function fetchEnergyDataDisplayData(
  payload
): Promise<ParkabrechnungDataResponse> {
  const response = await api.post(urls.api.energyDataDisplayData(), payload);
  return {
    serieses: response.data.serieses,
    summary: response.data.summary,
    acquisitionsWithLowerFrequency:
      response.data.acquisitionsWithLowerFrequency,
    missingData: response.data.missingData
  };
}
