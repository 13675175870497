/* Add new feature flags here.
 * This constant should always reflect the current state of created feature flags,
 * switches and samples in the database.
 *
 * The keys are the plain text flag names as they appear in the Feature Flags API.
 * For example: "my_feature_flag" -> "myFeatureFlag"
 */

export enum Flag {
  // test values for Jest and Storybook tests (not in DB)
  TestFlag = "testFlag",
  TestFlag2 = "testFlag2",

  QuickSiteJump = "quickSiteJump",
  siteSetupAssistant = "siteSetupAssistant",
  showDataGaps = "showDataGaps",
  replacePlotly = "replacePlotly",
  blockRueckerstattungP6Eeg = "blockRueckerstattungP6Eeg"
}

export type FeatureFlags = Record<Flag, boolean>;
