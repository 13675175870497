import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useParams } from "react-router";
import { useManagerVariant } from "../../hooks/useManagerVariant";
import { ROUTES } from "../../routes";
import {
  DEFAULT_SUBPAGE as DEFAULT_DELIVERY_OVERVIEW_SUBPAGE,
  DeliveryOverview
} from "../DeliveryOverview/DeliveryOverview";
import { DirektvermarktungView } from "../Direktvermarktung/DirektvermarktungView";
import {
  DEFAULT_SUBPAGE as DEFAULT_ENERGY_DATA_SUBPAGE,
  EnergyDataView
} from "../EnergyData/EnergyDataView/EnergyDataView";
import { GuaranteeOfOriginPage } from "../GuaranteeOfOrigin/GuaranteeOfOriginPage";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import {
  DEFAULT_SUBPAGE as DEFAULT_MSM_PROJECT_VIEW_SUBPAGE,
  MsmProjectView
} from "../MsmProjectView/MsmProjectView";
import {
  DEFAULT_SUBPAGE as DEFAULT_PARAGRAPH6_SUBPAGE,
  Paragraph6View
} from "../Paragraph6/Paragraph6View";
import {
  DEFAULT_SUBPAGE as DEFAULT_PARK_ABRECHNUNG_SUBPAGE,
  ParkabrechnungView
} from "../Parkabrechnung/ParkabrechnungView";
import { PerformanceMonitoring } from "../PerformanceMonitoring/PerformanceMonitoring";
import { RevenueMonitoringView } from "../RevenueMonitoring/RevenueMonitoringView";
import { Mode } from "../Todos/common";
import { Todos } from "../Todos/Todos";
import { TodosV2 } from "../TodosV2/TodosV2";

function ManagerVariantRoutes() {
  const { projectId } = useParams();
  const { variantId, isLoading, error } = useManagerVariant(projectId);
  const { t } = useTranslation();

  const redirectPath = ROUTES.managerProjectList;

  const customErrors = {
    404: t("errors.ProjectNotFound")
  };

  return (
    <LoadOrError
      customErrors={customErrors}
      error={error}
      errorRedirectTo={redirectPath}
      loading={isLoading}
    >
      <Routes>
        <Route
          element={<Navigate replace to={DEFAULT_MSM_PROJECT_VIEW_SUBPAGE} />}
          index
        />
        <Route element={<MsmProjectView />} path=":projectPage/*" />
        <Route path="lieferungen-uebersicht">
          <Route
            element={
              <Navigate replace to={DEFAULT_DELIVERY_OVERVIEW_SUBPAGE} />
            }
            index
          />
          <Route
            element={
              <DeliveryOverview
                projectId={projectId ?? undefined}
                variantId={variantId ?? undefined}
              />
            }
            path=":tab/*"
          />
        </Route>
        <Route
          element={
            <>{variantId && <GuaranteeOfOriginPage variantId={variantId} />}</>
          }
          path="herkunftsnachweis/*"
        />
        <Route path="parkabrechnung">
          <Route
            element={<Navigate replace to={DEFAULT_PARK_ABRECHNUNG_SUBPAGE} />}
            index
          />
          <Route
            element={
              <ParkabrechnungView
                projectId={projectId ?? ""}
                variantId={variantId ?? undefined}
              />
            }
            path=":tab/*"
          />
        </Route>
        <Route path="paragraph6">
          <Route
            element={<Navigate replace to={DEFAULT_PARAGRAPH6_SUBPAGE} />}
            index
          />
          <Route
            element={
              <>{variantId && <Paragraph6View variantId={variantId} />}</>
            }
            path=":tab/*"
          />
        </Route>
        <Route element={<DirektvermarktungView />} path="direktvermarktung/*" />
        <Route element={<RevenueMonitoringView />} path="erloesmonitoring/*" />
        <Route path="energiedaten">
          <Route
            element={<Navigate replace to={DEFAULT_ENERGY_DATA_SUBPAGE} />}
            index
          />
          <Route path=":tab">
            <Route
              element={
                <>{variantId && <EnergyDataView variantId={variantId} />}</>
              }
              index
            />
            <Route
              element={
                <>{variantId && <EnergyDataView variantId={variantId} />}</>
              }
              path="*"
            />
          </Route>
        </Route>
        <Route
          element={<>{variantId && <TodosV2 variantId={variantId} />}</>}
          path="aufgaben/*"
        />
        <Route
          element={
            <>
              {variantId && (
                <Todos mode={Mode.Onboarding} variantId={variantId} />
              )}
            </>
          }
          path="onboarding/*"
        />
        <Route
          element={<PerformanceMonitoring projectId={projectId ?? ""} />}
          path="performance-monitoring/*"
        />
      </Routes>
    </LoadOrError>
  );
}

export { ManagerVariantRoutes };
