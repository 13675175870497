import { type LineChartSeries } from "@mantine/charts";
import _ from "lodash";
import { DateTime } from "luxon";
import { type Data } from "plotly.js";
import React, { useMemo } from "react";
import Plot from "react-plotly.js";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { seriesColors } from "../../../../mantine/charts/mantine.constants";
import { UNITS } from "../../../../utils/constants";
import { DATE_TIME_DISPLAY_WITHOUT_SECONDS_FORMAT } from "../../../../utils/dates/dates.constants";
import type { Unit } from "../../../../utils/enums";
import { MantineBarChart } from "../../../Charts/BarChart/MantineBarChart";
import { MantineLineChart } from "../../../Charts/LineChart/MantineLineChart";
import type { CounterData } from "../../../DataSheet/DataSheet";

interface AcquisitionPlotProps {
  acquisitionSerieses: CounterData;
  selectedUnitValue: Unit;
}

function AcquisitionPlot({
  acquisitionSerieses,
  selectedUnitValue
}: AcquisitionPlotProps) {
  const { featureFlags } = useFeatureFlags();

  const barDataIds = Object.keys(acquisitionSerieses.header);
  const barSeries = [
    {
      name: "value"
    }
  ];
  const barData = barDataIds.map((id) => ({
    category: acquisitionSerieses.labels?.[id]?.replaceAll("<br>", " ") || id,
    value: acquisitionSerieses.values[id][0]
  }));

  const selectedUnit = UNITS[selectedUnitValue];
  const yAxisDescription = selectedUnit.physicalSize;
  const yAxisTitle = `${yAxisDescription} [${selectedUnitValue}]`;
  const valueCount = acquisitionSerieses.index
    ? acquisitionSerieses.index.length
    : 0;

  // Remove the following code down to the render method when finally kicking out Plotly
  const PLOTLY_dataIds = Object.keys(acquisitionSerieses.header);

  const PLOTLY_layout = {
    showLegend: true,
    margin: { t: 18 },
    yaxis: { title: { text: yAxisTitle } }
  };

  let PLOTLY_traces: Array<Data> = [];

  if (valueCount === 1) {
    // build bar plot
    const xs = PLOTLY_dataIds.map((id) =>
      acquisitionSerieses.labels ? acquisitionSerieses.labels[id] : "Unbekannt"
    );
    const ys = PLOTLY_dataIds.map((id) => acquisitionSerieses.values[id][0]);
    PLOTLY_traces = [
      {
        type: "bar",
        x: xs,
        y: ys
      }
    ];
  } else if (valueCount > 1) {
    // build line plot
    PLOTLY_layout["xAxis"] = { title: { text: "Zeitraum" } };
    const xs = _.unzip(acquisitionSerieses.index)[0];
    PLOTLY_traces = PLOTLY_dataIds.map((id) => {
      return {
        name: acquisitionSerieses.labels
          ? acquisitionSerieses.labels[id]
          : "Unbekannt",
        x: xs,
        y: acquisitionSerieses.values[id],
        type: "scatter",
        mode: "lines",
        connectgaps: false,
        line: {
          shape: "hv"
        }
      };
    });
  }

  const dataIds = Object.keys(acquisitionSerieses.header);
  const series: Array<LineChartSeries & { color: string }> = dataIds.map(
    (id, idIndex) => ({
      name: id,
      label:
        acquisitionSerieses.labels?.[id].replaceAll("<br>", " ") || "Unbekannt",
      color: seriesColors[idIndex % seriesColors.length]
    })
  );
  const data = useMemo(
    () =>
      acquisitionSerieses.index.map((index, indexIndex) => ({
        date: DateTime.fromISO(index[0]).toMillis(),
        ...dataIds.reduce(
          (result, id) => ({
            ...result,
            [id]: acquisitionSerieses.values[id][indexIndex]
          }),
          {}
        )
      })),
    [acquisitionSerieses, dataIds]
  );

  if (featureFlags.replacePlotly) {
    if (valueCount === 1) {
      return (
        <MantineBarChart
          data={barData}
          dataKey="category"
          h={500}
          mb="xl"
          series={barSeries}
          tickLine="y"
          yAxisLabel={yAxisTitle}
        />
      );
    } else {
      return (
        <MantineLineChart
          data={data}
          dataKey="date"
          h={500}
          series={series}
          withLegend
          xAxisProps={{
            type: "number",
            tickFormatter: (timeStamp) =>
              DateTime.fromMillis(timeStamp).toFormat(
                DATE_TIME_DISPLAY_WITHOUT_SECONDS_FORMAT
              )
          }}
          yAxisLabel={yAxisTitle}
        />
      );
    }
  } else {
    return (
      <Plot
        data={PLOTLY_traces}
        layout={PLOTLY_layout}
        style={{ width: "100%" }}
        useResizeHandler={true}
      />
    );
  }
}

export { AcquisitionPlot };
