import type { ChangeablePrice } from "../components/DynamicForm/FormItems/FormField/ChangeablePriceField/ChangeablePriceField";
import type { ChangeableSpotMarketFormula } from "../components/DynamicForm/FormItems/FormField/ChangeableSpotMarketFormulaFieldStatic/ChangeableSpotMarketFormulaFieldStatic";
import type { FieldsDict } from "../components/OptionsForm/OptionsForm";
import type {
  TodoSystemDecision,
  TodoUserDecision
} from "../components/Todos/common";
import type { components, FoerderRegime, MixKind } from "../types/api.types";
import type {
  Frequency,
  MeasurementType,
  Medium,
  MspCategory,
  MspImportFrequency,
  SupplyType,
  Unit
} from "./enums";

export type User = components["schemas"]["User"];
export type ExtendedUser = components["schemas"]["ExtendedUser"];
export type CurrentUser = components["schemas"]["CurrentUser"];

export type ProjectMinimal = {
  id: string;
  name: string;
  variants: Array<VariantMinimal>;
};

export interface ProjectMinimalWithManagerVariant extends ProjectMinimal {
  managerVariant: VariantMinimal;
}

export type Project = ProjectMinimal & {
  managers: Array<ExtendedUser>;
  customer: number;
  reminderMailsActive: boolean;
  active: boolean;
  performanceMonitoringActive: boolean;
  parkabrechnungActive: boolean;
  optichargeActive: boolean;
  sapExportActive: boolean;
  documentsInOnboardingAssistantActive: boolean;
  customerMbkWriteAccessActive: boolean;
  manualBlockRueckerstattungP6EegActive: boolean;
};

export type CreateManagerProject = {
  name: string;
  demo: boolean;
  customer: number;
};

export type VariantId = number;
export type VariantMinimal = {
  id: VariantId;
  isManagerVariant: boolean;
  name: string;
};

export interface Variant extends VariantMinimal {
  bundleConsumers: boolean;
  disregardEegLevy: boolean;
}

export interface SiteBasic {
  id: number;
  global_id: string;
  name: string;
}

export interface ConsumerConsumptionShare {
  dateRangeConsumptionShare?: number | null;
  consumer: number;
  consumerName: string;
  consumptionPercentile?: string | null;
}

export interface DateRangeConsumptionShare {
  id?: number | null;
  firstDate: string | null;
  lastDate: string | null;
  consumerConsumptionShares: Array<ConsumerConsumptionShare>;
}
export interface ConsumptionShareModel {
  id?: number;
  isConfirmed?: boolean;
  meter?: number;
  dateRangeConsumptionShares?: Array<DateRangeConsumptionShare>;
}

export type SiteRaw = components["schemas"]["Site"];

export interface Site extends SiteRaw {
  id: number;
}

export type SiteCategories = components["schemas"]["SiteCategories"];
export type SiteCategoriesResponse =
  components["schemas"]["SiteCategoriesResponse"];
export type SiteCategoriesUpdate =
  components["schemas"]["SiteCategoriesUpdate"];

export interface SiteCategoriesWithSiteFeatures extends SiteCategoriesUpdate {
  invoicing_active: boolean;
}

export type WizardDetail = {
  displayPhase: boolean;
  done: boolean;
  name: string;
  objectsAreMissing: boolean;
  missingData: Array<WizardMissingData>;
  content: string;
  exclusiveOrFields: Array<Array<string>>;
};

type EnergyWizardDetail = WizardDetail & {
  blocked: boolean;
};

export type WizardMissingData = {
  componentName: MissingDataComponentName;
  id: number;
  missingDataType: MissingDataTypes;
  missingFields: Array<string>;
  name: string;
};

export enum MissingDataTypes {
  DEFAULT = "default",
  INVOICE = "invoice",
  REGULATORY = "regulatory",
  TAX = "tax",
  ANLAGE = "anlage",
  MEASUREMENT = "measurement",
  EEG = "eeg",
  KWK = "kwk",
  METERING_CONCEPT = "metering_concept"
}

export enum MissingDataComponentName {
  PERSON = "Person",
  GENERATOR = "Generator",
  METER = "Meter",
  CONNECTION = "Connection",
  SITE = "Site",
  DELIVERY = "Delivery",
  CONSUMER = "Consumer",
  MARKET_LOCATION = "MarketLocation",
  METERING_LOCATION = "MeteringLocation",
  MIETERSTROM_CONTRACT = "MieterstromContract"
}

export interface GridFees {
  energyPrice: number;
  powerPrice?: number;
}

export type MSBDetail = WizardDetail & {
  hasMissingDocuments: boolean;
};

export interface SiteEnergyWizardPhases {
  meters: EnergyWizardDetail;
}

export type SiteEnergyWizard = {
  id: number;
  enabled: boolean;
  phases: SiteEnergyWizardPhases;
};

export type OnboardingAssistantDocument = {
  label: string;
  todoFiles: Array<TodoFile>;
};
export type OnboardingAssistantDocuments = Array<OnboardingAssistantDocument>;
export type SiteWizardOnboardingStatus = {
  onboardingDone: boolean;
  todoStatus: {
    regulatoryTodo: boolean;
    msbDataTodo: boolean;
    mbkTodo: boolean;
  };
};

export interface SiteWizardPhases {
  configuration: WizardDetail;
  msbauthority: MSBDetail;
  regulatory: WizardDetail;
  invoices: WizardDetail;
  meteringConcept: WizardDetail;
}

export type SiteWizard = {
  id: number;
  enabled: boolean;
  onboardingDone: SiteWizardOnboardingStatus;
  phases: SiteWizardPhases;
};

export type SitePlausibility =
  components["schemas"]["SiteMeteringPlausibilityResponse"];

export type MeterPlausibility =
  components["schemas"]["MeteringPlausibilityResponse"];

export interface Component {
  id: number;
  name: string;
}

export enum DeliveryType {
  DirectDelivery = "direct_delivery",
  ForwardingDelivery = "forwarding_delivery"
}

export enum PriceModel {
  PassingOn = "passing_on",
  FixedPrice = "fixed_price"
}

export interface Delivery extends Omit<Component, "id"> {
  id: string;
  automaticallyDetermineGridFees: boolean;
  identificationGraphic: string | null;
  concessionFees: Array<ChangeablePrice>;
  contract: string;
  deliveryKind: DeliveryType;
  electricityTax: number | null;
  generators: Array<number>;
  lossesTransfer: boolean;
  gridUsageBillingModel: string;
  gridUsagePowerPrices: Array<ChangeablePrice>;
  priceModel: PriceModel;
  site: number | null;
  workingPrices: Array<ChangeablePrice>;
  workingPricesPrognosis: Array<ChangeablePrice>;
  mixKind: MixKind;
  greenCountries: string | null;
  nuclearPercentage: number | null;
  coalPercentage: number | null;
  gasPercentage: number | null;
  otherFossilPercentage: number | null;
  tenantPercentage: number | null;
  renewableNotPromotedPercentage: number | null;
  renewablePromotedPercentage: number | null;
  yearMix: number | null;
  emissionCo2: number | null;
  radioactiveWaste: number | null;
  useSpotMarketPrices: boolean;
  minimumPurchaseQuantityApplicable: boolean;
  minimumPurchaseQuantity: number | null;
  spotMarketPrices: Array<ChangeableSpotMarketFormula>;
}
export enum YesNoChoice {
  YES = "yes",
  NO = "no"
}

export enum RepresentationType {
  AuthorizedRepresentativeSection80AO = "authorized_representative_section_80_ao",
  AuthorizedRepresentativeSection80AORelativeAccountantSection3P4StBerG = "authorized_representative_section_80_ao_relative_accountant_section_3_4_StBerG",
  UniversalSuccessor = "universal_successor",
  ManagerGroupsWithoutLegalCapacity = "manager_groups_without_legal_capacity",
  ManagerEstatesSection8626BGB = "manager_estates_section_86_26_BGB",
  LegalRepresentativeLegalPerson = "legal_representative_legal_person",
  LegalRepresentativeNaturalPerson = "legal_representative_natural_person",
  OtherRepresentative = "other_representative",
  TaxRepresentativeSection214AO = "tax_representative_section_214_ao",
  TaxOperationsManagerSection62Paragraph1StG = "tax_operations_manager_section_62_paragraph_1_StG",
  AcquiringLegalEntity = "acquiring_legal_entity",
  AuthorizedForDisposalSection35AO = "authorized_for_disposal_section_35_ao",
  AssetManagerSection34Paragraph1AO = "asset_manager_section_34_paragraph_1_ao",
  RepresentativeSection81AO = "representative_section_81_ao"
}

interface ActiveCompany {
  id: number;
  name: string;
}
interface SiteCompanies {
  activeCompanies: Array<ActiveCompany>;
  id: number;
}
export interface ActiveCompanies {
  id: number;
  sites: Array<SiteCompanies>;
}

export interface Person extends Component {
  address: string;
  addressExtra: string;
  besondereAusgleichsregelungEeg: number | null;
  besondereAusgleichsregelungKwk: number | null;
  besondereAusgleichsregelungOffshore: number | null;
  city: string;
  color: string;
  companyNumber: string;
  contact: string | null;
  customsOffice: string | null;
  dinEn16247_1Installed: string;
  dinEnIso_50001Installed: string;
  electricityConsumptionOver_50GwhPerYear: string | null;
  electricityOrEnergyTaxRelief_10StromstgOr_55Energiestg: string | null;
  emasInstalled: string;
  energyTaxRelief_51Energiestg: string | null;
  energyTaxRelief_54Energiestg: string | null;
  forestryOrAgriculture: string;
  generalPartnerName: string | null;
  generalPartnerCity: string | null;
  generalPartnerCourt: string | null;
  generalPartnerRegisterNumber: string | null;
  generalPartnerCeo: string | null;
  invoiceRecipient: string;
  invoiceSuppliedAddressIsGeneralAddress: YesNoChoice | null;
  invoiceSuppliedAddress: string | null;
  invoiceSuppliedAddressExtra: string | null;
  invoiceSuppliedBank: string | null;
  invoiceSuppliedBic: string | null;
  invoiceSuppliedCity: string | null;
  invoiceSuppliedCustomerNumber: string | null;
  invoiceSuppliedIban: string | null;
  invoiceSuppliedZip: string | null;
  invoiceSupplierBank: string | null;
  invoiceSupplierBic: string | null;
  invoiceSupplierCeo: string | null;
  invoiceSupplierCourt: string | null;
  invoiceSupplierCreditorId: string | null;
  invoiceSupplierEmail: string | null;
  invoiceSupplierFax: string | null;
  invoiceSupplierIban: string | null;
  invoiceSupplierLocation: string | null;
  invoiceSupplierLogo: string | null;
  invoiceSupplierPhone: string | null;
  invoiceSupplierIsGbr: YesNoChoice | null;
  invoiceSupplierRegisterNumber: string | null;
  invoiceSupplierTaxId: string | null;
  invoiceSupplierUrl: string | null;
  isErloesmonitoringActive: boolean;
  isInMieterstromProject: boolean;
  isInternalSupplier: string;
  isRegisteredAsEltvuAtAmprion: string | null;
  isRegisteredAsEltvuAtHertz: string | null;
  isRegisteredAsEltvuAtTennet: string | null;
  isRegisteredAsEltvuAtTransnet: string | null;
  isStrompbgCalculatorActive: boolean;
  isParagraph_6EegActive: boolean;
  isYearlyReportOfTaxReliefsRequired: string | null;
  makesUseOfBeihilfenStrompreiskompensation: string | null;
  makesUseOfBesondereAusgleichsregelung: string | null;
  makesUseOfExemptsOver_500_000PerYear: string | null;
  manufacturingIndustry: string;
  mastrNummerMarktakteur: string | null;
  unternehmenInSchwierigkeiten: string | null;
  pensionSalariesPaid: number;
  taxFormsRepresentationType: RepresentationType | null;
  taxFormsCompanyRepresentedBy: number | null;
  shouldRegulatoryDutiesBeChecked: boolean;
  isSolarContractor: boolean;
  smallMediumEnterprise: string | null;
  taxFormsSubmissionInOwnName: boolean;
  supplierAccordingToStromstg: string | null;
  supplierLicenseValidityDate: string | null;
  supplyElectricityTaxMonthly: string;
  taxFormsApplicantAddress: string;
  taxFormsApplicantCity: string;
  taxFormsApplicantLegalStructure: string | null;
  taxFormsApplicantName: string;
  taxFormsApplicantOtherCity: string;
  taxFormsApplicantResidenceOrOffice: string;
  taxFormsApplicantZip: string;
  taxFormsBankAccountBic: string;
  taxFormsBankAccountHolder: string;
  taxFormsBankAccountIban: string;
  taxFormsContactEmail: string;
  taxFormsContactFax: string;
  taxFormsContactName: string;
  taxFormsContactPhone: string;
  taxFormsContactWebsite: string;
  variant: number;
  zip: string;
}

export interface Generator extends Component {
  anlageStromstg: number;
  approvalDate: string | null;
  buildingInstalled: string | null;
  commissioning: string | null;
  connection: number;
  decommissioning: string | null;
  directMarketingFee: number | null;
  marketPremiumViaTendering: boolean | null;
  einheit: string | null;
  eligibleForNonBuildingInstalledFeedinTariff: string | null;
  feedinTariffDeprecated: number | null;
  feedinTariffNonPv: number | null;
  applicableValuePv: number | null;
  marketValueEeg: number | null;
  initialInstalledCapacity: number | null;
  installedCapacity: number;
  isCombined: boolean;
  kwkgDirectDeliverySubsidy: string | null;
  loadprofile: Loadprofile;
  modernized: string | null;
  operatorHasChangedSince_01_01_2017: string | null;
  ownConsumptionBefore_2014_08_01: string | null;
  ownConsumptionBefore_2015_01_01: string | null;
  ownConsumptionSubsidy: string | null;
  person: number;
  registeredForMieterstrom: string | null;
  residentialBuildingInstalled: string | null;
  site: number;
  siteName: string;
  type: GeneratorType;
  typeLabel: GeneratorTypeLabel;
  yearlyEnergy: number;
}

export enum GeneratorType {
  EEGAnlageSolar = "EEGAnlageSolar",
  EEGAnlageBiomasse = "EEGAnlageBiomasse",
  EEGAnlageWind = "EEGAnlageWind",
  EEGAnlageWasser = "EEGAnlageWasser",
  EEGAnlageGSGK = "EEGAnlageGSGK",
  KWKAnlageVerbrennung = "KWKAnlageVerbrennung",
  KWKAnlageBiomasse = "KWKAnlageBiomasse",
  KWKAnlageGSGK = "KWKAnlageGSGK",
  Sonstige = "Sonstige"
}

export enum GeneratorTypeLabel {
  EEGAnlageSolar = "Photovoltaik",
  EEGAnlageBiomasse = "Biomasse (fest, flüssig, gasförmig) - EEG",
  EEGAnlageWind = "Windkraft",
  EEGAnlageWasser = "Wasserkraft",
  EEGAnlageGSGK = "Geothermie, Solarthermie, Grubengas, Klärschlamm (GSGK) - EEG",
  KWKAnlageVerbrennung = "KWK fossil (u. a. Erdgas-BHKW)",
  KWKAnlageBiomasse = "Biomasse - nicht EEG",
  KWKAnlageGSGK = "Geothermie, Solarthermie, Grubengas, Klärschlamm (GSGK) - nicht EEG",
  Sonstige = "Sonstige"
}

export interface CreatedGenerator {
  generators: Array<Generator>;
  warnings: Array<string>;
}

export interface Consumer extends Component {
  connection: number;
  electricity_price: number;
  eligibleForTaxReliefForParticularProcesses: "yes" | "no";
  loadprofile: Loadprofile;
  operational_purpose: "yes" | "no";
  person: number;
  site: number;
  type: string;
  type_label: string;
  yearlyEnergy: number;
}

export interface Storage extends Component {
  commissioning: string | null;
  connection: number;
  decommissioning: string | null;
  efficiency: string | null;
  electricityPrice: number;
  eligibleForTaxReliefForParticularProcesses: string;
  installedCapacity: number;
  person: number;
  site: number;
  standbyConsumption: number;
  storageSize: number;
  usageStrategy: string | null;
}

export enum MeteringDirection {
  VerbrauchEntnahme = "consumption_feedout",
  Zweirichtungszähler = "bidrectional",
  ErzeugungEinspeisung = "generation_feedin",
  VierQuadrantenZähler = "four_quadrant"
}

export interface TextBlockElement {
  id: number;
  site: number;
  text: string;
  x: number;
  y: number;
  fontWeight: number;
  fontSize: number;
}
export interface ContainerElement {
  color: string;
  height: number;
  id: number;
  site: number;
  width: number;
  data: {
    type: string;
    color: string;
  };
  x: number;
  y: number;
}

export interface Connection extends Component {
  connectingParty: number;
  ghostNode: GhostNode;
  gridOperator: number;
  gridOperatorMarktakteur: string;
  gridOperatorName: string;
  site: number;
  supplyContractHeldBy: number;
  transmissionSystemOperator: string;
  transmissionSystemOperatorMarktakteur: string;
  voltage: string;
  meteringConcept: number | null;
  supplyType: SupplyType | null;
}

export interface GasConnection extends Component {
  calibrationValidUntil: string | null;
  conversionFactor: number;
  frequency: Frequency;
  isCalibrated: string;
  marketLocationFeedin: number | null;
  marketLocationFeedout: number | null;
  measurementType: MeasurementType;
  medium: string;
  meteringDirection: string;
  meteringDirectionLabel: string;
  meteringLocation: number | null;
  number: number | null;
  site: number;
  subMeteringSystem: string;
}

export interface Meter extends Component {
  calibrationValidUntil: string | null;
  conversionFactor: number;
  frequency: Frequency;
  isCalibrated: "yes" | "no";
  consumptionShare: number | null;
  marketLocationFeedin: number | null;
  marketLocationFeedout: number | null;
  measurementType: MeasurementType;
  medium: Medium;
  meteringDirection: MeteringDirection;
  meteringDirectionLabel: string;
  meteringLocation: number | null;
  number: number | null;
  site: number;
  subMeteringSystem: string;
  globalId: string;
}

export interface GhostNode {
  site: number;
  id: number;
  name: string | null;
}

export interface MeteringServiceProvider {
  id: number;
  marktpartneridentifikationsnummer: string;
  medium: string | null;
  name: string;
  category: MspCategory;
  email: string | null;
  notes: string | null;
  importFrequency: MspImportFrequency;
}

export interface Loadprofile {
  id: number;
  name: string;
  yearlyEnergy: number;
}

export interface Todo {
  category: TodoCategory;
  cluster: RegulatoryCluster;
  priority: Priority;
  description: string | null;
  status: TodoStatus;
  dueDate: string | null; // "31.12.2020"
  fulfillFor: string | null;
  id: number;
  label: string;
  overdue: boolean;
  person: string | null;
  project: string;
  regulatoryDuty: RegulatoryDuty | null;
  responsible: number | null;
  systemCreated: boolean;
  systemDecision: TodoSystemDecision | null;
  todoFiles: Array<TodoFile>;
  todoLogs: Array<TodoLog>;
  userDecision: TodoUserDecision;
  variant: number;
  documentProvided: boolean;
  group: TodoGroup;
}

export enum TodoGroup {
  ProjectPreparation = "project_preparation",
  RegulatoryDuties = "regulatory_duties"
}

export interface MSBFiles {
  id: number;
  todoFiles: Array<TodoFile>;
}

export enum TodoCategory {
  Onboarding = "onboarding",
  RegulatoryDuty = "regulatory_duty",
  Other = "other"
}

export enum RegulatoryCluster {
  Onboarding = "onboarding",
  RegulatoryDuty = "regulatory_duty",
  Other = "other",
  AssessmentProfitabilityAndFeasibility = "assessment_profitability",
  PlanningAndRealisation = "planning_and_realisation",
  MeteringConceptAndOperations = "metering_concept_and_ops",
  DirectMarketing = "direct_marketing",
  PowerSupplyToTenantAndCustomer = "power_supply_to_tenant",
  ProjectFinalisation = "project_finalisation"
}

export enum TodoStatus {
  Open = "open",
  Done = "done",
  NotRelevant = "not_relevant"
}

export enum Priority {
  High = "high",
  Medium = "medium",
  Low = "low",
  Unknown = "unknown"
}

export interface TodoFile {
  createdBy: ExtendedUser | null;
  creationDatetime: string; // "23.03.2021 19:04:20"
  id: number;
  name: string;
  revoked?: string | null;
}

export interface TodoLog {
  creationDatetime: string; // "22.03.2021 17:08:28"
  description: string;
  user: string;
}

export interface TodoNote {
  author: { id: number; name: string };
  created: string; // "22.03.2021 17:08:28"
  id: number;
  internal: boolean;
  note: string;
  todo: number;
}

export type RegulatoryDutiesList = {
  allDuties: Array<RegulatoryDuty>;
  applicableDutyIds: Array<number>;
};

export enum RegulatoryDutyCategory {
  OwnConsumption = "Eigenverbrauch",
  DirectDelivery = "Direktlieferung",
  PlantOperation = "Anlagenbetrieb",
  PeakBalancingTax = "StromSt-Spitzenausgleich",
  PowerConsumption = "Stromverbrauch",
  Premium = "Premium",
  ElectricityPriceBrake = "Strompreisbremse",
  EnergyPriceBrake = "Energiepreisbremse",
  Other = "Sonstiges"
}

export interface RegulatoryDuty {
  id: number;
  name: string;
  description: string;
  obligor: string;
  category: RegulatoryDutyCategory;
  obligee: string;
  fulfillmentProcess: string;
  deadline: string;
  recurring: boolean;
  legalBasis: string;
  additionalInformation: string;
  events: Array<RegulatoryDutyEvent>;
  slug?: string;
}

export type GeneratorDataStatus =
  | "overwriteSuccess"
  | "overwriteFail"
  | "getPossibleChangesFail";

export interface RegulatoryDutyEvent {
  title: string;
  rrule: string;
}

export interface ReversalInvoice {
  id: string;
  file: string;
  state: InvoiceState;
}

export interface InvoiceResponse {
  invoiceData?: InvoiceData;
  file: string;
  id: string;
  state: InvoiceState;
  variant: number;
  sentViaEmail: boolean;
  reversalInvoice?: ReversalInvoice;
}

export interface InvoiceData {
  billingPeriodEnd: string; // e.g. "31.12.2021"
  billingPeriodStart: string; // e.g. "31.12.2021"
  contract: string;
  id: string;
  number: string;
  site: number;
  isAdvanceInvoice: boolean;
  isCorrectionInvoice: boolean;
}

// Corresponds to class paragraph_6_eeg.enums.CreditState
export enum InvoiceState {
  ReadyToSend = "ready_to_send",
  Cancelled = "cancelled",
  Sent = "sent",
  Paid = "paid"
}

export enum CancellationState {
  ReadyToSend = "ready_to_send",
  Sent = "sent",
  Received = "received"
}

export interface AdvancePaymentsPlan {
  id: string;
  variant: number;
  file: string;
  advancePaymentsPlanData: AdvancePaymentsPlanData;
}

export interface AdvancePaymentsPlanData {
  id: string;
  contract: string;
  site: number;
  paymentPlanBegin: string;
  paymentPlanEnd: string;
}

export enum MeasuringPointPriceOption {
  None = "none",
  Total = "total",
  PerMeter = "per_meter"
}

export enum PaymentIntervalAndBillingMethod {
  AnnualBasedOnMeasurements = "annual_based_on_measurements",
  MonthlyAdvancePaymentsAndAnnualFinalInvoice = "monthly_advance_payments_and_annual_final_invoice",
  MonthlyBasedOnMeasurements = "monthly_based_on_measurements"
}
export interface MeteringConceptByPersons {
  url: string;
  persons: Array<MeteringConceptForPerson>;
  hasWarnings: boolean;
}
export interface Contract {
  allowanceSendInvoiceViaMail: boolean;
  automaticSendInvoiceActive: boolean;
  addBasePrice: boolean;
  addPriceMeasuringPoint: MeasuringPointPriceOption;
  basePrices: ChangeablePrice[];
  billingMethodAndPeriod: PaymentIntervalAndBillingMethod;
  monthlyInvoiceDocumentsForAdvancePayment: boolean;
  automaticExtension: boolean | null;
  begin: string;
  cancelationDeadlineUnit: string | null;
  cancelationDeadlineValue: number | null;
  cancelationReference: string | null;
  creationTemplate: string;
  creationTemplateSite: number;
  dueDateUnit: string;
  dueDateValue: number;
  end: string | null;
  extensionDurationUnit: string | null;
  extensionDurationValue: number | null;
  id: string;
  mandateReference: string | null;
  meterCounter: number | null;
  minimumTerm: string;
  name: string;
  paymentInterval: string;
  paymentMethod: PaymentMethod;
  payments: Array<Payment>;
  measuringPointPrices: Array<ChangeablePrice>;
  previousElectricityConsumption: boolean;
  productName: string;
  state: string;
  isTemplate: boolean;
  supplied: number;
  supplier: number;
  variant: number;
  deposit: string | null;
  isVatIncluded: boolean;
  vatExemptionReason: VATExemptionReason | null;
  customVatExemptionReason: string | null;
}

export enum PaymentMethod {
  BankTransfer = "bank_transfer",
  DirectDebit = "direct_debit"
}

export interface Payment {
  contract: string;
  year: number;
  month: number | null;
  estimatedAdvancePayments: number | null;
  madeAdvancePayments: number | null;
  discountPayments: number | null;
  discountDescription: string | null;
  pendingPayments: number | null;
  pendingPaymentsDescription: string | null;
  deliveryAmountPredictionThisYear: number | null;
  directDeliverySharePrediction: number | null;
}

export interface MeteringConceptForPerson {
  name: string; // person name
  marketLocations: Array<MarketLocation>;
  priceComponents: Array<PriceComponent>;
  summary: ResultSummary;
}

export interface MarketLocation {
  id: number;
  locationType: string;
  generator: { id: number; name: string } | null;
  consumer: { id: number; name: string } | null;
  connection: { id: number; name: string } | null;
  yearlyEnergy: number;
  priceComponents: Array<PriceComponent>;
}

export interface MeteringOrMarketLocation extends Component {
  comment: string;
  customLabel: string | null;
  measurands: Array<string>;
  meteringServiceProvider: number | null;
  number: string;
  site: number;
  status: "active" | "inactive";
  updated: string;
}

export interface SuggestedMeteringOrMarketLocationResponse {
  id: number;
}

export interface PriceComponent {
  id: number;
  label: string;
  yearlyCost: number;
  price: number;
  quantity: number;
  priceUnit: string;
  quantityUnit: Unit;
  person: number;
}

export interface ResultSummary {
  totalYearlyCost: string;
  internalDelivery: { kwh: number; share: number };
}

interface SiteNotificationDetail {
  id: number;
  name: string;
  url: string;
}
export interface SiteNotification {
  generatorsFullFeedin: Array<SiteNotificationDetail>;
  metersConversionFactor: Array<SiteNotificationDetail>;
  tenantOutsideMieterstromModel: Array<Omit<SiteNotificationDetail, "url">>;
  id: number;
}

export enum NotificationSettingDbName {
  TodoCreation = "receiveEmailOnTodoCreation",
  TodoUpcomingDueDate = "receiveEmailOnTodoUpcomingDueDate"
}

export type NotificationSetting = {
  dbName: NotificationSettingDbName;
  label: string;
};

export type NotificationSettings = Record<NotificationSettingDbName, boolean>;

export interface Paged<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface TemplateMeter {
  id: number;
  name: string;
  defaultUnit: Unit;
  siteName: string;
  units: Array<[Unit, string]>;
}

export interface ParameterSet {
  id: string;

  name: string;
  originName?: string;
}

export enum MachineAccountReimportStatus {
  InProgress = "IN_PROGRESS",
  Success = "SUCCESSFUL",
  Error = "FAILED"
}

export interface MachineAccountConfiguration {
  id: number;
  parameterSet: string;
  name: string;
  receiverUuid: string;
  email?: string;
  parameterSetName?: string;
  status: MachineAccountReimportStatus;
  celeryTaskId: string;
}

export interface QivaloParameters {
  deprecated: boolean;
  installationDate: string;
  medium: string;
  nodeId: number;
  propertyId: number;
  propertyName: string;
  serialNumber: string;
  units: string;
}

export interface RotorsoftParameters {
  plantId: string;
  serialNumber: string;
  modelName: string | null;
  plantName: string;
  manufacturer: string | null;
  windparkName: string;
  modelNominalPower: string;
}

export interface WonderParameters {
  wonderId: number;
  type: string;
  serialNumber: string;
  nominalPower: string;
  name: string;
  manufacturer: string;
}

export interface WISParameters {
  plantId: number;
  parkName: string;
  plantName: string;
  serialNumber: string;
}

export interface GreenbyteParameters {
  greenbyteId: number;
  title: string;
  altTitle: string;
  installationData: string;
  meterType: string;
  siteName: string;
}

export interface SiemensParameters {
  measurementType: string;
  measurementTypeCode: number;
  medium: string;
  mediumCode: number;
  meterModel: string | null;
  name: string;
  readingFrequencyInSeconds: string;
  serialNumber: string;
  unit: string;
}

export interface ByteMeeParameters {
  installationDate: string;
  numberInPark: number;
  plantName: string;
  serialNumber: string;
  windparkId: number;
  windparkName: string;
}

export interface WattlineParameters {
  seriesId: string;
  serialNumber: string;
  meteringDirection: string;
}

export interface MeterConfigurationData {
  id: number;
  meter: number | null;
  subMeteringConfiguration: number;
  suggestedMeter: number | null;
  parameters:
    | QivaloParameters
    | RotorsoftParameters
    | WonderParameters
    | WISParameters
    | GreenbyteParameters
    | SiemensParameters
    | ByteMeeParameters
    | WattlineParameters;
}
export interface AnlageStromStg {
  id: number;
  variant: number;
  bezeichnung: string | null;
  datumErlaubnisBetriebserklaerung: string | null;
  datumErlaubnisStfreiParagraph_9Abs_1Nr_1Stromstg: string | null;
  einzelerlaubnisSteuerfreieEntnahmeZurStromerzeugung: string;
  hauptzollamtAnlagennummer: number | null;
  nummerErlaubnisBetriebserklaerung: number | null;
  stfreiParagraph_9Abs_1Nr_1Stromstg: string | null;
  datumEinzelerlaubnisSteuerfreieEntnahmeZurStromerzeugung: string | null;
  erlaubnisnummerSteuerfreieEntnahme: string | null;
  steuerbefreiungStromZurStromerzParagraph_9Abs_2Stromstg: string | null;
  stfreiParagraph_9Abs_1Nr_3Stromstg: string | null;
  datumErlaubnisStfreiParagraph_9Abs_1Nr_3Stromstg: string | null;
  hasStandortuebergreifendeVerklammerung: boolean | null;
}

export interface MastrData {
  adresszusatz: string | null;
  anschlussAnHoechstOderHochSpannung: boolean;
  auflageAbschaltungLeistungsbegrenzung: boolean;
  beschreibung: string | null;
  breitengrad: number | null;
  bruttoleistung: number | null;
  bundesland: string | null;
  datumEndgueltigeStilllegung: string | null;
  datumErstmaligeInbetriebnahme: string | null;
  datumLetzteAktualisierung: string | null;
  eeganlage: string;
  einheitMastrNummer: string | null;
  einsatzverantwortlicher: string | null;
  einspeisungsart: string | null;
  fernsteuerbarkeitDv: boolean;
  fernsteuerbarkeitNb: boolean;
  flurFlurstuecknummern: string | null;
  gaussKruegerHoch: number | null;
  gaussKruegerRechts: number | null;
  gemarkung: string | null;
  gemeinde: string | null;
  gemeindeschluessel: string | null;
  geplantesInbetriebnahmedatum: string | null;
  hausnummer: number | null;
  hersteller: string;
  herstellerEinheit: string | null;
  id: number;
  imMastrRegistriert: boolean;
  inbetriebnahmedatum: string | null;
  inselbetriebsfaehigkeit: string | null;
  kraftwerksnummer: string | null;
  laengengrad: number | null;
  lage: string | null;
  land: string | null;
  landkreis: string | null;
  meldedatum: string | null;
  nameStromerzeugungseinheit: string | null;
  nameWindpark: string | null;
  ort: string | null;
  postleitzahl: string | null;
  praequalifiziertFuerRegelenergie: string | null;
  schwarzstartfaehigkeit: string | null;
  seriennummer: string | null;
  strasse: string | null;
  typenbezeichnung: string | null;
  utmEast: number | null;
  utmNorth: number | null;
  utmZonenwert: number | null;
  weic: number | null;
  weicDisplayName: string | null;
  zubauAustausch: string | null;
}

export interface AnlageData {
  anlageBetriebsstatus: string | null;
  anlagenkennzifferAnlagenregister: string | null;
  anlagenschluesselEeg: string | null;
  ausschreibungZuschlag: boolean | null;
  bnetzaRegistrierungsnummer: number | null;
  datumLetzteAktualisierung: string | null;
  eegInbetriebnahmedatum: string | null;
  einheiten: Array<string>;
  foerderregime: FoerderRegime | null;
  herstellerTyp: string | null;
  id: number;
  imMastrRegistriert: boolean;
  installierteLeistung: number | null;
  kategorieEegUmlageEigenverbrauch: string | null;
  mastrNummer: string | null;
  meldedatum: string | null;
  messlokationId: number | null;
  pilotAnlage: boolean | null;
  prototypAnlage: boolean | null;
  verhaeltnisErtragsschaetzungReferenzertrag: string | null;
  verhaeltnisReferenzertragErtrag5Jahre: string | null;
  verhaeltnisReferenzertragErtrag10Jahre: string | null;
  verhaeltnisReferenzertragErtrag15Jahre: string | null;
  windParkReglerZentralSteuern: string | null;
  zuschlagsnummer: string | null;
}

export type Vollbenutzungsstunden = Array<{
  year: number | null;
  hours: number | null;
}>;

export interface VBHCalculated {
  status: "FAILED" | "SUCCESS";
  value: number;
  message?: string;
}
export interface VBHCalculatedResponseData {
  [key: number]: VBHCalculated;
}

export interface KwkAnlageData {
  id: number;
  meldedatum: string | null;
  datumLetzteAktualisierung: string | null;
  thermischeNutzleistung: number | null;
  bafaAnlagennummer: string | null;
  elektrischeKwkLeistung: number | null;
  ausschreibungZuschlag: string | null;
  zuschlagnummer: string | null;
  anlageBetriebsstatus: string | null;
  einheiten: Array<string>;
  bnetzaRegistrierungsnummer: string | null;
  herstellerTyp: string | null;
  messlokationId: string | null;
  kategorieEegUmlageEigenverbrauch: string | null;
  windParkReglerZentralSteuern: string | null;
  energiesteuerentlastungParagraph_53Energiestg: string | null;
  energiesteuerentlastungParagraph_53aEnergiestg: string | null;
  fahrweise: string | null;
  foerderregime: FoerderRegime | null;
  gesamtwirkungsgrad: string | null;
  hocheffizient: string | null;
  imMastrRegistriert: boolean;
  inbetriebnahmedatum: string | null;
  mastrNummer: string;
  mechEnergieStromerzeugungParagraph_3Abs_1Nr_1Energiestg: string | null;
  nutzungDerEnergieerzeugnisse: string | null;
  nutzungsgrad: number | null;
  seriennummer: string | null;
  vollbenutzungsstunden: Vollbenutzungsstunden;
  vorrichtungenAbwaermeabfuhr: string | null;
}

export interface PostOptions {
  actions: { pOST: FieldsDict };
}

export interface PutOptions {
  actions: { pUT: FieldsDict };
}

export interface GeneratorChangesFromMastr {
  fieldName:
    | "lokation_mastr_nummer"
    | "registrierungsdatum"
    | "anlagenbetreiber_mastr_nummer";
  fieldVerboseName: string;
  oldValue: string | null;
  newValue: string | null;
  modelName: string;
  modelApp: string;
  objId: number;
}
export interface MetersSuggestions {
  subMeteringEntityId: number;
  suggestedMeterId: number;
}

export enum VATExemptionReason {
  smallBusinessRegulation = "small_business_regulation",
  reverseCharge = "reverse_charge",
  custom = "custom"
}

export interface AnlageStromstgListData {
  name: string;
  id: number;
  bruttoleistung: number | null;
  site: string;
}

export interface Direktvermarkter {
  id: number;
  name: string;
}

export enum Measurand {
  negative = "negative",
  positive = "positive",
  reactiveCapacitive = "reactiveCapacitive",
  reactiveInductive = "reactiveInductive",
  lossOfWork = "lossOfWork"
}
export interface MeasurandDataGaps {
  gaps: Array<Array<string>>;
  measurand: Measurand;
}

export type DataGapKeyPattern = `${number}.${string}`;

export interface MeterDataGaps {
  expectedOrigin: string;
  [key: DataGapKeyPattern]: MeasurandDataGaps;
}
export interface LocationSection {
  title: string;
  locationTypes: Array<LocationType>;
  sourceLabel: "Erzeuger" | "Verbraucher";
  targetLabel?: "Verbraucher";
  key: string;
}

export enum LocationType {
  AccountedFeedOut = "Accounted Feed-Out",
  AccountedFeedIn = "Accounted Feed-In",
  InternalDelivery = "Internal Delivery",
  ResidualDelivery = "Residual Delivery"
}

export type HistoryLog = components["schemas"]["HistoryLog"];
export type HistoryLogs = Array<HistoryLog>;
export type HistoryChanges = components["schemas"]["Changes"];
export type HistoryChange = HistoryChanges[keyof HistoryChanges];
export type HistoryDateBoundedValue = components["schemas"]["DateBoundedValue"];

export type MeteringConceptTodo = components["schemas"]["TodoLabel"];
