import * as Sentry from "@sentry/browser";
import { t } from "i18next";
import { generatePath } from "react-router-dom";
import type { ApiError, ApiResponse } from "../api";
import api from "../api";
import { ROUTES } from "../routes";

export interface TaskStatusResponse {
  evaluationId?: string;
  taskStatusUrl?: string;
  taskIds?: Array<string>;
  // properties that are returned by apiWithoutCamelization responses
  evaluation_id?: string;
  task_status_url?: string;
  task_ids?: Array<string>;
}

interface PostDataAndPollResponseOptions<T> {
  queryAgainOnPollSuccess?: boolean;
  queryAgainOnPollSuccessWithTaskId?: boolean;
  queryAgainFn?: (
    taskId?: string
  ) => Promise<ApiResponse<TaskStatusResponse & T>>;
  pollInterval?: number;
}

interface QueryWithPollResponseResponse<T> {
  evaluationId?: string;
  taskIds?: Array<string>;
  redirectUrl?: string;
  data?: T;
  response?: ApiResponse<TaskStatusResponse & T>;
}

export enum TaskStatus {
  Success = "SUCCESS",
  Pending = "PENDING",
  Missing = "MISSING",
  Failure = "FAILURE",
  Error = "ERROR"
}

export interface TaskResponse {
  status: TaskStatus;
  redirectProjectId?: string;
  redirectVariantId?: number;
  evaluationId?: string;
  message?: string;
  // properties that are returned by apiWithoutCamelization responses
  redirect_project_id?: string;
  redirect_variant_id?: number;
  evaluation_id?: string;
}

/** Calls queryFunc and polls automatically if a `taskStatusUrl` is returned. Returns result otherwise.
 *
 * If `queryAgainOnPollSuccess` is included in the optional `options`, `queryFunc` will be automatically called again on success.
 *
 * If `queryAgainOnPollSuccessWithTaskId` is included in `options`, `queryFunc` will be automatically called again on success, if a `taskId` was returned in the original response.
 */
export async function queryWithPollResponse<T>(
  queryFunc: (taskId?: string) => Promise<ApiResponse<TaskStatusResponse & T>>,
  options?: PostDataAndPollResponseOptions<T>
): Promise<QueryWithPollResponseResponse<T>> {
  let response: ApiResponse<TaskStatusResponse & T>;

  try {
    response = await queryFunc();
  } catch (error) {
    return Promise.reject(error);
  }

  return new Promise((resolve, reject) => {
    const evaluationId =
      response.data.evaluationId ?? response.data.evaluation_id;
    const taskStatusUrl =
      response.data.taskStatusUrl ?? response.data.task_status_url;
    const taskIds = response.data.taskIds ?? response.data.task_ids;

    if (evaluationId) {
      resolve({ evaluationId });
    } else if (taskStatusUrl) {
      pollTaskStatus(
        taskStatusUrl,
        () => {
          if (options && options.queryAgainOnPollSuccess) {
            const taskId = taskIds?.[0];
            const queryAgainFunction = options.queryAgainFn ?? queryFunc;

            if (options.queryAgainOnPollSuccessWithTaskId && taskId) {
              resolve(
                queryWithPollResponse(() => queryAgainFunction(taskId), options)
              );
            } else {
              resolve(queryWithPollResponse(queryAgainFunction, options));
            }
          } else {
            resolve({
              taskIds
            });
          }
        },
        (error) => {
          reject(error);
        },
        (redirectUrl: string, evaluationId: string) => {
          resolve({
            redirectUrl: redirectUrl,
            evaluationId: evaluationId
          });
        },
        options?.pollInterval
      );
    } else {
      resolve({ data: response.data, response: response });
    }
  });
}

export function pollTaskStatus(
  taskStatusUrl: string,
  onSuccess: () => void,
  onError: (unknown) => void,
  onMissing: (redirectUrl: string | null, evaluationId: string) => void,
  interval = 200
): Promise<void> {
  return api
    .get(taskStatusUrl)
    .then((response: { data: TaskResponse }) => {
      const status = response.data.status;
      if (status === TaskStatus.Success) {
        onSuccess();
      } else if (status === TaskStatus.Pending) {
        setTimeout(() => {
          pollTaskStatus(
            taskStatusUrl,
            onSuccess,
            onError,
            onMissing,
            interval
          );
        }, interval);
      } else if (status === TaskStatus.Missing) {
        let redirectUrl: string | null = null;
        const evaluationId: string | null =
          response.data.evaluationId ?? response.data.evaluation_id ?? null;
        const redirectProjectId: string | null =
          response.data.redirectProjectId ??
          response.data.redirect_project_id ??
          null;
        const redirectVariantId: number | null =
          response.data.redirectVariantId ??
          response.data.redirect_variant_id ??
          null;

        if (redirectProjectId && redirectVariantId) {
          redirectUrl = generatePath(ROUTES.evaluation, {
            projectId: redirectProjectId,
            variantId: redirectVariantId.toString(),
            evaluationId: evaluationId
          });
        }

        if (evaluationId) {
          onMissing(redirectUrl, evaluationId);
        } else {
          Sentry.captureMessage(
            `Task endpoint reported missing data but has no evaluationId. Endpoint was ${taskStatusUrl}`
          );
        }
      } else if (status === TaskStatus.Error || status === TaskStatus.Failure) {
        onError({
          response: { status: 500 },
          userFacingErrorMessage:
            response.data.message ?? t("errors.ServerError")
        });
      } else {
        Sentry.captureMessage(
          "Task endpoint responded with an unexpected status: " +
            response.data.status
        );
        onError({
          response: { status: 500 },
          userFacingErrorMessage: t("errors.ServerError")
        });
      }
    })
    .catch((error: ApiError) => {
      Sentry.captureException(error);
      onError(error);
    });
}

export async function postDataAndPollResponse<T>(
  postUrl: string,
  data,
  options?: PostDataAndPollResponseOptions<T>
): Promise<QueryWithPollResponseResponse<T>> {
  return queryWithPollResponse<T>(() => api.post(postUrl, data), options);
}

export function getFieldSpecificError(e: ApiError, fieldNames?: string[]) {
  const response = e.response;

  if (response?.status === 400) {
    const errorData = response.data;

    return fieldNames && fieldNames.length > 0
      ? fieldNames
          .reduce(
            (acc, fieldName) => [
              ...acc,
              errorData?.[fieldName] ? errorData?.[fieldName][0] : ""
            ],
            []
          )
          .join(", ")
      : t("errors.UnknownError");
  }

  return null;
}
