import { Group } from "@mantine/core";
import React from "react";
import type { SitePlausibility } from "../../../../../../utils/backend-types";
import { THEME_VARS } from "../../../../../../utils/constants";
import { Icon } from "../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";
import "./SiteTitle.scss";

interface SiteTitleProps {
  loading: boolean;
  loadingFailed: boolean;
  showPlausibility: boolean;
  siteName: string;
  sitePlausibility: SitePlausibility | undefined;
}

function SiteTitle({
  loading,
  loadingFailed,
  showPlausibility,
  siteName,
  sitePlausibility
}: SiteTitleProps) {
  let iconName = IconName.CheckCircle;
  let iconColour = THEME_VARS.successColor;
  let allUnknown = false;

  if (loading) {
    iconName = IconName.SpinnerSpinning;
    iconColour = THEME_VARS.brandColor;
  } else if (loadingFailed) {
    iconName = IconName.Question;
    iconColour = THEME_VARS.dangerColor;
  } else if (sitePlausibility) {
    const isSitePlausible = sitePlausibility.reduce(
      (plausible, meterPlausibility) => {
        return plausible && meterPlausibility.plausibility !== false;
      },
      true
    );

    if (isSitePlausible) {
      allUnknown =
        sitePlausibility.length > 0 &&
        sitePlausibility.reduce((allUnknown, meterPlausibility) => {
          return allUnknown === true && meterPlausibility.plausibility === null;
        }, true);
    } else {
      iconName = IconName.Warning;
      iconColour = THEME_VARS.warningColor;
    }
  }

  return (
    <Group align="center" className="SiteTitle" fw={500} gap={5}>
      {showPlausibility && !allUnknown && (
        <Icon name={iconName} style={{ color: iconColour }} />
      )}
      {siteName}
    </Group>
  );
}

export { SiteTitle };
