import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import api from "../../../../../../../api";
import urls from "../../../../../../../urls";
import type { MeterDataGaps } from "../../../../../../../utils/backend-types";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { Unit } from "../../../../../../../utils/enums";

function useDataGaps(unit: Unit, meterId: number, start: Date, end: Date) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["dataGaps", { unit, meterId, start, end }],
    queryFn: () => getDataGaps(unit, meterId, start, end),
    retry: false
  });

  async function getDataGaps(
    unit: Unit,
    meterId: number,
    start: Date,
    end: Date
  ) {
    const firstDateStr = luxonDateTimeToBackendDateOrDateTime(
      DateTime.fromJSDate(start).startOf("day"),
      "ISO 8601"
    );
    const lastDateStr = luxonDateTimeToBackendDateOrDateTime(
      DateTime.fromJSDate(end).endOf("day"),
      "ISO 8601"
    );

    const response = await api.get<MeterDataGaps>(
      urls.api.meterDataGaps(firstDateStr, lastDateStr, unit, meterId)
    );

    return response.data;
  }

  return {
    isLoading,
    error,
    dataGaps: data
  };
}

export { useDataGaps };
