import { Group } from "@mantine/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import type { Column, SortingRule } from "react-table";
import type { ApiResponse } from "../../../../../api";
import api from "../../../../../api";
import { useSiteCategories } from "../../../../../hooks/useSiteCategories";
import urls from "../../../../../urls";
import type {
  ExtendedUser,
  RegulatoryDuty,
  TodoFile
} from "../../../../../utils/backend-types";
import { sortBackendDates } from "../../../../../utils/dates/sortBackendDates";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../../../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import { showToast } from "../../../../../utils/toast";
import { Checkbox } from "../../../../BuildingBlocks/Forms/Checkbox/Checkbox";
import { MultiConfirmationModal } from "../../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { Button } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { CustomReactSelectTable } from "../../../../CustomReactTable/CustomReactTable";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import { useCustomReactTableCheckboxes } from "../../../../CustomReactTable/CustomReactTableHooks";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import { Mode, SYSTEM_USER_NAME } from "../../../common";
import { useRevokeTodoDocumentMutation } from "../../../hooks/useRevokeTodoDocumentMutation";
import { slugsWithRevoke } from "./DocumentsList.constants";
import "./DocumentsList.scss";
import { DeleteDocumentCell } from "./DocumentsListComponents/DeleteDocumentCell";
import { DocumentNameCell } from "./DocumentsListComponents/DocumentNameCell";
import { RenameDocumentCell } from "./DocumentsListComponents/RenameDocumentCell";
import { RevokeDocumentCell } from "./DocumentsListComponents/RevokeDocumentCell";
import { RevokeDocumentInstruction } from "./DocumentsListComponents/RevokeDocumentInstruction";

declare const SITE_NAME: string;

const DEFAULT_SORTED: Array<SortingRule> = [
  { id: "creationDatetime", desc: true }
];

export interface DocumentsListProps {
  documents: Array<TodoFile>;
  todoId: number;
  mode: Mode;
  documentProvided?: boolean;
  showTodoStatus?: boolean;
  regulatoryDuty?: RegulatoryDuty | null;
  variantId?: number;
  onClickRename: (document: TodoFile, newName: string) => void;
  onClickDelete: (document: TodoFile) => void;
  deleteDocuments: (ids: Array<number>) => Promise<ApiResponse>;
  updateDocumentProvided: (todoId: number) => void;
  onClickNotify: (todoId: number) => Promise<ApiResponse>;
}

function DocumentsList({
  documents,
  todoId,
  mode,
  documentProvided,
  showTodoStatus,
  regulatoryDuty,
  variantId,
  onClickRename,
  onClickDelete,
  deleteDocuments,
  updateDocumentProvided,
  onClickNotify
}: DocumentsListProps) {
  const { projectId } = useParams();
  const { siteCategories } = useSiteCategories(projectId || null);

  const { revokeTodoDocument } = useRevokeTodoDocumentMutation({
    mode,
    variantId
  });

  const isPartialFeedin =
    siteCategories?.some((site) => !site.is_full_feedin) || false;

  const isRevokeable = slugsWithRevoke.includes(regulatoryDuty?.slug || "");

  const [showRenameControls, setShowRenameControls] = useState<
    Record<number, boolean>
  >({});
  const [documentsToDelete, setDocumentsToDelete] = useState<Array<TodoFile>>(
    []
  );
  const [documentsToRevoke, setDocumentsToRevoke] = useState<Array<TodoFile>>(
    []
  );
  const [isNotifying, setIsNotifying] = useState<boolean>(false);

  const {
    selection,
    setSelection,
    setSelectAll,
    getSelectedData,
    customReactTableProps
  } = useCustomReactTableCheckboxes<TodoFile>();
  const shouldShowStaffView = useShouldShowStaffView();
  const canAlwaysRenameAndDelete = mode !== Mode.Normal || shouldShowStaffView;

  const toggleDeleteDocumentsModal = () => setDocumentsToDelete([]);

  function getUserNameFromValue(user: ExtendedUser) {
    let name = "";

    if (user) {
      if (user.name === SYSTEM_USER_NAME) {
        name = SITE_NAME;
      } else {
        name = user.name;
      }
    }

    return name;
  }

  function handleClickDownloadSelection() {
    const selectedDocuments = getSelectedData();
    const selectedDocumentIds = selectedDocuments.map(
      (document) => document.id
    );

    api
      .post(urls.api.todoDownloadSelectedDocuments(todoId), {
        todoFileIds: selectedDocumentIds
      })
      .then((response) =>
        downloadCsvOrExcelFileForResponseWithContentDisposition(response, true)
      )
      .catch((error) => openErrorAlertPopup(error));
  }

  function handleClickNotify() {
    setIsNotifying(true);
    onClickNotify(todoId)
      .then(() => {
        showToast("success", "Die Benachrichtigung wurde versandt!");
      })
      .finally(() => setIsNotifying(false));
  }

  const selectedDocuments = getSelectedData();
  const canRevokeAnySelectedDocuments = selectedDocuments.some(
    (document) => document.revoked === null
  );

  function handleClickDeleteSelection() {
    setDocumentsToDelete(selectedDocuments);
  }
  function handleClickRevokeSelection() {
    selectedDocuments.filter((document) => document.revoked === null);
    setDocumentsToRevoke(selectedDocuments);
  }

  function handleDeleteDocuments(ids: Array<number>) {
    return deleteDocuments(ids)
      .then(() => {
        setSelection([]);
        setSelectAll(false);
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        setDocumentsToDelete([]);
      });
  }

  function handleRevokeDocument(documentId: number) {
    return revokeTodoDocument({ todoId, documentId })
      .then(() => {
        setSelection([]);
        setSelectAll(false);
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        setDocumentsToRevoke([]);
      });
  }

  const tableColumns: Array<Column<TodoFile>> = [
    {
      Header: "Dateiname",
      accessor: "name",
      minWidth: 405,
      Cell: (data) => (
        <DocumentNameCell
          documentId={data.original.id}
          documentName={data.original.name}
          setShowRenameControls={(show: boolean) =>
            setShowRenameControls({
              ...showRenameControls,
              [data.original.id]: show
            })
          }
          showRenameControls={!!showRenameControls[data.original.id]}
          onRename={(newName: string) => onClickRename(data.original, newName)}
        />
      )
    },
    {
      Header: "Erstellt von",
      accessor: "createdBy",
      Cell: (data: TypedCellInfo<ExtendedUser, TodoFile>) => (
        <span className="created-by">{getUserNameFromValue(data.value)}</span>
      ),
      sortMethod: function (a, b) {
        const userA = getUserNameFromValue(a);
        const userB = getUserNameFromValue(b);

        return userA < userB ? -1 : 1;
      }
    },
    {
      Header: "Erstellt am",
      accessor: "creationDatetime",
      minWidth: 126,
      sortMethod: sortBackendDates
    },
    {
      Cell: (data) =>
        !data.original.revoked && (
          <RenameDocumentCell
            canAlwaysRename={canAlwaysRenameAndDelete}
            createdBy={data.original.createdBy}
            onClickRename={() =>
              setShowRenameControls({
                ...showRenameControls,
                [data.original.id]: true
              })
            }
          />
        ),
      width: 30,
      sortable: false,
      resizable: false
    },
    {
      Cell: (data) => {
        return (
          <RevokeDocumentCell
            revokedStatus={data.original.revoked}
            onClickRevoke={() => setDocumentsToRevoke([data.original])}
          />
        );
      },
      show: false || isRevokeable,
      width: 30,
      sortable: false,
      resizable: false
    },
    {
      Cell: (data) => (
        <DeleteDocumentCell
          canAlwaysDelete={canAlwaysRenameAndDelete}
          createdBy={data.original.createdBy}
          onClickDelete={() => onClickDelete(data.original)}
        />
      ),
      width: 30,
      sortable: false,
      resizable: false
    }
  ];

  const selectionButtonsDisabled = selection.length === 0;
  const notifyButtonDisabled = documentProvided === false;
  const documentsToDeleteModalIsOpen = documentsToDelete.length > 0;
  const documentsToDeleteNames = documentsToDelete.map(
    (document) => document.name
  );
  const documentsToDeleteIds = documentsToDelete.map((document) => document.id);
  const dataText = selection.length <= 1 ? "Datei" : "Dateien";

  return (
    <div className="documents-list">
      {isRevokeable && <RevokeDocumentInstruction />}
      <div
        className="controls"
        style={{
          justifyContent:
            showTodoStatus && isPartialFeedin ? "space-between" : "flex-end"
        }}
      >
        {isPartialFeedin && (
          <Group className="documents-provided">
            {showTodoStatus && (
              <div className="left-hand-controls">
                {shouldShowStaffView && (
                  <div className="document-provided-checkbox">
                    <Checkbox
                      checked={documentProvided}
                      onChange={() => updateDocumentProvided(todoId)}
                    ></Checkbox>
                  </div>
                )}
                {(shouldShowStaffView || documentProvided) && (
                  <p className="document-provided">
                    {!documentProvided && shouldShowStaffView
                      ? "Als bereitgestellt markieren"
                      : "Dokument wurde bereitgestellt"}
                  </p>
                )}
                {shouldShowStaffView && (
                  <SpinButton
                    color="brand"
                    disabled={notifyButtonDisabled}
                    spin={isNotifying}
                    onClick={() => handleClickNotify()}
                  >
                    Benachrichtigen
                  </SpinButton>
                )}
              </div>
            )}
          </Group>
        )}
        <div className="right-hand-controls">
          {isRevokeable && (
            <Button
              color="brand"
              disabled={
                selectionButtonsDisabled || !canRevokeAnySelectedDocuments
              }
              onClick={handleClickRevokeSelection}
            >
              Ungültig markieren
            </Button>
          )}
          {canAlwaysRenameAndDelete && (
            <Button
              color="brand"
              disabled={selectionButtonsDisabled}
              onClick={handleClickDeleteSelection}
            >
              {dataText} löschen
            </Button>
          )}
          <Button
            color="brand"
            disabled={selectionButtonsDisabled}
            onClick={handleClickDownloadSelection}
          >
            {dataText} herunterladen
          </Button>
        </div>
      </div>
      <CustomReactSelectTable
        keyField="id"
        {...customReactTableProps}
        columns={tableColumns}
        data={documents}
        defaultSorted={DEFAULT_SORTED}
        minRows={0}
        NoDataComponent={NoDataComponent}
        pageSize={documents.length}
      />
      <MultiConfirmationModal
        actionName="löschen"
        actionObjects={documentsToDeleteNames}
        confirmationText="Möchten Sie die folgenden Dokumente löschen? Dieser Schritt kann nicht rückgängig gemacht werden."
        isModalOpen={documentsToDeleteModalIsOpen}
        objectName="Dokumente"
        toggleModal={toggleDeleteDocumentsModal}
        onAction={() => handleDeleteDocuments(documentsToDeleteIds)}
      />
      <MultiConfirmationModal
        actionName="ungültig markieren"
        actionObjects={documentsToRevoke.map((document) => document.name)}
        confirmationText={`Möchten Sie ${documentsToRevoke.length === 1 ? "das" : "die"} Dokument${documentsToRevoke.length === 1 ? " des" : "e der"} als ungültig markieren? Dieser Schritt kann nicht rückgängig gemacht werden.`}
        isModalOpen={documentsToRevoke.length > 0}
        objectName="Dokument"
        toggleModal={() => setDocumentsToRevoke([])}
        onAction={async () => {
          await Promise.all(
            documentsToRevoke.map((document) =>
              handleRevokeDocument(document.id)
            )
          );

          setDocumentsToRevoke([]);
        }}
      />
    </div>
  );
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Dokumente vorhanden.</p>
    </div>
  );
}

export { DocumentsList };
