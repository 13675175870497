import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { ApiResponse } from "../../../api";
import type { MeteringOrMarketLocation } from "../../../utils/backend-types";
import { validateMarketLocationNumber } from "../../../utils/validateMarketLocationNumber";
import { FormFieldController } from "../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { setErrorsFromResponseData } from "../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { applyHighlightingToObjectFormFieldData } from "../../VariantObjectWizard/utils/applyHighlightingToObjectFormFieldData";
import type { WizardModeProps } from "../../VariantObjectWizard/VariantObjectWizard";
import { MARKET_LOCATION_FORM_FIELD_DATA } from "./MarketLocationForm.constants";

type MarketLocationFormProps = {
  buttonContainer?: HTMLElement;
  showStaffView?: boolean;
  onCancel: () => void;
  onChangeNumErrors: (numErrors: number) => void;
  onChangeNumHighlightedFields: (numHighlightedFields: number) => void;
  onSubmit: (data: MeteringOrMarketLocation) => Promise<ApiResponse>;
} & WizardModeProps<MeteringOrMarketLocation>;

function MarketLocationForm({
  buttonContainer,
  showStaffView,
  onCancel,
  onChangeNumErrors,
  onChangeNumHighlightedFields,
  onSubmit,
  ...modeProps
}: MarketLocationFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch
  } = useForm<MeteringOrMarketLocation>({
    defaultValues:
      modeProps.mode === "edit" ? modeProps.wizardObject : undefined
  });
  const { formFieldData, numHighlightedFields } =
    applyHighlightingToObjectFormFieldData(
      MARKET_LOCATION_FORM_FIELD_DATA,
      watch,
      modeProps.mode === "edit" ? modeProps : undefined
    );
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    onChangeNumErrors(Object.keys(errors).length);
  }, [errors, onChangeNumErrors]);

  useEffect(() => {
    onChangeNumHighlightedFields(numHighlightedFields);
  }, [numHighlightedFields, onChangeNumHighlightedFields]);

  const onValidSubmit: SubmitHandler<MeteringOrMarketLocation> = async (
    data
  ) => {
    setIsSubmitting(true);

    try {
      const response = await onSubmit(data);
      return response;
    } catch (error) {
      setErrorsFromResponseData<MeteringOrMarketLocation>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitCallback = handleSubmit(onValidSubmit);

  function handleClickCancel() {
    reset();
    onCancel();
  }

  const Buttons = (
    <>
      <Button disabled={isSubmitting} onClick={handleClickCancel}>
        Abbrechen
      </Button>
      <SpinButton color="primary" spin={isSubmitting} onClick={submitCallback}>
        {modeProps.mode === "edit" ? "Speichern" : "Erstellen"}
      </SpinButton>
    </>
  );

  return (
    <form className="MarketLocationForm" onSubmit={submitCallback}>
      <FormFieldController
        control={control}
        data={formFieldData.number}
        error={errors.number}
        rules={{
          validate: (value) =>
            validateMarketLocationNumber(value)
              ? true
              : t("errors.MarketLocation.InvalidNumber")
        }}
      />

      <FormFieldController
        control={control}
        data={formFieldData.customLabel}
        error={errors.customLabel}
      />

      <FormFieldController
        control={control}
        data={formFieldData.meteringServiceProvider}
        error={errors.meteringServiceProvider}
      />

      {showStaffView && (
        <FormFieldController
          control={control}
          data={formFieldData.status}
          error={errors.status}
        />
      )}

      {showStaffView && (
        <FormFieldController
          control={control}
          data={formFieldData.comment}
          error={errors.comment}
        />
      )}

      {buttonContainer
        ? ReactDOM.createPortal(Buttons, buttonContainer)
        : Buttons}
    </form>
  );
}

export { MarketLocationForm, MarketLocationFormProps };
