import type { MRT_ColumnDef, MRT_SortingState } from "mantine-react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { getDefaultMRTOptions } from "../../../../../mantine/getDefaultMRTOptions";
import { getDefaultMRTRowEditModeOptions } from "../../../../../mantine/getDefaultMRTRowEditModeOptions";
import { getMRTTopToolbarCustomActionsRenderer } from "../../../../../mantine/getMRTTopToolbarCustomActionsRenderer";
import { type MeterMeasurementType } from "../../../../../types/api.types";
import { showToast } from "../../../../../utils/toast";
import {
  CONSUMER_TYPE_CHOICES,
  type Consumer,
  type TenantTableData
} from "../../../SiteSetupAssistant.types";
import { formatConsumersToFrontendTableData } from "../../../utils/formatConsumersToFrontendTableData";
import { formatFrontendTableDataToConsumers } from "../../../utils/formatFrontendTableDataToConsumers";
import { getLabelFromConsumerType } from "../../../utils/getLabelFromConsumerType";
import { getAddressColumn } from "./Columns/getAddressColumn";
import { getBillingAddressIsSameAsAddressColumn } from "./Columns/getBillingAddressIsSameAsAddressColumn";
import { getIsParticipatingInMieterstromColumn } from "./Columns/getIsParticipatingInMieterstromColumn";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "name",
    desc: false
  }
];

interface TenantTableProps {
  consumers: Array<Consumer>;
  defaultMeasurementType: MeterMeasurementType | null;
  onBlockNavigation: (blocked: boolean) => void;
  onChange: (data: Array<Consumer>) => void;
}

function TenantTable({
  consumers,
  defaultMeasurementType,
  onBlockNavigation,
  onChange
}: TenantTableProps) {
  const [tableData, setTableData] = useState<Array<TenantTableData>>([]);

  useEffect(() => {
    if (consumers) {
      setTableData(formatConsumersToFrontendTableData(consumers));
    }
  }, [consumers]);

  const columns = useMemo<Array<MRT_ColumnDef<TenantTableData>>>(
    () => [
      {
        accessorKey: "name",
        header: "Bezeichnung des Verbrauchers",
        sortingFn: (a, b) => {
          const aName = a.original.name;
          const bName = b.original.name;
          if (!aName || !bName) return 0;
          return aName.localeCompare(bName, undefined, {
            numeric: true
          });
        },
        mantineEditTextInputProps: () => {
          return {
            "aria-label": "Bezeichnung des Verbrauchers",
            required: true
          };
        }
      },
      {
        accessorKey: "type",
        header: "Typ des Verbrauchers",
        editVariant: "select",
        mantineEditSelectProps: () => {
          return {
            "aria-label": "Typ des Verbrauchers",
            data: CONSUMER_TYPE_CHOICES,
            required: true
          };
        },
        Cell: ({ row }) => <>{getLabelFromConsumerType(row.original.type)}</>
      },
      {
        accessorKey: "tenantName",
        header: "Name des Mieters",
        mantineEditTextInputProps: () => {
          return {
            "aria-label": "Name des Mieters",
            required: true
          };
        }
      },
      getIsParticipatingInMieterstromColumn(),
      getAddressColumn(),
      getBillingAddressIsSameAsAddressColumn()
    ],
    []
  );

  function handleChangeDeleteTenant(tenantToDelete: TenantTableData) {
    const newData = [...tableData];
    const indexToDelete = newData.findIndex(
      (tenant) => tenant === tenantToDelete
    );
    if (indexToDelete !== -1) {
      newData.splice(indexToDelete, 1);
      onChange(formatFrontendTableDataToConsumers(newData));
    } else {
      showToast("error", "Fehler beim Löschen des Mieters");
    }
  }

  const table = useMantineReactTable({
    ...getDefaultMRTOptions<TenantTableData>({
      emptyRowsFallbackText: "Hier können Sie die Mieter eintragen!"
    }),
    ...getMRTTopToolbarCustomActionsRenderer<TenantTableData>({
      create: {
        text: "Verbraucher hinzufügen",
        onClick: () => {
          table.setCreatingRow(true);
          onBlockNavigation(true);
        }
      },
      delete: {
        hide: true
      }
    }),
    ...getDefaultMRTRowEditModeOptions<TenantTableData>({
      rowActions: {
        edit: {
          text: "Mieter bearbeiten",
          onClick: () => {
            onBlockNavigation(true);
          }
        },
        delete: {
          text: "Mieter löschen",
          onClick: (tenant) => handleChangeDeleteTenant(tenant[0])
        }
      }
    }),
    columns,
    data: tableData,
    enableRowSelection: false,
    enableFilters: false,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10
      },
      sorting: DEFAULT_SORTED
    },
    onCreatingRowCancel: () => {
      onBlockNavigation(false);
    },
    onEditingRowCancel: () => {
      onBlockNavigation(false);
    },
    onCreatingRowSave: ({ values }) => {
      const formattedValues = Object.keys(values).reduce(
        (result, key) => ({
          ...result,
          [key]: values[key] === "" ? null : values[key]
        }),
        {
          meter: {
            name: `Zähler ${values.name}`,
            is_calibrated: true,
            measurement_type: defaultMeasurementType
          }
        }
      );
      onChange(
        formatFrontendTableDataToConsumers([
          ...tableData,
          formattedValues as TenantTableData
        ])
      );
      table.setCreatingRow(null);
      onBlockNavigation(false);
    },
    onEditingRowSave: ({ values, row }) => {
      const newData = [...tableData];
      newData[row.index] = {
        ...tableData[row.index],
        ...values,
        meter: { ...tableData[row.index].meter, name: `Zähler ${values.name}` }
      };
      onChange(formatFrontendTableDataToConsumers(newData));
      table.setEditingRow(null);
      onBlockNavigation(false);
    }
  });

  return (
    <div className="TenantTable">
      <MantineReactTable table={table} />
    </div>
  );
}

export { TenantTable };
