import { BarChart, type BarChartProps } from "@mantine/charts";
import classNames from "classnames";
import React from "react";
import { numericFormatter } from "react-number-format";
import { Text as RechartsText } from "recharts";
import { getDefaultNumericFormatterProps } from "../../../utils/getDefaultNumericFormatterProps";
import { getColoredSeries } from "../utils/getColoredSeries";
import "./MantineBarChart.scss";
import {
  MantineBarChartTooltip,
  type MantineBarChartPayloadItem
} from "./MantineBarChartTooltip";

type MantineBarChartProps = BarChartProps;

function MantineBarChart({
  className,
  data,
  dataKey,
  h = 450,
  series,
  xAxisProps,
  yAxisProps,
  ...props
}: MantineBarChartProps) {
  const coloredSeries = getColoredSeries(series);

  return (
    <BarChart
      className={classNames("MantineBarChart ", className)}
      data={data}
      dataKey={dataKey}
      h={h}
      series={coloredSeries}
      {...props}
      tooltipProps={{
        content: ({
          label,
          payload
        }: {
          label: string;
          payload: Array<MantineBarChartPayloadItem>;
        }) => {
          const title: string =
            label && xAxisProps?.tickFormatter
              ? xAxisProps.tickFormatter(label, 0)
              : label;
          return (
            <MantineBarChartTooltip
              payload={payload}
              seriesLabels={coloredSeries.reduce(
                (result, singleSeries) => ({
                  ...result,
                  [singleSeries.name]: singleSeries.label || singleSeries.name
                }),
                {}
              )}
              title={title}
            />
          );
        }
      }}
      xAxisProps={{
        ...xAxisProps,
        tick: ({ x, y, payload }) => (
          <RechartsText angle={90} width={90} x={x} y={y}>
            {payload.value}
          </RechartsText>
        )
      }}
      yAxisProps={{
        tickFormatter: (value) =>
          numericFormatter(value.toString(), getDefaultNumericFormatterProps()),
        ...yAxisProps
      }}
    />
  );
}

export { MantineBarChart, MantineBarChartProps };
