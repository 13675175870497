import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

import type { RegulatoryDuty } from "../../../../utils/backend-types";
import { AlertColor } from "../../../Alert/Alert";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import { RegulatoryDutyDetailRow } from "../../../RegulatoryDuties/RegulatoryDutyList/RegulatoryDutyItem/RegulatoryDutyDetailRow/RegulatoryDutyDetailRow";
import type { DocumentsWidgetProps } from "../DocumentsWidget/DocumentsWidget";
import { DocumentsWidget } from "../DocumentsWidget/DocumentsWidget";

const warningTexts = ["Qualitätsverbesserung", "Wartungshinweis"] as const;

interface OverviewTabProps extends DocumentsWidgetProps {
  regulatoryDuty: RegulatoryDuty | null;
}

function OverviewTab({
  regulatoryDuty,
  todoId,
  ...documentsTabProps
}: Omit<OverviewTabProps, "handleErrorMessage">) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleErrorMessage = (message: string | null) => {
    setErrorMessage(message);
  };
  const showRegulatoryDuty = regulatoryDuty !== null;

  return (
    <div className="overview">
      {showRegulatoryDuty && (
        <div className="todo-property">
          <RegulatoryDutyOverview regulatoryDuty={regulatoryDuty} />
        </div>
      )}
      {errorMessage &&
        (warningTexts.some((warningText) =>
          errorMessage.includes(warningText)
        ) ? (
          <IconAlert color={AlertColor.Warning}>{errorMessage}</IconAlert>
        ) : (
          <IconAlert color={AlertColor.Danger}>{errorMessage}</IconAlert>
        ))}
      <DocumentsWidget
        handleErrorMessage={handleErrorMessage}
        regulatoryDuty={regulatoryDuty}
        todoId={todoId}
        {...documentsTabProps}
      />
    </div>
  );
}

interface RegulatoryDutyOverviewProps {
  regulatoryDuty: RegulatoryDuty;
}

function RegulatoryDutyOverview({
  regulatoryDuty
}: RegulatoryDutyOverviewProps) {
  return (
    <div className="regulatory-duty-rows">
      <RegulatoryDutyDetailRow title="Empfänger">
        {regulatoryDuty.obligee}
      </RegulatoryDutyDetailRow>
      <RegulatoryDutyDetailRow title="Erfüllungsprozess">
        {regulatoryDuty.fulfillmentProcess && (
          <ReactMarkdown linkTarget="_blank">
            {regulatoryDuty.fulfillmentProcess}
          </ReactMarkdown>
        )}
      </RegulatoryDutyDetailRow>
    </div>
  );
}

export { OverviewTab, OverviewTabProps };
