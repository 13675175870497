import type { QueryClient } from "@tanstack/react-query";
import type { SiteCategories } from "../../../../../utils/backend-types";
import { ObjectName, Product } from "../../../../../utils/enums";
import type { FormValues } from "../../../../CustomForm/useCustomForm";
import type { FieldsDict } from "../../../../OptionsForm/OptionsForm";
import { getOrderedFormFieldsFromResponseAndValues } from "../common";
import {
  buildHistoryData,
  filterValues,
  mapDependenciesToSelectedFields,
  saveDataBasic
} from "./common";
import type { FieldDependency, Form } from "./data-types";
import { buildFieldNameToLabelMapOldForms } from "./utils/buildFieldNameToLabelMapOldForms";
import { loadGeneralDataFields } from "./utils/loadGeneralDataFields";

export const GENERAL_FIELDS_PART_1 = {
  NAME: "name",
  SHOULD_REGULATORY_DUTIES_BE_CHECKED: "shouldRegulatoryDutiesBeChecked",
  IS_PARAGRAPH_6_EEG_ACTIVE: "isParagraph_6EegActive",
  IS_ERLOESMONITORING_ACTIVE: "isErloesmonitoringActive"
};

export const GENERAL_FIELDS_PART_2 = {
  ADDRESS: "address",
  ZIP: "zip",
  CITY: "city",
  CONTACT: "contact",
  APPLICANT_LEGAL_STRUCTURE: "taxFormsApplicantLegalStructure"
};

export const GENERAL_PARTNER_FIELDS = {
  GENERAL_PARTNER_NAME: "generalPartnerName",
  GENERAL_PARTNER_CITY: "generalPartnerCity",
  GENERAL_PARTNER_COURT: "generalPartnerCourt",
  GENERAL_PARTNER_REGISTER_NUMBER: "generalPartnerRegisterNumber",
  GENERAL_PARTNER_CEO: "generalPartnerCeo"
};

export const GENERAL_FIELDS = {
  ...GENERAL_FIELDS_PART_1,
  ...GENERAL_FIELDS_PART_2,
  ...GENERAL_PARTNER_FIELDS
};

export const GENERAL_FIELDS_MIETER = {
  NAME: "name",
  IS_SOLAR_CONTRACTOR: "isSolarContractor",
  SHOULD_REGULATORY_DUTIES_BE_CHECKED: "shouldRegulatoryDutiesBeChecked",
  PARTICIPATIONS_IN_MIETERSTROM_MODEL: "participationsInMieterstromModel",
  ADDRESS: "address",
  ADDRESS_EXTRA: "addressExtra",
  ZIP: "zip",
  CITY: "city",
  CONTACT: "contact",
  ...GENERAL_PARTNER_FIELDS
};

const GENERAL_FIELDS_SOLAR_CONTRACTOR = {
  NAME: "name",
  IS_SOLAR_CONTRACTOR: "isSolarContractor",
  SHOULD_REGULATORY_DUTIES_BE_CHECKED: "shouldRegulatoryDutiesBeChecked",
  PARTICIPATIONS_IN_MIETERSTROM_MODEL: "participationsInMieterstromModel",
  ...GENERAL_FIELDS_PART_2,
  ...GENERAL_PARTNER_FIELDS
};

const GENERAL_FIELDS_ANALYZER = {
  ...GENERAL_FIELDS,
  FORESTRY_OR_AGRICULTURE: "forestryOrAgriculture",
  PENSION_SALARIES_PAID: "pensionSalariesPaid",
  IS_INTERNAL_SUPPLIER: "isInternalSupplier"
};

const HIDDEN_IF_NULL_GENERAL_FIELDS = [GENERAL_FIELDS.CONTACT];

const HIDDEN_IF_NULL_GENERAL_FIELDS_ANALYZER = [
  ...HIDDEN_IF_NULL_GENERAL_FIELDS,
  GENERAL_FIELDS_ANALYZER.FORESTRY_OR_AGRICULTURE,
  GENERAL_FIELDS_ANALYZER.PENSION_SALARIES_PAID,
  GENERAL_FIELDS_ANALYZER.IS_INTERNAL_SUPPLIER
];

interface RegulatoryDataField {
  name: string;
  dependencies?: Array<FieldDependency>;
  hiddenIfNull?: boolean;
  product?: Product;
  showOnlyForSiteCategoriesWhenManager?: Partial<SiteCategories>;
}

const REGULATORY_DATA_FIELDS: Array<RegulatoryDataField> = [
  {
    name: "manufacturingIndustry",
    hiddenIfNull: false,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "forestryOrAgriculture",
    hiddenIfNull: false,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "electricityOrEnergyTaxRelief_10StromstgOr_55Energiestg",
    product: Product.Manager,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "dinEnIso_50001Installed",
    hiddenIfNull: false,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "emasInstalled",
    hiddenIfNull: false,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "dinEn16247_1Installed",
    hiddenIfNull: false,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "energyTaxRelief_54Energiestg",
    product: Product.Manager,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "energyTaxRelief_51Energiestg",
    product: Product.Manager,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "isYearlyReportOfTaxReliefsRequired",
    product: Product.Manager,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  { name: "isRegisteredAsEltvuAtHertz", product: Product.Manager },
  { name: "isRegisteredAsEltvuAtAmprion", product: Product.Manager },
  { name: "isRegisteredAsEltvuAtTennet", product: Product.Manager },
  { name: "isRegisteredAsEltvuAtTransnet", product: Product.Manager },
  {
    name: "makesUseOfExemptsOver_500_000PerYear",
    product: Product.Manager,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "electricityConsumptionOver_50GwhPerYear",
    product: Product.Manager,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "makesUseOfBesondereAusgleichsregelung",
    product: Product.Manager,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "makesUseOfBeihilfenStrompreiskompensation",
    product: Product.Manager,
    showOnlyForSiteCategoriesWhenManager: { is_eigenerzeugung_plus: true }
  },
  {
    name: "smallMediumEnterprise",
    hiddenIfNull: true,
    product: Product.Manager
  }
];

const TAX_FORMULAE_DATA_1_FIELDS = [
  "customsOffice",
  "taxFormsSubmissionInOwnName",
  "taxFormsCompanyRepresentedBy",
  "taxFormsRepresentationType"
];

const TAX_FORMULAE_DATA_2_FIELDS = [
  "taxFormsApplicantName",
  "supplierAccordingToStromstg",
  "supplierLicenseValidityDate",
  "mastrNummerMarktakteur",
  "taxFormsApplicantDateOfBirthOrFoundation",
  "taxFormsApplicantStreet",
  "taxFormsApplicantHouseNumber",
  "taxFormsApplicantAddressSupplement",
  "taxFormsApplicantZip",
  "taxFormsApplicantCity",
  "taxFormsApplicantCityDistrict",
  "taxFormsApplicantCountry",
  "taxFormsApplicantResidenceOrOffice",
  "taxFormsApplicantOtherCity"
];

const TAX_FORMULAE_DATA_3_FIELDS = ["unternehmenInSchwierigkeiten"];

const TAX_FORMULAE_DATA_4_FIELDS = [
  "taxFormsApplicantNumber",
  "companyNumber",
  "taxFormsApplicantTransactionNumberElectricityTax"
];

const TAX_FORMULAE_DATA_5_FIELDS = [
  "taxFormsContactName",
  "taxFormsContactPhone",
  "taxFormsContactFax",
  "taxFormsContactEmail",
  "taxFormsContactWebsite"
];

const TAX_FORMULAE_DATA_6_FIELDS = [
  "taxFormsBankAccountHolder",
  "taxFormsBankAccountIban",
  "taxFormsBankAccountBic"
];

const DELIVERY_AND_INVOICES_SUPPLIER_FIELDS = [
  "invoiceSupplierPhone",
  "invoiceSupplierFax",
  "invoiceSupplierEmail",
  "invoiceSupplierUrl",
  "invoiceSupplierCeo",
  "invoiceSupplierIsGbr",
  "invoiceSupplierCourt",
  "invoiceSupplierRegisterNumber",
  "invoiceSupplierLocation",
  "invoiceSupplierLogo",
  "invoiceSupplierSalesTaxId",
  "invoiceSupplierTaxNumber",
  "invoiceSupplierBank",
  "invoiceSupplierIban",
  "invoiceSupplierBic",
  "invoiceSupplierCreditorId"
];

const DELIVERY_AND_INVOICES_SUPPLIED_FIELDS = [
  "invoiceSuppliedCustomerNumber",
  "invoiceSuppliedEmail",
  "invoiceSuppliedBusinessReference",
  "invoiceSuppliedBank",
  "invoiceSuppliedIban",
  "invoiceSuppliedBic",
  "invoiceRecipient",
  "invoiceSuppliedAddressIsGeneralAddress",
  "invoiceSuppliedAddress",
  "invoiceSuppliedAddressExtra",
  "invoiceSuppliedZip",
  "invoiceSuppliedCity"
];

const DELIVERY_AND_INVOICES_SUPPLIED_BASE_DATA_FIELDS = [
  "invoiceSuppliedDebitNumber",
  "invoiceSuppliedAccountingArea",
  "invoiceSuppliedTargetAccountingArea",
  "invoiceSuppliedBusinessArea",
  "invoiceSuppliedCostCenter",
  "invoiceSuppliedPspElement",
  "invoiceSuppliedProfitCenter",
  "invoiceSuppliedGlobalId"
];
const DELIVERY_AND_INVOICES_SUPPLIED_INFORMATION_FIELDS = [
  "invoiceSuppliedInformationCategory",
  "invoiceSuppliedInformation"
];

const PERSON_EDIT_TABS = {
  TAB_GENERAL: "general",
  TAB_REGULATORY_DATA: "regulatorische-daten",
  TAB_TAX_FORMULAE_DATA: "daten-steuerformulare",
  TAB_DELIVERIES_AND_INVOICES: "stromlieferung-und-abrechnung",
  TAB_HISTORY: "historie"
};

export function loadPersonData(
  queryClient: QueryClient,
  personId: number,
  variantId: number,
  product: Product,
  siteCategories?: SiteCategories
) {
  return loadGeneralDataFields(
    queryClient,
    ObjectName.Person,
    personId,
    variantId
  )
    .catch((error) => Promise.reject(error))
    .then((response) => {
      const generalData = buildGeneralData(
        response.option.data.actions.pOST,
        response.detail.data,
        product
      );

      const forms = [generalData];

      const shouldRegulatoryDutiesBeChecked =
        response.detail.data[
          GENERAL_FIELDS.SHOULD_REGULATORY_DUTIES_BE_CHECKED
        ];
      const shouldTaxFormulaeDataBeShown =
        response.detail.data[
          GENERAL_FIELDS.SHOULD_REGULATORY_DUTIES_BE_CHECKED
        ];

      if (shouldRegulatoryDutiesBeChecked) {
        const regulatoryData = buildRegulatoryData(
          response.option.data.actions.pOST,
          response.detail.data,
          product,
          siteCategories
        );

        if (regulatoryData) {
          forms.push(regulatoryData);
        }
      }

      if (product === Product.Manager && shouldTaxFormulaeDataBeShown) {
        const taxFormulaeData = buildTaxFormulaeData(
          response.option.data.actions.pOST,
          response.detail.data,
          variantId
        );

        forms.push(taxFormulaeData);
      }

      const isFullFeedin = !!siteCategories?.is_full_feedin;

      const deliveryAndInvoicesData = buildDeliveryAndInvoicesData(
        response.option.data.actions.pOST,
        response.detail.data,
        personId,
        isFullFeedin
      );

      if (deliveryAndInvoicesData) {
        forms.push(deliveryAndInvoicesData);
      }

      const personHistory = buildHistoryData(
        PERSON_EDIT_TABS.TAB_HISTORY,
        ObjectName.Person,
        personId,
        buildFieldNameToLabelMapOldForms(forms)
      );

      forms.push(personHistory);
      return Promise.resolve(forms);
    });
}

function buildGeneralData(
  fieldData: FieldsDict,
  valueData: FormValues,
  product: Product
): Form {
  const selectedFormFields = getOrderedFormFieldsFromResponseAndValues(
    product === Product.Analyzer
      ? Object.values(GENERAL_FIELDS_ANALYZER)
      : valueData.isInMieterstromProject
        ? valueData.isSolarContractor
          ? Object.values(GENERAL_FIELDS_SOLAR_CONTRACTOR)
          : Object.values(GENERAL_FIELDS_MIETER)
        : Object.values(GENERAL_FIELDS),
    product === Product.Analyzer
      ? HIDDEN_IF_NULL_GENERAL_FIELDS_ANALYZER
      : HIDDEN_IF_NULL_GENERAL_FIELDS,
    fieldData,
    valueData
  );

  return {
    name: PERSON_EDIT_TABS.TAB_GENERAL,
    formTitle: "Allgemeine Daten",
    extraProps: {},
    sections: [
      {
        fields: selectedFormFields,
        values: filterValues(valueData, selectedFormFields),
        errors: {}
      }
    ]
  } satisfies Form;
}

function buildRegulatoryData(
  fieldData: FieldsDict,
  valueData: FormValues,
  product: Product,
  siteCategories?: SiteCategories
) {
  let filteredRegulatoryDataFields = REGULATORY_DATA_FIELDS.filter(
    (field) => !field.product || field.product === product
  );

  if (product === Product.Manager) {
    filteredRegulatoryDataFields = filterRegulatoryDataFieldsBySiteCategories(
      filteredRegulatoryDataFields,
      siteCategories
    );
  }

  const regulatoryDataFieldNames = filteredRegulatoryDataFields.map(
    (field) => field.name
  );
  const hiddenIfNullRegulatoryDataFields = REGULATORY_DATA_FIELDS.filter(
    (field) => field.hiddenIfNull
  );
  const hiddenIfNullRegulatoryDataFieldNames =
    hiddenIfNullRegulatoryDataFields.map((field) => field.name);

  const selectedFormFieldsRegulatoryData =
    getOrderedFormFieldsFromResponseAndValues(
      regulatoryDataFieldNames,
      hiddenIfNullRegulatoryDataFieldNames,
      fieldData,
      valueData
    );

  const selectedFormFieldsRegulatoryDataWithDependencies =
    mapDependenciesToSelectedFields(
      selectedFormFieldsRegulatoryData,
      filteredRegulatoryDataFields
    );

  if (selectedFormFieldsRegulatoryDataWithDependencies.length === 0) {
    return null;
  }

  return {
    name: PERSON_EDIT_TABS.TAB_REGULATORY_DATA,
    formTitle: "Regulatorische Daten",
    extraProps: {},
    sections: [
      {
        fields: selectedFormFieldsRegulatoryDataWithDependencies,
        values: filterValues(
          valueData,
          selectedFormFieldsRegulatoryDataWithDependencies
        ),
        errors: {}
      }
    ]
  } as Form;
}

function buildTaxFormulaeData(
  fieldData: FieldsDict,
  valueData: FormValues,
  variantId: number
) {
  const selectedFormFieldsTaxFormulae1 =
    getOrderedFormFieldsFromResponseAndValues(
      TAX_FORMULAE_DATA_1_FIELDS,
      [],
      fieldData,
      valueData
    );

  const selectedFormFieldsTaxFormulae2 =
    getOrderedFormFieldsFromResponseAndValues(
      TAX_FORMULAE_DATA_2_FIELDS,
      [],
      fieldData,
      valueData
    );

  const selectedFormFieldsTaxFormulae3 =
    getOrderedFormFieldsFromResponseAndValues(
      TAX_FORMULAE_DATA_3_FIELDS,
      [],
      fieldData,
      valueData
    );

  const selectedFormFieldsTaxFormulae4 =
    getOrderedFormFieldsFromResponseAndValues(
      TAX_FORMULAE_DATA_4_FIELDS,
      [],
      fieldData,
      valueData
    );

  const selectedFormFieldsTaxFormulae5 =
    getOrderedFormFieldsFromResponseAndValues(
      TAX_FORMULAE_DATA_5_FIELDS,
      [],
      fieldData,
      valueData
    );

  const selectedFormFieldsTaxFormulae6 =
    getOrderedFormFieldsFromResponseAndValues(
      TAX_FORMULAE_DATA_6_FIELDS,
      [],
      fieldData,
      valueData
    );

  return {
    name: PERSON_EDIT_TABS.TAB_TAX_FORMULAE_DATA,
    formTitle: "Daten Steuerformulare",
    extraProps: { variantId: variantId, personId: valueData.id },
    sections: [
      {
        header: "Allgemein",
        fields: selectedFormFieldsTaxFormulae1,
        values: filterValues(valueData, selectedFormFieldsTaxFormulae1),
        errors: {}
      },
      {
        header: "Antragsteller Steuerformulare",
        fields: selectedFormFieldsTaxFormulae2,
        values: filterValues(valueData, selectedFormFieldsTaxFormulae2),
        errors: {}
      },
      {
        header: "EU-Beihilferecht",
        fields: selectedFormFieldsTaxFormulae3,
        values: filterValues(valueData, selectedFormFieldsTaxFormulae3),
        errors: {}
      },
      {
        header: "Zusatzinformationen für die HZA-Kommunikation",
        fields: selectedFormFieldsTaxFormulae4,
        values: filterValues(valueData, selectedFormFieldsTaxFormulae4),
        errors: {}
      },
      {
        header: "Ansprechpartner Steuerformulare",
        fields: selectedFormFieldsTaxFormulae5,
        values: filterValues(valueData, selectedFormFieldsTaxFormulae5),
        errors: {}
      },
      {
        header: "Bankverbindung Steuerformulare",
        fields: selectedFormFieldsTaxFormulae6,
        values: filterValues(valueData, selectedFormFieldsTaxFormulae6),
        errors: {}
      }
    ]
  } as Form;
}

function buildDeliveryAndInvoicesData(
  fieldData: FieldsDict,
  valueData: FormValues,
  personId: number,
  isFullFeedin: boolean
) {
  const isSupplier = valueData.isSolarContractor;

  const selectedFormFieldsSupplier = getOrderedFormFieldsFromResponseAndValues(
    DELIVERY_AND_INVOICES_SUPPLIER_FIELDS,
    [],
    fieldData,
    valueData
  );

  const selectedFormFieldsSupplied = getOrderedFormFieldsFromResponseAndValues(
    DELIVERY_AND_INVOICES_SUPPLIED_FIELDS,
    [],
    fieldData,
    valueData
  );

  const selectedFormFieldSuppliedInformation =
    getOrderedFormFieldsFromResponseAndValues(
      DELIVERY_AND_INVOICES_SUPPLIED_INFORMATION_FIELDS,
      [],
      fieldData,
      valueData
    );
  const selectedFormFieldsSuppliedBaseData =
    getOrderedFormFieldsFromResponseAndValues(
      DELIVERY_AND_INVOICES_SUPPLIED_BASE_DATA_FIELDS,
      [],
      fieldData,
      valueData
    );

  if (
    selectedFormFieldsSupplier.length === 0 &&
    selectedFormFieldsSupplied.length === 0
  ) {
    return null;
  }

  return {
    name: PERSON_EDIT_TABS.TAB_DELIVERIES_AND_INVOICES,
    formTitle: "Stromlieferung und Abrechnung",
    extraProps: { personId },
    sections: [
      {
        header: !isFullFeedin ? "Für die Lieferanten" : undefined,
        fields: selectedFormFieldsSupplier,
        values: filterValues(valueData, selectedFormFieldsSupplier),
        errors: {},
        initialToggle: isSupplier,
        isCollapsible: !isFullFeedin
      },
      {
        header: "Für die Belieferten",
        fields: selectedFormFieldsSupplied,
        values: filterValues(valueData, selectedFormFieldsSupplied),
        errors: {},
        initialToggle: !isSupplier,
        isCollapsible: true
      },
      {
        header: "Stammdaten für die Abrechnung",
        fields: selectedFormFieldsSuppliedBaseData,
        values: filterValues(valueData, selectedFormFieldsSuppliedBaseData),
        errors: {},
        isCollapsible: true,
        initialToggle: !isSupplier,
        isSubSection: true
      },
      {
        header:
          "Individuelle Informationen auf der Rechnung für diesen Belieferten ausweisen",
        fields: selectedFormFieldSuppliedInformation,
        values: filterValues(valueData, selectedFormFieldSuppliedInformation),
        errors: {},
        isCollapsible: true,
        initialToggle: !isSupplier,
        isSubSection: true
      }
    ]
  } as Form;
}

function filterRegulatoryDataFieldsBySiteCategories(
  fields: Array<RegulatoryDataField>,
  siteCategories?: SiteCategories
) {
  return fields.filter((field) => {
    if (!field.showOnlyForSiteCategoriesWhenManager || !siteCategories) {
      return true;
    }

    let shouldFilter = true;

    if (field.showOnlyForSiteCategoriesWhenManager.is_eigenerzeugung_plus) {
      shouldFilter = shouldFilter && !!siteCategories.is_eigenerzeugung_plus;
    }

    if (field.showOnlyForSiteCategoriesWhenManager.is_premium) {
      shouldFilter = shouldFilter && !!siteCategories.is_premium;
    }

    if (field.showOnlyForSiteCategoriesWhenManager.is_partial_feedin_site) {
      shouldFilter = shouldFilter && !!siteCategories.is_partial_feedin_site;
    }

    return shouldFilter;
  });
}

export function savePersonData(personId: number, forms: Array<Form>) {
  return saveDataBasic(ObjectName.Person, personId, forms);
}
