import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../../api";
import urls from "../../../../../urls";
import type { GridFees } from "../../../../../utils/backend-types";

export enum GridUsageBillingModel {
  SLP = "slp",
  RLM = "rlm"
}

export function useGridFees(
  siteId: number | null | undefined,
  gridUsageBillingModel: GridUsageBillingModel | undefined,
  options?: Partial<UseQueryOptions<GridFees | null>>
) {
  const {
    isLoading: isGridFeesDataLoading,
    error: gridFeesLoadingError,
    data: gridFees
  } = useQuery({
    queryKey: ["gridFees", { siteId, gridUsageBillingModel }],
    queryFn: () => fetchGridFees(siteId, gridUsageBillingModel),
    enabled: !!siteId,
    gcTime: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    ...options
  });

  return {
    isGridFeesDataLoading,
    gridFeesLoadingError,
    gridFees
  };
}

async function fetchGridFees(
  siteId: number | undefined | null,
  gridUsageBillingModel: GridUsageBillingModel | undefined
) {
  if (!siteId || !gridUsageBillingModel) {
    return null;
  }

  const endpointUrl =
    gridUsageBillingModel === GridUsageBillingModel.RLM
      ? urls.api.rlmGridFees(siteId)
      : urls.api.slpGridFees(siteId);
  const response = await api.get<GridFees>(endpointUrl);

  return response.data;
}
