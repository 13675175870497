import { merge } from "lodash";
import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_SortingState,
  type MRT_TableOptions,
  useMantineReactTable
} from "mantine-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponse } from "../../../../api";
import { useMeteringOrMarketLocations } from "../../../../hooks/useMeteringOrMarketLocations";
import { useSuggestedMeteringOrMarketLocation } from "../../../../hooks/useSuggestedMeteringOrMarketLocation";
import { getDefaultMRTOptions } from "../../../../mantine/getDefaultMRTOptions";
import { getDefaultMRTRowEditModeOptions } from "../../../../mantine/getDefaultMRTRowEditModeOptions";
import { getMRTTopToolbarCustomActionsRenderer } from "../../../../mantine/getMRTTopToolbarCustomActionsRenderer";
import { convertApiErrorsToMrtErrors } from "../../../../mantine/mrt/utils/convertApiErrorsToMrtErrors";
import { isMrtCreating } from "../../../../mantine/mrt/utils/isMrtCreating";
import { THEME_VARS } from "../../../../utils/constants";
import { getErrorText } from "../../../../utils/get-error-text";
import { showToast } from "../../../../utils/toast";
import { MultiConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import type {
  DirektvermarktungGeneratorShareFormValues,
  DirektvermarktungGeneratorShareResponse,
  DirektvermarktungUnassignedGeneratorsResponse
} from "../../Direktvermarktung.types";
import { useDirektvermarktungUnassignedGenerators } from "../../hooks/useDirektvermarktungUnassignedGenerators";
import { getGeneratorColumn } from "./Columns/getGeneratorColumn";
import { getWeightingColumn } from "./Columns/getWeightingColumn";
import { getZaehlpunktColumn } from "./Columns/getZaehlpunktColumn";
import { validateDirektvermarktungGeneratorShareFormValues } from "./utils/validateDirektvermarktungGeneratorShareFormValues";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "generator_display_name",
    desc: false
  }
];

interface DirektvermarktungGeneratorShareTableProps {
  generatorShares: Array<DirektvermarktungGeneratorShareResponse>;
  contractUuid: string;
  onDelete: (generatorShareId: number) => Promise<void>;
  onSubmit: (
    updatedGenerator: DirektvermarktungGeneratorShareFormValues,
    generatorShareId?: number
  ) => Promise<ApiResponse>;
}

function DirektvermarktungGeneratorShareTable({
  generatorShares,
  contractUuid,
  onDelete,
  onSubmit
}: DirektvermarktungGeneratorShareTableProps) {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [generatorSharesToBeDeleted, setGeneratorSharesToBeDeleted] = useState<
    Array<DirektvermarktungGeneratorShareResponse>
  >([]);
  const [currentlySelectedGeneratorId, setCurrentlySelectedGeneratorId] =
    useState<number | undefined>(undefined);
  const [
    currentlySelectedGeneratorSiteId,
    setCurrentlySelectedGeneratorSiteId
  ] = useState<number | undefined>(undefined);

  const {
    data: direktvermarktungUnassignedGenerators,
    isLoading,
    error
  } = useDirektvermarktungUnassignedGenerators({
    contractUuid
  });

  const {
    meteringOrMarketLocations: meloMalosForSelectedGenerator,
    isLoading: meloMalosForSelectedGeneratorLoading
  } = useMeteringOrMarketLocations(currentlySelectedGeneratorSiteId);

  const {
    suggestedMeteringOrMarketLocation,
    isLoading: suggestedMeteringOrMarketLocationLoading
  } = useSuggestedMeteringOrMarketLocation(currentlySelectedGeneratorId);

  const currentlySelectedUnassignedGenerator =
    direktvermarktungUnassignedGenerators?.find(
      (generator) => generator.generator_id === currentlySelectedGeneratorId
    );
  const currentlySelectedGeneratorMeteringOrMarketLocationId =
    currentlySelectedUnassignedGenerator?.metering_or_market_location_id ??
    undefined;
  const currentlySelectedGeneratorWeighting =
    currentlySelectedUnassignedGenerator?.weighting ?? undefined;

  const columns = useMemo<
    Array<MRT_ColumnDef<DirektvermarktungGeneratorShareResponse>>
  >(
    () => [
      getGeneratorColumn({
        validationErrors: validationErrors,
        unassignedGenerators: direktvermarktungUnassignedGenerators,
        onChange: (
          generator: DirektvermarktungUnassignedGeneratorsResponse
        ) => {
          setCurrentlySelectedGeneratorId(generator.generator_id);
          setCurrentlySelectedGeneratorSiteId(generator.site_id);
        }
      }),
      getZaehlpunktColumn<DirektvermarktungGeneratorShareResponse>({
        validationErrors,
        meloMalos: meloMalosForSelectedGenerator,
        suggestedMeloMaloId: suggestedMeteringOrMarketLocation?.id,
        overrideMeteringOrMarketLocationId:
          currentlySelectedGeneratorMeteringOrMarketLocationId,
        isLoading:
          meloMalosForSelectedGeneratorLoading ||
          suggestedMeteringOrMarketLocationLoading
      }),
      getWeightingColumn<DirektvermarktungGeneratorShareResponse>({
        validationErrors,
        overrideShareValue: currentlySelectedGeneratorWeighting
      })
    ],
    [
      currentlySelectedGeneratorMeteringOrMarketLocationId,
      currentlySelectedGeneratorWeighting,
      direktvermarktungUnassignedGenerators,
      meloMalosForSelectedGenerator,
      meloMalosForSelectedGeneratorLoading,
      suggestedMeteringOrMarketLocation?.id,
      suggestedMeteringOrMarketLocationLoading,
      validationErrors
    ]
  );

  const handleCreateGenerator: MRT_TableOptions<DirektvermarktungGeneratorShareResponse>["onCreatingRowSave"] =
    async function ({ values, exitCreatingMode }) {
      await createOrEditGenerator(values, exitCreatingMode);
    };

  const handleSaveGenerator: MRT_TableOptions<DirektvermarktungGeneratorShareResponse>["onEditingRowSave"] =
    async function ({ row, values, table }) {
      await createOrEditGenerator(
        values,
        () => table.setEditingRow(null) /* exit editing mode */,
        row.original.generator_id
      );
    };

  async function createOrEditGenerator(
    values: MRT_Row<DirektvermarktungGeneratorShareResponse>["_valuesCache"],
    exitCreatingOrEditingMode: () => void,
    generatorShareId?: number
  ) {
    const newValidationErrors =
      validateDirektvermarktungGeneratorShareFormValues(values, t);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setIsSaving(true);
    setValidationErrors({});

    const currentlyEditingGeneratorId =
      table.getState().editingRow?.original.generator_id;

    try {
      await onSubmit(
        {
          ...values,
          generator_id:
            direktvermarktungUnassignedGenerators?.find(
              (generator) =>
                generator.generator_display_name ===
                values.generator_display_name
            )?.generator_id ||
            currentlyEditingGeneratorId ||
            values.generator_id,
          metering_or_market_location_id: Number(
            values.metering_or_market_location_id
          )
        },
        generatorShareId
      );
    } catch (error) {
      if (error.response.status === 400) {
        const validationErrors = convertApiErrorsToMrtErrors(
          error.response.data
        );

        if (Object.keys(validationErrors).length > 0) {
          setValidationErrors(validationErrors);
        } else {
          showToast("error", error);
        }

        setIsSaving(false);
        return;
      } else {
        showToast("error", error);
      }
    }

    exitCreatingOrEditingMode();
    setIsSaving(false);
    setCurrentlySelectedGeneratorId(undefined);
    setCurrentlySelectedGeneratorSiteId(undefined);
  }

  const defaultOptions =
    getDefaultMRTOptions<DirektvermarktungGeneratorShareResponse>({
      emptyRowsFallbackText: "Fügen Sie den ersten Erzeuger hinzu.",
      searchTextInput: {
        placeholder: "Suche nach Erzeugern"
      },
      hideInternalToolbar: true
    });

  const rowEditModeOptions =
    getDefaultMRTRowEditModeOptions<DirektvermarktungGeneratorShareResponse>({
      rowActions: {
        edit: {
          text: "Erzeuger bearbeiten"
        },
        delete: {
          text: "Erzeuger löschen",
          onClick: (generators) => setGeneratorSharesToBeDeleted(generators)
        }
      }
    });

  const tableOptions = merge({}, defaultOptions, rowEditModeOptions, {
    ...getMRTTopToolbarCustomActionsRenderer<DirektvermarktungGeneratorShareResponse>(
      {
        create: {
          text: "Erzeuger hinzufügen",
          disabled:
            !direktvermarktungUnassignedGenerators ||
            direktvermarktungUnassignedGenerators.length === 0
        },
        delete: {
          onClick: () => {
            const generationShares = table
              .getSelectedRowModel()
              .rows.map((row) => row.original)
              .filter((generationShare) => generationShare.weighting !== "");
            setGeneratorSharesToBeDeleted(generationShares);
          }
        }
      }
    ),
    defaultColumn: {
      size: 300,
      minSize: 180
    },
    enablePagination: false,
    enableBottomToolbar: false,
    displayColumnDefOptions: {
      "mrt-row-select": {
        visibleInShowHideMenu: false,
        size: 40,
        minSize: 40
      },
      "mrt-row-actions": {
        visibleInShowHideMenu: false,
        size: 40,
        minSize: 40
      }
    },
    initialState: {
      sorting: DEFAULT_SORTED
    },
    mantineToolbarAlertBannerProps: error
      ? {
          color: THEME_VARS.dangerColor,
          children: getErrorText(error)
        }
      : undefined,
    state: {
      isLoading: isLoading,
      isSaving: isSaving || isDeleting,
      showAlertBanner: !!error
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateGenerator,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveGenerator
  });

  const table = useMantineReactTable<DirektvermarktungGeneratorShareResponse>({
    ...tableOptions,
    columns,
    data: generatorShares
  });

  const tableIsCreating = isMrtCreating(table);
  const tableEditingRow = table.getState().editingRow;

  useEffect(() => {
    if (tableIsCreating) {
      setCurrentlySelectedGeneratorSiteId(undefined);
    }
  }, [tableIsCreating]);

  useEffect(() => {
    if (tableEditingRow?.original.generator_id !== undefined) {
      setCurrentlySelectedGeneratorId(tableEditingRow?.original.generator_id);
    }
    if (tableEditingRow?.original.site_id !== undefined) {
      setCurrentlySelectedGeneratorSiteId(tableEditingRow?.original.site_id);
    }
  }, [tableEditingRow]);

  const namesOfGeneratorSharesToBeDeleted = generatorSharesToBeDeleted.map(
    (generatorShare) => generatorShare.generator_display_name
  );
  const denOrDie =
    namesOfGeneratorSharesToBeDeleted.length === 1 ? "den" : "die";

  return (
    <LoadOrError
      error={error}
      loading={isLoading}
      loadingMessage="Erzeugerdaten werden geladen ..."
    >
      <div className="DirektvermarktungGeneratorTable">
        <MantineReactTable table={table} />
        <MultiConfirmationModal
          actionName="löschen"
          actionObjects={namesOfGeneratorSharesToBeDeleted}
          confirmationText={`Möchten Sie ${denOrDie} folgenden Erzeuger löschen? Dieser Schritt kann nicht rückgängig gemacht werden.`}
          isModalOpen={generatorSharesToBeDeleted.length > 0}
          objectName="Erzeuger"
          toggleModal={() => setGeneratorSharesToBeDeleted([])}
          onAction={async () => {
            setIsDeleting(true);

            await Promise.all(
              generatorSharesToBeDeleted.map((generatorShare) =>
                onDelete(generatorShare.generator_id)
              )
            );

            setGeneratorSharesToBeDeleted([]);
            table.setRowSelection({}); // MRT doesn't auto-clear the selected rows after deletion
            setIsDeleting(false);
          }}
        />
      </div>
    </LoadOrError>
  );
}

export {
  DirektvermarktungGeneratorShareTable,
  DirektvermarktungGeneratorShareTableProps
};
