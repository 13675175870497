import type { TodosApiUrlOptions } from "../../../urls";
import { CATEGORIES, Mode, TodoUserDecision } from "../common";

export function getApiOptions({ mode, variantId, isRecurring }) {
  let options: TodosApiUrlOptions;

  switch (mode) {
    case Mode.Universal:
      options = {
        userDecision: TodoUserDecision.Confirmed,
        category: [
          CATEGORIES.ONBOARDING.value,
          CATEGORIES.REGULATORY_DUTY.value
        ],
        demo: false,
        byUser: true,
        isRecurring
      };
      break;
    case Mode.Onboarding:
      options = {
        variantId: variantId,
        userDecision: TodoUserDecision.Confirmed,
        category: CATEGORIES.ONBOARDING.value,
        isRecurring: isRecurring
      };
      break;
    default:
      options = {
        variantId: variantId,
        userDecision: TodoUserDecision.Confirmed,
        category: Object.values(CATEGORIES)
          .filter((category) => category.value !== CATEGORIES.ONBOARDING.value)
          .map((category) => category.value),
        isRecurring: isRecurring
      };
      break;
  }

  return options;
}
