import React from "react";
import { Link } from "react-router-dom";
import type { Paragraph6CreditError } from "../../../Paragraph6.types";
import type { Paragraph6CreditErrorMessageDetailProps } from "../Paragraph6CreditErrorMessage";

function EnergyErrors({
  paragraph6CreditErrors
}: Paragraph6CreditErrorMessageDetailProps) {
  const energyErrors = paragraph6CreditErrors.filter(
    (error) =>
      error.energy_data_check_failed ||
      error.billing_period.fiktive_strommengen_are_imprecise
  );

  if (!(energyErrors.length > 0)) {
    return null;
  }

  const energyDataCheckFailed =
    energyErrors.filter((e) => e.energy_data_check_failed).length > 0;
  const fiktiveStrommengenAreImprecise =
    energyErrors.filter(
      (e: Paragraph6CreditError) =>
        e.billing_period.fiktive_strommengen_are_imprecise
    ).length > 0;

  return (
    <li>
      {energyDataCheckFailed && (
        <>
          <Link to="../../energiedaten/bereitstellen">Energiedaten</Link> im
          Zeitraum:{" "}
        </>
      )}
      {!energyDataCheckFailed && fiktiveStrommengenAreImprecise && (
        <>
          Fiktive Strommengen, die den Abrechnungszeitraum der Gutschrift
          überschreiten. Gleichverteilung erfolgt vom{" "}
        </>
      )}
      {energyErrors
        ?.map(
          (energyError) =>
            `${energyError.billing_period.start_date} bis ${energyError.billing_period.end_date}`
        )
        .join(", ")}
    </li>
  );
}

export { EnergyErrors };
