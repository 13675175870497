import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";

export function getMarketLocationFeedoutColumn<T extends MRT_RowData>({
  errorText,
  onChange
}: {
  errorText?: string | null;
  onChange?: (value: MRT_RowData) => void;
}): MRT_ColumnDef<T> {
  return {
    accessorKey: "market_location_feedout",
    header: "Marktlokation (Entnahme)",
    mantineEditTextInputProps: ({ row }) => ({
      disabled: row.original.source === "pvPlant",
      error: errorText,
      onChange: () => onChange?.(row)
    })
  };
}
