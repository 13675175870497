import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { base64toUtf8 } from "../../../../utils/base64toUtf8";
import { FREQUENCY_CHOICES, UNITS } from "../../../../utils/constants";
import {
  LUXON_END_OF_LAST_MONTH,
  LUXON_START_OF_LAST_MONTH
} from "../../../../utils/dates";
import { Frequency, Unit } from "../../../../utils/enums";
import { DEFAULT_FILTERS } from "../EnergyDataAcquisitionSelectionModal/Filters/filter-utils";
import type { AcquisitionFilter } from "../EnergyDataView";
import { getSearchParamsFromEnergyDataPageState } from "../utils/getSearchParamsFromEnergyDataPageState";

export interface EnergyDataPageState {
  selectedAcquisitions: Array<number>;
  acquisitionFilters: Array<AcquisitionFilter>;
  filter: number | null;
  frequency: Frequency;
  firstDate: DateTime<true>;
  lastDate: DateTime<true>;
  unit: Unit;
}

const initialPageState: EnergyDataPageState = {
  selectedAcquisitions: [],
  acquisitionFilters: DEFAULT_FILTERS,
  filter: null,
  frequency: Frequency.QuarterHour,
  firstDate: LUXON_START_OF_LAST_MONTH,
  lastDate: LUXON_END_OF_LAST_MONTH,
  unit: Unit.KilowattHour
};

export function useEnergyDataPageState() {
  const [searchParams, setSearchParams] = useSearchParams();

  const validatedPageState = initialPageState;
  const pageStateKeys = Object.keys(validatedPageState);

  pageStateKeys.forEach((param) => {
    // on render, get search params & do safety constraint checks on them
    const searchParamValue = searchParams.get(param);

    // for any of the desired state props, do basic validation if there's a query param provided
    if (searchParamValue !== null) {
      // eslint-disable-next-line default-case
      switch (param) {
        case "selectedAcquisitions": {
          const potentialSelectedAcquisitions = searchParamValue
            .split(",")
            .map((a) => Number(a))
            .filter((a) => !isNaN(a) && a > 0);

          if (potentialSelectedAcquisitions.length > 0) {
            validatedPageState.selectedAcquisitions =
              potentialSelectedAcquisitions;
          }
          break;
        }

        case "acquisitionFilters": {
          const potentialAcquisitionFilters = base64toUtf8(searchParamValue);
          if (potentialAcquisitionFilters) {
            try {
              validatedPageState.acquisitionFilters = JSON.parse(
                potentialAcquisitionFilters
              );
            } catch (_) {
              // string not parsable into JSON -> use default filters instead
            }
          }
          break;
        }

        case "filter": {
          const potentialFilter = Number(searchParamValue);
          if (!isNaN(potentialFilter) && potentialFilter > 0) {
            validatedPageState.filter = potentialFilter;
          }
          break;
        }

        case "frequency": {
          const potentialFrequency = searchParamValue;
          if (
            Object.values(FREQUENCY_CHOICES)
              .map((f) => f.value as string)
              .includes(potentialFrequency)
          ) {
            validatedPageState.frequency = potentialFrequency as Frequency;
          }
          break;
        }

        case "firstDate": {
          const potentialFirstDate = DateTime.fromISO(searchParamValue);
          if (potentialFirstDate.isValid) {
            validatedPageState.firstDate = potentialFirstDate;

            if (validatedPageState.frequency === Frequency.Month) {
              validatedPageState.firstDate =
                validatedPageState.firstDate.startOf("month");
            } else if (validatedPageState.frequency === Frequency.Year) {
              validatedPageState.firstDate =
                validatedPageState.firstDate.startOf("year");
            }
          }
          break;
        }

        case "lastDate": {
          const potentialLastDate = DateTime.fromISO(searchParamValue);
          if (potentialLastDate.isValid) {
            if (potentialLastDate > validatedPageState.firstDate) {
              validatedPageState.lastDate = potentialLastDate;
            } else {
              validatedPageState.lastDate = validatedPageState.firstDate.plus({
                days: 1
              });
            }

            if (validatedPageState.frequency === Frequency.Month) {
              validatedPageState.lastDate =
                validatedPageState.lastDate.endOf("month");
            } else if (validatedPageState.frequency === Frequency.Year) {
              validatedPageState.lastDate =
                validatedPageState.lastDate.endOf("year");
            }
          }
          break;
        }

        case "unit": {
          const potentialUnit = searchParamValue;
          if (
            Object.values(UNITS)
              .map((u) => u.value as string)
              .includes(potentialUnit)
          ) {
            validatedPageState.unit = potentialUnit as Unit;
          }
          break;
        }
      }
    }
  });

  const [pageState, setPageState] = useState(validatedPageState);

  useEffect(() => {
    const updatedSearchParams =
      getSearchParamsFromEnergyDataPageState(pageState);
    setSearchParams(updatedSearchParams, { replace: true });
  }, [pageState, setSearchParams]);

  return {
    pageState,
    setPageState
  };
}
