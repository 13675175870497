import { Highlight } from "@mantine/core";
import React, { useMemo, useState } from "react";
import type { Column } from "react-table";
import { FINANCIAL_DOCUMENT_TYPES } from "../../../../utils/constants";
import { DeleteConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { CustomReactTable } from "../../../CustomReactTable/CustomReactTable";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { SearchBar } from "../../../SearchBar/SearchBar";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import { useParagraph6Cancellations } from "../../hooks/useParagraph6Cancellations";
import { useParagraph6CreditMutations } from "../../hooks/useParagraph6CreditMutations";
import { useParagraph6Credits } from "../../hooks/useParagraph6Credits";
import type { Paragraph6FinancialDocument } from "../../Paragraph6.types";
import { creditsBySearchString } from "../utils/filterCreditsBySearchString";
import { getCancelColumn } from "./Columns/getCancelColumn";
import { getCreditNumberColumn } from "./Columns/getCreditNumberColumn";
import { getDeleteColumn } from "./Columns/getDeleteColumn";
import { getDocumentColumn } from "./Columns/getDocumentColumn";
import { getStateColumn } from "./Columns/getStateColumn";
import "./Paragraph6CreditsTable.scss";

interface Paragraph6CreditsTableProps {
  variantId: number;
  isPaymentInformationView?: boolean;
}

function Paragraph6CreditsTable({
  variantId,
  isPaymentInformationView
}: Paragraph6CreditsTableProps) {
  const {
    credits = [],
    isLoading: paragraph6CreditsLoading,
    error: paragraph6CreditsError
  } = useParagraph6Credits(variantId);
  const { cancellations = [], isLoading: cancellationsLoading } =
    useParagraph6Cancellations(variantId);
  const shouldShowStaffView = useShouldShowStaffView();
  const [search, setSearch] = useState("");

  const rows = useMemo(() => {
    const items = isPaymentInformationView
      ? credits
      : [...credits, ...cancellations];

    return items
      .filter((credit) => {
        return isPaymentInformationView
          ? credit.is_payment_information
          : !credit.is_payment_information;
      })
      .filter((credit) => creditsBySearchString(search, credit));
  }, [credits, cancellations, search, isPaymentInformationView]);

  const [creditToDelete, setCreditToDelete] =
    useState<Paragraph6FinancialDocument | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<unknown | null>(null);

  const columns = useMemo(() => {
    const columns: Array<Column<Paragraph6FinancialDocument>> = [
      {
        Header: "Jahr",
        accessor: (row) => row.billing_year,
        id: "billingYear",
        style: { textAlign: "center" },
        width: 80
      },
      {
        Header: "Typ",
        accessor: (row) => {
          const type = row?.type ? FINANCIAL_DOCUMENT_TYPES[row.type] : "";
          return <Highlight highlight={search}>{type}</Highlight>;
        },
        id: "type",
        width: 95
      },
      getDocumentColumn(search),
      {
        Header: "Gesellschaft",
        accessor: (row) => (
          <Highlight highlight={search}>{row.supplier}</Highlight>
        ),
        id: "supplier"
      },
      {
        Header: "Gemeinde / Kommune",
        accessor: (row) => (
          <Highlight highlight={search}>{row.community_name}</Highlight>
        ),
        id: "communityName"
      },
      getCreditNumberColumn(search),
      getStateColumn(variantId),
      getCancelColumn(variantId)
    ];

    if (shouldShowStaffView) {
      columns.push(getDeleteColumn(handleClickDeleteCredit));
    }

    return columns;
  }, [shouldShowStaffView, variantId, search]);

  const { paragraph6CreditDeleteMutation } =
    useParagraph6CreditMutations(variantId);

  function handleClickDeleteCredit(credit: Paragraph6FinancialDocument) {
    setCreditToDelete(credit);
  }

  function handleUserAcceptsDeletion() {
    if (creditToDelete) {
      setIsDeleting(true);
      paragraph6CreditDeleteMutation.mutate(creditToDelete.id, {
        onError: (error) => {
          setDeleteError(error);
        },
        onSettled: () => {
          setIsDeleting(false);
          setCreditToDelete(null);
        }
      });
    }
  }

  function documentTypeToDelete() {
    if (!creditToDelete || !creditToDelete.type) return "";
    return (
      FINANCIAL_DOCUMENT_TYPES[creditToDelete.type] +
      " für " +
      creditToDelete.community_name
    );
  }

  return (
    <LoadOrError
      error={paragraph6CreditsError}
      loading={paragraph6CreditsLoading || cancellationsLoading}
      loadingMessage={`${isPaymentInformationView ? "Zahlungsinformationen" : "Gutschriften"} werden geladen ...`}
    >
      <div className="Paragraph6CreditsTable">
        <SearchBar
          btnHidden
          className="credits-search-bar"
          inputClassName="credits-search-bar-input"
          placeholder="Suche nach Typ, Dokument, Gesellschaft, Gemeinde / Kommune ..."
          onChange={(event) => setSearch(event.currentTarget.value)}
          onClear={() => setSearch("")}
        />
        <CustomReactTable
          columns={columns}
          data={rows}
          showPagination={rows.length > 5}
        />
        <DeleteConfirmationModal
          error={deleteError}
          isLoading={isDeleting}
          isModalOpen={!!creditToDelete}
          name={documentTypeToDelete()}
          toggleModal={() => setCreditToDelete(null)}
          onClickConfirm={handleUserAcceptsDeletion}
        />
      </div>
    </LoadOrError>
  );
}

export { Paragraph6CreditsTable, Paragraph6CreditsTableProps };
