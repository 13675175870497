import { useQuery } from "@tanstack/react-query";
import { type DateTime } from "luxon";
import { apiWithoutCamelization } from "../../../../api";
import urls from "../../../../urls";
import { type SitePlausibility } from "../../../../utils/backend-types";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";

export function useSitePlausibility(
  siteId: number,
  selectedTimeRange: [DateTime, DateTime]
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["site-plausibility", { siteId, selectedTimeRange }],
    queryFn: () => fetchSitePlausibility(siteId, selectedTimeRange),
    enabled: !!siteId
  });

  async function fetchSitePlausibility(
    siteId: number,
    selectedTimeRange: [DateTime, DateTime]
  ) {
    const response = await apiWithoutCamelization.get<SitePlausibility>(
      urls.apiWithoutCamelization.sitePlausibility(
        siteId,
        luxonDateTimeToBackendDateOrDateTime(selectedTimeRange[0], "ISO 8601"),
        luxonDateTimeToBackendDateOrDateTime(selectedTimeRange[1], "ISO 8601")
      )
    );
    return response.data;
  }

  return { data, isLoading, error };
}
