import { Text } from "@mantine/core";
import React, { useEffect } from "react";
import {
  type Control,
  type FieldErrors,
  type UseFormSetValue,
  type UseFormWatch
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  MeterMeasurementType,
  MeteringDirection
} from "../../../../types/api.types";
import { validateMarketLocationNumber } from "../../../../utils/validateMarketLocationNumber";
import { validateMeteringLocationNumber } from "../../../../utils/validateMeteringLocationNumber";
import { AlertColor } from "../../../Alert/Alert";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import { type SiteSetupProcessForForm } from "../../SiteSetupAssistant.types";
import { SITE_SETUP_ASSISTANT_FORM } from "../SiteSetupAssistantForms.constants";

interface ConnectionFormItemsProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  watch: UseFormWatch<SiteSetupProcessForForm>;
  onSetFormValue: UseFormSetValue<SiteSetupProcessForForm>;
}

function ConnectionFormItems({
  formControl,
  formErrors,
  watch,
  onSetFormValue
}: ConnectionFormItemsProps) {
  const { t } = useTranslation();

  const connectionMeasurementType = watch("connectionMeasurementType");
  const connectionLocationType = watch("connectionLocationType");
  const connectionMeteringDirection = watch("connectionMeteringDirection");
  const voltageLevelDeterminedAutomatically = watch(
    "voltageLevelDeterminedAutomatically"
  );

  useEffect(() => {
    if (
      connectionMeasurementType === MeterMeasurementType.NoMeter &&
      connectionLocationType === null
    ) {
      onSetFormValue("connectionLocationType", "Marktlokation");
    }
  }, [connectionLocationType, connectionMeasurementType, onSetFormValue]);

  return (
    <div className="ConnectionFormItems">
      {!voltageLevelDeterminedAutomatically && (
        <>
          <Text fw={500} mb="sm">
            Netzverknüpfungspunkt
          </Text>
          <FormFieldController
            control={formControl}
            data={SITE_SETUP_ASSISTANT_FORM.connectionVoltageLevel}
            error={formErrors.connectionVoltageLevel}
          />
          <IconAlert color={AlertColor.Warning} mb="xl">
            Die Spannungsebene des Netzverknüpfungspunktes konnte nicht aus dem
            Marktstammdatenregister importiert werden. Bitte ergänzen Sie die
            Spannungsebene manuell.
          </IconAlert>
        </>
      )}
      <Text fw={500} mb="sm">
        Netzübergabe
      </Text>
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.connectionMeasurementType}
        error={formErrors.connectionMeasurementType}
      />
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.connectionMeteringDirection}
        error={formErrors.connectionMeteringDirection}
      />
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.connectionMeteringServiceProvider}
        error={formErrors.connectionMeteringServiceProvider}
      />
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.connectionLocationType}
        error={formErrors.connectionLocationType}
      />
      {connectionLocationType === "Marktlokation" &&
        connectionMeteringDirection &&
        connectionMeteringDirection !==
          MeteringDirection.Verbrauch_Entnahme && (
          <FormFieldController
            control={formControl}
            data={SITE_SETUP_ASSISTANT_FORM.connectionMarketLocationFeedin}
            error={formErrors.connectionMarketLocationFeedin}
            rules={{
              validate: (value?: string | null) => {
                if (!value) {
                  return true;
                } else if (value.length !== 11) {
                  return t("errors.MarketLocation.InvalidLength", {
                    amount: value.length
                  });
                } else if (!/^\d{11}$/.test(value)) {
                  return t("errors.MarketLocation.InvalidCharacter");
                } else if (!validateMarketLocationNumber(value)) {
                  return t("errors.MarketLocation.InvalidCheckSum");
                } else {
                  return true;
                }
              }
            }}
          />
        )}
      {connectionLocationType === "Marktlokation" &&
        connectionMeteringDirection &&
        connectionMeteringDirection !==
          MeteringDirection.Erzeugung_Einspeisung && (
          <FormFieldController
            control={formControl}
            data={SITE_SETUP_ASSISTANT_FORM.connectionMarketLocationFeedout}
            error={formErrors.connectionMarketLocationFeedout}
            rules={{
              validate: (value?: string | null) => {
                if (!value) {
                  return true;
                } else if (value.length !== 11) {
                  return t("errors.MarketLocation.InvalidLength", {
                    amount: value.length
                  });
                } else if (!/^\d{11}$/.test(value)) {
                  return t("errors.MarketLocation.InvalidCharacter");
                } else if (!validateMarketLocationNumber(value)) {
                  return t("errors.MarketLocation.InvalidCheckSum");
                } else {
                  return true;
                }
              }
            }}
          />
        )}
      {connectionLocationType === "Messlokation" && (
        <FormFieldController
          control={formControl}
          data={SITE_SETUP_ASSISTANT_FORM.connectionMeteringLocation}
          error={formErrors.connectionMeteringLocation}
          rules={{
            validate: (value?: string | null) => {
              if (!value) {
                return true;
              } else if (value.length !== 33) {
                return t("errors.MeteringLocation.InvalidLength", {
                  amount: value.length
                });
              } else if (!validateMeteringLocationNumber(value)) {
                return t("errors.MeteringLocation.InvalidNumber");
              } else {
                return true;
              }
            }
          }}
        />
      )}
      <IconAlert color={AlertColor.Info} mt="xl">
        Eine Mess- oder Marktlokation ist notwendig, um die Lastgänge beim
        zuständigen Messstellenbetreiber anfragen zu können. Stromzähler, welche
        für offizielle Abrechnungsprozesse genutzt werden, werden zumeist von
        einem Messstellenbetreiber (MSB) betrieben. Diese Stromzähler erhalten
        dann eine Mess- und/oder Marktlokation, damit sie in der
        Marktkommunikation (Versand von Messwerten bspw. an den
        Stromlieferanten) eindeutig identifizierbar sind.
      </IconAlert>
    </div>
  );
}

export { ConnectionFormItems };
