import React from "react";
import type { ExtendedUser } from "../../../../../../utils/backend-types";
import { IconWithLink } from "../../../../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";
import { SYSTEM_USER_NAME } from "../../../../common";

interface DeleteDocumentCellProps {
  canAlwaysDelete: boolean;
  createdBy: ExtendedUser | null;
  onClickDelete: () => void;
}

function DeleteDocumentCell({
  canAlwaysDelete,
  createdBy,
  onClickDelete
}: DeleteDocumentCellProps) {
  if (!canAlwaysDelete && createdBy?.name === SYSTEM_USER_NAME) {
    return null;
  }

  return (
    <div className="icons">
      <IconWithLink
        name={IconName.Trash}
        tooltipText="Dokument löschen"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onClickDelete();
        }}
      />
    </div>
  );
}

export { DeleteDocumentCell };
