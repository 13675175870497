import React from "react";
import { Link } from "react-router-dom";
import type { Paragraph6CreditErrorMessageDetailProps } from "../Paragraph6CreditErrorMessage";

function GeneratorsWithoutDirektvermarktungErrors({
  paragraph6CreditErrors
}: Paragraph6CreditErrorMessageDetailProps) {
  const generatorsWithoutDirektvermarktungErrors =
    paragraph6CreditErrors.filter(
      (error) => error.generators_without_direktvermarktung.length > 0
    );

  // render null if there are no dv errors
  if (generatorsWithoutDirektvermarktungErrors.length === 0) {
    return null;
  }

  return (
    <>
      {generatorsWithoutDirektvermarktungErrors.map((error) => (
        <li key={error.billing_period.start_date}>
          <Link to="../../direktvermarktung">
            Direktvermarktungsinformationen
          </Link>{" "}
          vom {error.billing_period.start_date} bis{" "}
          {error.billing_period.end_date} für folgende Anlagen:{" "}
          {error.generators_without_direktvermarktung
            .map((g) => g.name)
            .join(", ")}{" "}
        </li>
      ))}
    </>
  );
}

export { GeneratorsWithoutDirektvermarktungErrors };
