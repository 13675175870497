import React from "react";
import type { ExtendedUser } from "../../../../../../utils/backend-types";
import { IconWithLink } from "../../../../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";
import { SYSTEM_USER_NAME } from "../../../../common";

interface RenameDocumentCellProps {
  canAlwaysRename: boolean;
  createdBy: ExtendedUser | null;
  onClickRename: () => void;
}

function RenameDocumentCell({
  canAlwaysRename,
  createdBy,
  onClickRename
}: RenameDocumentCellProps) {
  if (!canAlwaysRename && createdBy?.name === SYSTEM_USER_NAME) {
    return null;
  }

  return (
    <div className="icons">
      <IconWithLink
        name={IconName.Pencil}
        tooltipText="Dokument umbenennen"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onClickRename();
        }}
      />
    </div>
  );
}

export { RenameDocumentCell };
