import { Group, Select, Text } from "@mantine/core";
import { DateTime } from "luxon";
import React from "react";
import { useEnergyDataAcquisitions } from "../../../../hooks/useEnergyDataAcquisitions";
import type { EnergyDisplayDataPayload } from "../../../../hooks/useEnergyDisplayData";
import { useEnergyDisplayData } from "../../../../hooks/useEnergyDisplayData";
import urls from "../../../../urls";
import { FREQUENCY_CHOICES, UNITS } from "../../../../utils/constants";
import type { Unit } from "../../../../utils/enums";
import { Frequency } from "../../../../utils/enums";
import { showToast } from "../../../../utils/toast";
import {
  DatePresetType,
  UncontrolledDateRangePicker
} from "../../../BuildingBlocks/Dates/DateRangePicker/DateRangePicker";
import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import type { Identity } from "../../../EdaVisualizationModal/EdaTable/Mbk";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { MonthYearRangePicker } from "../../../MonthYearRangePicker/MonthYearRangePicker";
import { DownloadButton } from "../DownloadButton/DownloadButton";
import { EnergyDataAcquisitionList } from "../EnergyDataAcquisitionList/EnergyDataAcquisitionList";
import { buildFilterString } from "../EnergyDataAcquisitionSelectionModal/Filters/filter-utils";
import type { Acquisition, AcquisitionFilter } from "../EnergyDataView";
import { useEnergyDataPageState } from "../hooks/useEnergyDataPageState";
import { Acquisitions } from "./Acquisitions/Acquisitions";
import { AcquisitionTabs } from "./AcquisitionTabs/AcquisitionTabs";
import { EnergyDataViewWarning } from "./EnergyDataViewWarning/EnergyDataViewWarning";
import "./EnergyDataPage.scss";

const MAX_DATE_SPAN = 5 * 365;
const MIN_DATE_RANGE = DateTime.fromISO("2016-01-01");
const MAX_DATE_RANGE = DateTime.now();

export interface EnergyDataAcquisitionInfo {
  id: number;
  identity: Omit<Identity, "displayName">;
}

export interface AcquisitionSummaryDataPoint {
  energy: number;
  energyDataAcquisition: EnergyDataAcquisitionInfo;
  maxPower: number;
}

interface EnergyDataPageProps {
  variantId: number;
}

function EnergyDataPage({ variantId }: EnergyDataPageProps) {
  const { pageState, setPageState } = useEnergyDataPageState();

  const {
    data: acquisitions,
    error: acquisitionsError,
    isLoading: isLoadingAcquisitions
  } = useEnergyDataAcquisitions(
    variantId,
    buildFilterString(pageState.acquisitionFilters)
  );

  const energyDataRequestPayload = {
    energyDataAcquisitions: pageState.selectedAcquisitions,
    firstDate: pageState.firstDate.toISO(),
    lastDate: pageState.lastDate.toISO(),
    frequency: pageState.frequency,
    unit: pageState.unit
  } satisfies EnergyDisplayDataPayload;

  const {
    data: energyDataDisplayDataResponse,
    error: energyDataError,
    isLoading: isLoadingEnergyData
  } = useEnergyDisplayData({
    payload: energyDataRequestPayload,
    enabled: true
  });

  const acquisitionsWithLowerFrequency =
    energyDataDisplayDataResponse?.acquisitionsWithLowerFrequency || [];

  function handleDatePickerChange({
    startDate,
    endDate
  }: {
    startDate: DateTime;
    endDate: DateTime;
  }) {
    // only react if a valid & complete range is set
    if (startDate?.isValid && endDate?.isValid) {
      setPageState((state) => {
        return {
          ...state,
          firstDate: startDate,
          lastDate: endDate
        };
      });
    }
  }

  function handleFrequencyChanged(value: Frequency) {
    setPageState((state) => {
      return {
        ...state,
        frequency: value
      };
    });
  }

  function handleSelectedAcquisitionsChanged(
    selectedAcquisitions: Array<number>,
    acquisitionFilters: Array<AcquisitionFilter>,
    definedFilterId: number | null
  ) {
    setPageState((state) => {
      return {
        ...state,
        selectedAcquisitions: selectedAcquisitions,
        acquisitionFilters: acquisitionFilters,
        filter: definedFilterId
      };
    });
  }

  function handleUnitChanged(value: Unit) {
    setPageState((state) => {
      return {
        ...state,
        unit: value
      };
    });
  }

  return (
    <Portlet className="EnergyDataPage">
      <EnergyDataControls
        acquisitionFilters={pageState.acquisitionFilters}
        acquisitions={acquisitions || []}
        definedFilterId={pageState.filter}
        firstDate={pageState.firstDate}
        lastDate={pageState.lastDate}
        selectedAcquisitions={pageState.selectedAcquisitions}
        selectedFrequency={pageState.frequency}
        selectedUnitValue={pageState.unit}
        variantId={variantId}
        onDatePickerChange={handleDatePickerChange}
        onFrequencyChanged={handleFrequencyChanged}
        onSelectedAcquisitionsChanged={handleSelectedAcquisitionsChanged}
        onUnitChanged={handleUnitChanged}
      />
      {acquisitionsWithLowerFrequency.length > 0 && (
        <EnergyDataViewWarning
          acquisitionsWithLowerFrequency={acquisitionsWithLowerFrequency}
          selectedFrequency={pageState.frequency}
        />
      )}
      {pageState.selectedAcquisitions.length > 0 && (
        <LoadOrError
          error={acquisitionsError || energyDataError}
          loading={isLoadingAcquisitions || isLoadingEnergyData}
        >
          <AcquisitionTabs
            acquisitionSerieses={energyDataDisplayDataResponse?.serieses}
            selectedUnitValue={pageState.unit}
          />
          <EnergyDataAcquisitionList
            acquisitions={acquisitions || []}
            acquisitionSummaryData={
              energyDataDisplayDataResponse?.summary ?? []
            }
          />
        </LoadOrError>
      )}
    </Portlet>
  );
}

interface EnergyDataControlsProps {
  firstDate: DateTime<true>;
  lastDate: DateTime<true>;
  selectedFrequency: Frequency;
  selectedUnitValue: string;
  acquisitions: Array<Acquisition>;
  selectedAcquisitions: Array<number>;
  acquisitionFilters: Array<AcquisitionFilter>;
  definedFilterId: number | null;
  variantId: number;
  onSelectedAcquisitionsChanged: (
    selectedAcquisitions: Array<number>,
    acquisitionFilters: Array<AcquisitionFilter>,
    definedFilterId: number | null
  ) => void;
  onDatePickerChange: (dates: {
    startDate: DateTime;
    endDate: DateTime | null;
  }) => void;
  onFrequencyChanged: (value: Frequency) => void;
  onUnitChanged: (value: Unit) => void;
}

function EnergyDataControls({
  firstDate,
  lastDate,
  selectedFrequency,
  selectedUnitValue,
  acquisitions,
  selectedAcquisitions,
  acquisitionFilters,
  definedFilterId,
  variantId,
  onSelectedAcquisitionsChanged,
  onDatePickerChange,
  onFrequencyChanged,
  onUnitChanged
}: EnergyDataControlsProps) {
  function handleFrequencyChange(newFrequency: Frequency) {
    if (selectedFrequency !== newFrequency) {
      if (newFrequency === FREQUENCY_CHOICES.MONTH.value) {
        showToast(
          "info",
          "Es können nur komplette Monate ausgewählt werden. Der ausgewählte Zeitraum wurde entsprechend angepasst."
        );
        onDatePickerChange({
          startDate: firstDate.startOf("month"),
          endDate: lastDate.endOf("month")
        });
      } else if (newFrequency === FREQUENCY_CHOICES.YEAR.value) {
        onDatePickerChange({
          startDate: firstDate.startOf("year"),
          endDate: lastDate.endOf("year")
        });
        showToast(
          "info",
          "Es können nur komplette Jahre ausgewählt werden. Der ausgewählte Zeitraum wurde entsprechend angepasst."
        );
      }
    }

    onFrequencyChanged(newFrequency);
  }

  const isFrequencyMonthOrYear =
    selectedFrequency === Frequency.Month ||
    selectedFrequency === Frequency.Year;

  const frequencySelectValues = Object.values(FREQUENCY_CHOICES).map((v) => ({
    value: v.value,
    label: v.displayName
  }));
  const unitSelectValues = Object.values(UNITS).map((v) => ({
    value: v.value,
    label: v.displayName
  }));

  return (
    <div className="EnergyDataControls">
      <Acquisitions
        acquisitionFilters={acquisitionFilters}
        acquisitions={acquisitions}
        definedFilterId={definedFilterId}
        selectedAcquisitions={selectedAcquisitions}
        variantId={variantId}
        onSelectedAcquisitionsChanged={onSelectedAcquisitionsChanged}
      />
      {selectedAcquisitions.length > 0 && (
        <Group gap="xl" my="md">
          <Group gap="sm">
            <Text fw="bold">Dargestellte Tage</Text>
            {isFrequencyMonthOrYear && (
              <MonthYearRangePicker
                end={lastDate}
                mode={selectedFrequency}
                start={firstDate}
                onChange={onDatePickerChange}
              />
            )}
            {!isFrequencyMonthOrYear && (
              <UncontrolledDateRangePicker
                allowSingleDateInRange
                initialEndDate={lastDate}
                initialStartDate={firstDate}
                maxDate={MAX_DATE_RANGE}
                maxSpan={MAX_DATE_SPAN}
                minDate={MIN_DATE_RANGE}
                presets={DatePresetType.MonthYear}
                onChange={onDatePickerChange}
              />
            )}
          </Group>
          <Select
            allowDeselect={false}
            className="control-dropdown time-intervals"
            data={frequencySelectValues}
            label="Zeitintervall"
            value={selectedFrequency}
            onChange={handleFrequencyChange}
          />
          <Select
            allowDeselect={false}
            className="control-dropdown units"
            data={unitSelectValues}
            label="Einheit"
            value={selectedUnitValue}
            onChange={onUnitChanged}
          />
          <DownloadButton
            downloadRequestOptions={{
              firstDate: firstDate.toISO(),
              lastDate: lastDate.toISO(),
              frequency: selectedFrequency,
              unit: selectedUnitValue,
              energyDataAcquisitions: selectedAcquisitions
            }}
            downloadRequestUrl={urls.api.energyDataRequestDownload()}
            downloadUrlFunc={urls.api.energyDataDownload}
          />
        </Group>
      )}
    </div>
  );
}

export { EnergyDataPage };
