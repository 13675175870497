import { Group, Paper, Text } from "@mantine/core";
import React from "react";
import { numericFormatter } from "react-number-format";
import { getDefaultNumericFormatterProps } from "../../../utils/getDefaultNumericFormatterProps";

interface MantineLineChartPayloadItem {
  name: string;
  color: string;
  value: number;
}

interface MantineLineChartTooltipProps {
  seriesLabels: Record<string, string>;
  title: string;
  payload: Array<MantineLineChartPayloadItem>;
}

/*  Mantine doesn't allow changes to the recharts tooltip: https://mantine.dev/charts/line-chart/#custom-tooltip */
function MantineLineChartTooltip({
  seriesLabels,
  title,
  payload
}: MantineLineChartTooltipProps) {
  if (!payload) return null;

  return (
    <Paper
      className="MantineLineChart ChartTooltip"
      px="md"
      py="sm"
      radius="md"
      shadow="md"
      withBorder
    >
      <Text fw={500} mb={5}>
        {title}
      </Text>
      {payload.map((item) => (
        <Group justify="space-between" key={item.name}>
          <Group gap="sm">
            <div className="dot" style={{ backgroundColor: item.color }} />
            <Text fz="sm">{seriesLabels[item.name] || "Unbekannt"}</Text>
          </Group>
          <Text fz="sm">
            {numericFormatter(
              item.value.toString(),
              getDefaultNumericFormatterProps()
            )}
          </Text>
        </Group>
      ))}
    </Paper>
  );
}

export { MantineLineChartTooltip, MantineLineChartPayloadItem };
