import { validateMarketLocationNumber } from "../../../../../utils/validateMarketLocationNumber";
import { validateMeteringLocationNumber } from "../../../../../utils/validateMeteringLocationNumber";
import { type Meter } from "../../../SiteSetupAssistant.types";

export function validateMeter(
  values: Partial<Meter>,
  t: (key: string, options?) => string
) {
  const errors: Partial<{ [k in keyof Meter]: string | null }> = {
    metering_location: null
  };
  if (values.metering_location) {
    if (values.metering_location.length !== 33) {
      errors.metering_location = t("errors.MeteringLocation.InvalidLength", {
        amount: values.metering_location.length
      });
    } else if (!validateMeteringLocationNumber(values.metering_location)) {
      errors.metering_location = t("errors.MeteringLocation.InvalidNumber");
    }
  }

  if (values.market_location_feedin) {
    if (values.market_location_feedin.length !== 11) {
      errors.market_location_feedin = t("errors.MarketLocation.InvalidLength", {
        amount: values.market_location_feedin.length
      });
    } else if (!/^\d{11}$/.test(values.market_location_feedin)) {
      errors.market_location_feedin = t(
        "errors.MarketLocation.InvalidCharacter"
      );
    } else if (!validateMarketLocationNumber(values.market_location_feedin)) {
      errors.market_location_feedin = t(
        "errors.MarketLocation.InvalidCheckSum"
      );
    }
  }

  if (values.market_location_feedout) {
    if (values.market_location_feedout.length !== 11) {
      errors.market_location_feedout = t(
        "errors.MarketLocation.InvalidLength",
        {
          amount: values.market_location_feedout.length
        }
      );
    } else if (!/^\d{11}$/.test(values.market_location_feedout)) {
      errors.market_location_feedout = t(
        "errors.MarketLocation.InvalidCharacter"
      );
    } else if (!validateMarketLocationNumber(values.market_location_feedout)) {
      errors.market_location_feedout = t(
        "errors.MarketLocation.InvalidCheckSum"
      );
    }
  }

  return errors;
}
