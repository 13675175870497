import type { ScatterChartSeries } from "@mantine/charts";
import { type DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import type { Unit } from "../../../../../../../utils/enums";
import { AlertColor } from "../../../../../../Alert/Alert";
import { IconAlert } from "../../../../../../BuildingBlocks/IconAlert/IconAlert";
import { LoadOrError } from "../../../../../../LoadOrError/LoadOrError";
import { useDataGaps } from "../hooks/useDataGaps";
import "./DataGaps.scss";
import type { DataGapsTableObject } from "./DataGaps.types";
import { DataGapsChart } from "./DataGapsChart/DataGapsChart";
import { DataGapsTable } from "./DataGapsTable/DataGapsTable";
import { getChartDataFromDataGaps } from "./utils/getChartDataFromDataGaps";
import { getCorrectNameFromExpectedOrigin } from "./utils/getCorrectNameFromExpectedOrigin";
import { getTableDataFromDataGaps } from "./utils/getTableDataFromDataGaps";

interface DataGapsProps {
  meterName?: string;
  selectedDateRange: [DateTime<true>, DateTime<true>];
  meter: number;
  unit: Unit;
}

function DataGaps({
  meter,
  unit,
  meterName,
  selectedDateRange
}: DataGapsProps) {
  const [dataGapsChartData, setDataGapsChartData] =
    useState<Array<ScatterChartSeries> | null>(null);
  const [dataGapsTableData, setDataGapsTableData] = useState<
    Array<DataGapsTableObject>
  >([]);

  const { dataGaps, isLoading, error } = useDataGaps(
    unit,
    meter,
    selectedDateRange[0].toJSDate(),
    selectedDateRange[1].toJSDate()
  );

  useEffect(() => {
    if (dataGaps) {
      setDataGapsChartData(getChartDataFromDataGaps(dataGaps));
      setDataGapsTableData(getTableDataFromDataGaps(dataGaps));
    }
  }, [dataGaps]);

  const expectedOrigin = dataGaps?.expectedOrigin;

  return (
    <div className="DataGaps">
      <h4>Datenlücken</h4>
      <LoadOrError error={error} loading={isLoading}>
        <div className="data-gaps-chart-container">
          <DataGapsChart
            data={dataGapsChartData || []}
            selectedDateRange={selectedDateRange}
          />
        </div>
        {expectedOrigin && (
          <IconAlert
            className="data-gaps-origin-info"
            color={AlertColor.Info}
            mb={"lg"}
          >
            Quelle der Daten für den Zähler{" "}
            <span className="data-gaps-origin-info-meter-name">
              {meterName}
            </span>{" "}
            ist {getCorrectNameFromExpectedOrigin(expectedOrigin)}
          </IconAlert>
        )}
        <h4>Übersicht Datenlücken</h4>
        <div className="data-gaps-overview-container">
          <DataGapsTable data={dataGapsTableData} />
        </div>
      </LoadOrError>
    </div>
  );
}

export { DataGaps };
