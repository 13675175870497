import type { ComboboxItem } from "@mantine/core";
import { Group, MultiSelect, Stack, Title } from "@mantine/core";
import type { DatesRangeValue } from "@mantine/dates";
import { MonthPickerInput } from "@mantine/dates";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { usePersonGenerators } from "../../../../hooks/usePersonGenerators";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import type { ChartTableSwitchState } from "../../ChartTableSwitch/ChartTableSwitch";
import { ChartTableSwitch } from "../../ChartTableSwitch/ChartTableSwitch";
import { useGeneratorEnergyRevenuesData } from "../../hooks/useGeneratorEnergyRevenuesData";
import { EnergyRevenuesView } from "../EnergyRevenuesView";
import { getMinOrMaxDateFromEnergyRevenues } from "../utils/getMinOrMaxDateFromEnergyRevenues";

interface GeneratorSectionProps {
  companyId: number;
}

function GeneratorSection({ companyId }: GeneratorSectionProps) {
  const [view, setView] = useState<ChartTableSwitchState>("chart");
  const {
    data: generators,
    error: generatorsError,
    isLoading: generatorsAreLoading
  } = usePersonGenerators(companyId);

  const generatorOptions = useMemo(
    () =>
      generators?.map<ComboboxItem>((generator) => ({
        label: `${generator.name} (${generator.siteName})`,
        value: generator.id.toString()
      })),
    [generators]
  );

  useEffect(() => {
    if (generatorOptions && generatorOptions.length > 0) {
      setSelectedGenerators([generatorOptions[0].value]);
    } else {
      setSelectedGenerators(undefined);
    }
  }, [generatorOptions]);

  const [selectedGenerators, setSelectedGenerators] = useState<
    Array<string> | undefined
  >();
  const [selectedDateRange, setSelectedDateRange] = useState<
    [DateTime<true>, DateTime<true>]
  >([DateTime.now().startOf("year"), DateTime.now().endOf("month")]);

  const {
    energyRevenuesGeneratorData,
    isLoading: isEnergyRevenuesGeneratorDataLoading,
    error: energyRevenuesGeneratorDataError
  } = useGeneratorEnergyRevenuesData(
    selectedGenerators?.map((generatorId) => Number(generatorId)) ?? []
  );

  const formattedGeneratorData = useMemo(() => {
    return [...(energyRevenuesGeneratorData ?? [])]
      .filter((energyRevenues) => {
        const revenuesDate = backendDateOrDateTimeToLuxonDateTime(
          energyRevenues.period_start
        );

        return (
          selectedDateRange[0] <= revenuesDate &&
          revenuesDate <= selectedDateRange[1]
        );
      })
      .sort((revenuesA, revenuesB) =>
        sortBackendDates(revenuesA.period_start, revenuesB.period_start)
      );
  }, [energyRevenuesGeneratorData, selectedDateRange]);

  function handleDatePickerChange(dateRange: DatesRangeValue) {
    // only react if a valid & complete range is set
    if (dateRange[0] && dateRange[1]) {
      const firstDate = DateTime.fromJSDate(dateRange[0]);
      const lastDate = DateTime.fromJSDate(dateRange[1]).endOf("month");
      if (firstDate.isValid && lastDate.isValid) {
        setSelectedDateRange([firstDate, lastDate]);
      }
    }
  }

  return (
    <Stack gap="sm">
      <Title order={5}>Anlagenübersicht</Title>

      <Group justify="space-between">
        <Group gap="sm">
          <MultiSelect
            className="generator-select"
            clearable
            data={generatorOptions}
            disabled={generatorsAreLoading || generators?.length === 0}
            label="Anlagen"
            placeholder="Anlagen auswählen"
            searchable
            size="xs"
            value={selectedGenerators}
            w={624}
            onChange={setSelectedGenerators}
          />
          <MonthPickerInput
            allowSingleDateInRange
            defaultValue={[
              selectedDateRange[0].toJSDate(),
              selectedDateRange[1].toJSDate()
            ]}
            disabled={
              generatorsAreLoading || energyRevenuesGeneratorData?.length === 0
            }
            label="Berechneter Zeitraum"
            maxDate={getMinOrMaxDateFromEnergyRevenues(
              energyRevenuesGeneratorData ?? [],
              "max"
            )?.toJSDate()}
            minDate={getMinOrMaxDateFromEnergyRevenues(
              energyRevenuesGeneratorData ?? [],
              "min"
            )?.toJSDate()}
            size="xs"
            type="range"
            onChange={handleDatePickerChange}
          />
        </Group>
        <ChartTableSwitch setValue={setView} value={view} />
      </Group>

      <LoadOrError
        error={generatorsError || energyRevenuesGeneratorDataError}
        loading={generatorsAreLoading || isEnergyRevenuesGeneratorDataLoading}
      >
        <EnergyRevenuesView
          energyRevenuesData={formattedGeneratorData}
          view={view}
        />
      </LoadOrError>
    </Stack>
  );
}

export { GeneratorSection };
