import { Group, Select, Title } from "@mantine/core";
import { type DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useFeatureFlags } from "../../../../../../hooks/useFeatureFlags";
import urls from "../../../../../../urls";
import type {
  Meter,
  MeteringOrMarketLocation,
  MeterPlausibility
} from "../../../../../../utils/backend-types";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Medium, Unit } from "../../../../../../utils/enums";
import { LoadOrError } from "../../../../../LoadOrError/LoadOrError";
import { AcquisitionPlot } from "../../../AcquisitionPlot/AcquisitionPlot";
import { DownloadButton } from "../../../DownloadButton/DownloadButton";
import { useRawEnergyDataFromLocation } from "../../../hooks/useRawEnergyDataFromLocation";
import { useRawEnergyDataFromMeter } from "../../../hooks/useRawEnergyDataFromMeter";
import {
  getLocationIdFromSelectedId,
  LOCATION_ID_PREFIX
} from "../../../utils/getLocationIdFromSelectedId";
import {
  getMeterIdFromSelectedId,
  METER_ID_PREFIX
} from "../../../utils/getMeterIdFromSelectedId";
import { DataGaps } from "./DataGaps/DataGaps";
import { MeterInfo } from "./MeterInfo/MeterInfo";
import "./RawEnergyDataSiteMeters.scss";

const DEFAULT_UNIT = Unit.KilowattHour;

function isGasMeter(meter?: Meter) {
  return meter?.medium === Medium.Gas;
}

interface RawEnergyDataSiteMetersProps {
  locations?: Array<MeteringOrMarketLocation>;
  meters?: Array<Meter>;
  meterPlausibilities?: Array<MeterPlausibility>;
  selectedId?: string;
  selectedDateRange: [DateTime<true>, DateTime<true>];
  onSetSelectedId: (id: string) => void;
}

function RawEnergyDataSiteMeters({
  locations = [],
  meters = [],
  meterPlausibilities,
  selectedId,
  selectedDateRange,
  onSetSelectedId
}: RawEnergyDataSiteMetersProps) {
  const [selectedUnit, setSelectedUnit] = useState<Unit>(DEFAULT_UNIT);

  const { featureFlags } = useFeatureFlags();

  const selectedMeterId = getMeterIdFromSelectedId(selectedId);
  const selectedLocationId = getLocationIdFromSelectedId(selectedId);

  // make sure that the passed ID references an actual meter/location in this particular site
  const selectedMeter = meters.find((m) => m.id === selectedMeterId);
  const selectedLocation = locations.find((l) => l.id === selectedLocationId);

  useEffect(() => {
    if (selectedUnit === Unit.CubicMeter && !isGasMeter(selectedMeter)) {
      setSelectedUnit(DEFAULT_UNIT);
    }
  }, [selectedMeter, selectedUnit]);

  const {
    data: acquisitionMeterSerieses,
    isLoading: acquisitionMeterSeriesesLoading,
    error: acquisitionMeterSeriesesError
  } = useRawEnergyDataFromMeter({
    selectedMeterId: selectedMeter?.id,
    selectedDateRange,
    selectedUnit
  });

  const {
    data: acquisitionLocationSerieses,
    isLoading: acquisitionLocationSeriesesLoading,
    error: acquisitionLocationSeriesesError
  } = useRawEnergyDataFromLocation({
    selectedLocationId: selectedLocation?.id,
    selectedDateRange,
    selectedUnit
  });

  const acquisitionSerieses =
    acquisitionMeterSerieses || acquisitionLocationSerieses;
  const acquisitionSeriesesLoading =
    acquisitionMeterSeriesesLoading || acquisitionLocationSeriesesLoading;
  const acquisitionSeriesesError =
    acquisitionMeterSeriesesError || acquisitionLocationSeriesesError;

  const meterChoices = meters.map((meter) => ({
    value: `${METER_ID_PREFIX}${meter.id}`,
    label: meter.number ? `${meter.number} (${meter.name})` : meter.name
  }));

  const locationChoices = locations.map((location) => ({
    value: `${LOCATION_ID_PREFIX}${location.id}`,
    label:
      location.number !== location.name
        ? `${location.number} (${location.name})`
        : location.name
  }));

  const meterPlausibility = meterPlausibilities?.find(
    (mp) => mp.meter_id === selectedMeter?.id
  );

  const unitChoices = [
    {
      value: Unit.KilowattHour,
      label: Unit.KilowattHour
    },
    {
      value: Unit.CubicMeter,
      label: Unit.CubicMeter
    }
  ];

  return (
    <div className="RawEnergyDataSiteMeters">
      <Title fw={500} mb="sm" order={4}>
        Zähler- und Lokationsdaten
      </Title>
      <Group mb="lg" w={700}>
        <Select
          className="meter-dropdown"
          data={[
            {
              group: "Lokationen",
              items: locationChoices
            },
            {
              group: "Zähler",
              items: meterChoices
            }
          ]}
          disabled={locationChoices.length + meterChoices.length === 0}
          placeholder="Bitte auswählen ..."
          value={selectedId?.toString() || null}
          onChange={(value) => onSetSelectedId(value || "")}
        />
        {isGasMeter(selectedMeter) && (
          <Select
            allowDeselect={false}
            className="unit-dropdown"
            data={unitChoices}
            label="Einheit"
            value={selectedUnit}
            onChange={(value) => setSelectedUnit(value as Unit)}
          />
        )}
        {selectedMeter && (
          <DownloadButton
            downloadRequestOptions={{
              meter: selectedMeter.id,
              firstDate: luxonDateTimeToBackendDateOrDateTime(
                selectedDateRange[0]
              ),
              lastDate: luxonDateTimeToBackendDateOrDateTime(
                selectedDateRange[1]
              )
            }}
            downloadRequestUrl={urls.api.energyDataRawDataRequestDownload()}
            downloadUrlFunc={urls.api.energyDataRawDataDownload}
          />
        )}
      </Group>
      <MeterInfo plausibility={meterPlausibility} />
      {(selectedMeter || selectedLocation) && (
        <LoadOrError
          error={acquisitionSeriesesError}
          loading={acquisitionSeriesesLoading}
        >
          {acquisitionSerieses && (
            <AcquisitionPlot
              acquisitionSerieses={acquisitionSerieses}
              selectedUnitValue={selectedUnit}
            />
          )}
          {selectedMeter && featureFlags.showDataGaps && (
            <DataGaps
              meter={selectedMeter.id}
              meterName={selectedMeter.name}
              selectedDateRange={selectedDateRange}
              unit={selectedUnit}
            />
          )}
        </LoadOrError>
      )}
    </div>
  );
}

export { RawEnergyDataSiteMeters };
