export function validateMarketLocationNumber(
  marketLocationNumber: string | number | null
): boolean {
  if (marketLocationNumber === null) {
    return false;
  }

  if (typeof marketLocationNumber === "number") {
    return validateString(marketLocationNumber.toString());
  }

  return validateString(marketLocationNumber);
}

function validateString(value: string) {
  const isElevenDigits = /^\d{11}$/.test(value);
  const isCheckDigitValid = validateCheckDigit(value.split("").map(Number));
  return isElevenDigits && isCheckDigitValid;
}

function validateCheckDigit(digitsArray: Array<number>) {
  const sumOfOddIndexedDigits = digitsArray
    .slice(0, digitsArray.length - 1)
    .filter((_, index) => index % 2 === 0)
    .reduce((acc, digit) => acc + digit, 0);
  const sumOfEvenIndexedDigits = digitsArray
    .slice(0, digitsArray.length - 1)
    .filter((_, index) => index % 2 !== 0)
    .reduce((acc, digit) => acc + digit, 0);
  const doubledSumOfEvenIndexedDigits = 2 * sumOfEvenIndexedDigits;
  const totalSum = sumOfOddIndexedDigits + doubledSumOfEvenIndexedDigits;
  const nextMultipleOfTen = Math.ceil(totalSum / 10) * 10;
  const checkDigit = nextMultipleOfTen - totalSum;

  return digitsArray[digitsArray.length - 1] === checkDigit;
}
