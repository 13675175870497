import { Group } from "@mantine/core";
import * as React from "react";
import { SiteSetupProcessDataStep } from "../../../../types/api.types";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Button } from "../../../Buttons/Button/Button";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import { getOrderFromStep } from "../../utils/getOrderFromStep";
import { getStepFromOrder } from "../../utils/getStepFromOrder";

interface SiteSetupAssistantFormControlsProps {
  activeStep: SiteSetupProcessDataStep;
  isCreating: boolean;
  isSubmitting: boolean;
  navigationBlocked?: boolean;
  onStep: (stepTo: SiteSetupProcessDataStep) => void;
  onCancel: () => void;
  onSaveProgress: () => Promise<void>;
  onCreateSite: () => Promise<void>;
}

function SiteSetupAssistantFormControls({
  activeStep,
  isCreating,
  isSubmitting,
  navigationBlocked,
  onStep,
  onSaveProgress,
  onCancel,
  onCreateSite
}: SiteSetupAssistantFormControlsProps) {
  function stepBack(): SiteSetupProcessDataStep | null {
    const currentStepNumber = getOrderFromStep(activeStep);
    if (currentStepNumber === null || currentStepNumber - 1 === -1) {
      return null;
    } else {
      return getStepFromOrder(currentStepNumber - 1);
    }
  }

  function stepForward(): SiteSetupProcessDataStep | null {
    const currentStepNumber = getOrderFromStep(activeStep);
    if (currentStepNumber === null || currentStepNumber + 1 === 6) {
      return null;
    } else {
      return getStepFromOrder(currentStepNumber + 1);
    }
  }

  const stepBackTo = stepBack();
  const stepForwardTo = stepForward();

  function handleStepBack() {
    if (stepBackTo) {
      onStep(stepBackTo);
    }
  }

  function handleStepForward() {
    if (stepForwardTo) {
      onStep(stepForwardTo);
    }
  }

  return (
    <Group className="SiteSetupAssistantFormControls" justify="space-between">
      <Button
        color="secondary"
        disabled={isSubmitting || isCreating}
        onClick={onCancel}
      >
        Abbrechen
      </Button>

      <Group>
        <IconButton
          color="secondary"
          disabled={
            isSubmitting ||
            isCreating ||
            stepBackTo === null ||
            navigationBlocked
          }
          iconColor="brand"
          iconName={IconName.ArrowCircleLeft}
          onClick={handleStepBack}
        >
          Zurück
        </IconButton>
        <IconButton
          color="secondary"
          disabled={
            isSubmitting ||
            isCreating ||
            stepForwardTo === null ||
            navigationBlocked
          }
          iconColor="brand"
          iconName={IconName.ArrowCircleRight}
          onClick={handleStepForward}
        >
          Weiter
        </IconButton>
      </Group>

      <Group>
        <SpinButton
          color={
            activeStep === SiteSetupProcessDataStep.meters
              ? "secondary"
              : "brand"
          }
          disabled={isCreating || navigationBlocked}
          spin={isSubmitting}
          onClick={onSaveProgress}
        >
          Speichern
        </SpinButton>
        {activeStep === SiteSetupProcessDataStep.meters && (
          <SpinButton
            color="brand"
            disabled={isSubmitting || navigationBlocked}
            spin={isCreating}
            onClick={onCreateSite}
          >
            Liegenschaft anlegen
          </SpinButton>
        )}
      </Group>
    </Group>
  );
}

export { SiteSetupAssistantFormControls, SiteSetupAssistantFormControlsProps };
