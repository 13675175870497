import { Badge, Flex } from "@mantine/core";
import React from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import type { Column } from "react-table";

import { useCanAssessManagerSiteFinancially } from "../../../hooks/useCanAssessManagerSiteFinanciallyQuery";
import urls from "../../../urls";
import type { Person } from "../../../utils/backend-types";
import { ObjectName, Product } from "../../../utils/enums";
import { IconWithRouterLink } from "../../BuildingBlocks/Icon/IconWithRouterLink/IconWithRouterLink";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { ComponentEditWizardTs } from "../../ComponentListContainer/ComponentList/ComponentEditWizard/ComponentEditWizardTs";
import { getCompanyFeatures } from "../../ComponentListContainer/ComponentList/ComponentEditWizard/Data/utils/getCompanyFeatures";
import { SwissArmyTable } from "../../SwissArmyTable/SwissArmyTable";
import { VariantObjectWizard } from "../../VariantObjectWizard/VariantObjectWizard";

import "./CompaniesPage.scss";

interface CompaniesPageProps {
  selectedCompanyId?: number;
  companies: Array<Person>;
  variantId: number;
  showNewModal?: boolean;
  onDeleteCompany: (companyId: number) => void;
}

function CompaniesPage({
  selectedCompanyId,
  companies,
  variantId,
  showNewModal = false,
  onDeleteCompany
}: CompaniesPageProps) {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const {
    isLoading: currentUserCanAssessManagerSiteFinanciallyIsLoading,
    error: currentUserCanAssessManagerSiteFinanciallyError,
    currentUserCanAssessManagerSiteFinancially
  } = useCanAssessManagerSiteFinancially();

  function toggleCreateModal() {
    if (showNewModal) {
      navigate("../", { replace: true });
    } else {
      navigate("neu");
    }
  }

  return (
    <div className="CompaniesPage ItemsPage">
      <Section>
        <CompaniesList companies={companies} onDelete={onDeleteCompany} />
        <VariantObjectWizard
          isOpen={showNewModal}
          mode="create"
          objectDisplayName={ObjectName.Person}
          objectName={ObjectName.Person}
          product={Product.Manager}
          siteId={siteId ? Number(siteId) : undefined}
          variantId={variantId}
          onToggle={toggleCreateModal}
        />
        {selectedCompanyId &&
          !currentUserCanAssessManagerSiteFinanciallyIsLoading && (
            <ComponentEditRoute
              currentUserCanAssessManagerSiteFinancially={
                currentUserCanAssessManagerSiteFinanciallyError
                  ? false
                  : currentUserCanAssessManagerSiteFinancially
              }
              product={Product.Manager}
              variantId={variantId}
            />
          )}
      </Section>
    </div>
  );
}

interface CompaniesListProps {
  companies: Array<Person>;
  onDelete: (companyId: number) => void;
}

function CompaniesList({ companies, onDelete }: CompaniesListProps) {
  function handleCompaniesDeleted(companyIds: Array<number>) {
    companyIds.forEach((id) => {
      onDelete(id);
    });
  }

  const defaultSorted = [];
  const tableColumns: Array<Column<Person>> = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cellData) => (
        <NameCell companyId={cellData.original.id} name={cellData.value} />
      ),
      sortMethod: (a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true
        });
      }
    },
    {
      Header: "Gebuchte Add-Ons",
      accessor: (row) => getCompanyFeatures(row).map((f) => f.label),
      Cell: (cellData) => (
        <Flex gap="xs">
          {cellData.value.map((feature) => (
            <Badge color="gray" key={feature}>
              {feature}
            </Badge>
          ))}
        </Flex>
      ),
      id: "features"
    },
    {
      Cell: (data) => {
        return (
          <div className="icons">
            <IconWithRouterLink
              name={IconName.Pencil}
              to={`${data.original.id}/`}
              tooltipText="Unternehmen bearbeiten"
            />
          </div>
        );
      },
      width: 30,
      sortable: false,
      resizable: false
    }
  ];

  return (
    <SwissArmyTable
      data={companies}
      defaultSorted={defaultSorted}
      options={{
        buttons: {
          create: {
            text: "Unternehmen erstellen",
            isPrimary: false,
            path: "neu/"
          }
        },
        deletion: {
          confirmWithText: true,
          deleteEndpoint: urls.api.person,
          onDataDeleted: handleCompaniesDeleted
        },
        singleWord: "Unternehmen",
        pluralWord: "Unternehmen"
      }}
      tableColumns={tableColumns}
    />
  );
}

interface ComponentEditRouteProps {
  variantId: number;
  product: Product;
  currentUserCanAssessManagerSiteFinancially?: boolean;
}

function ComponentEditRoute({
  variantId,
  product,
  currentUserCanAssessManagerSiteFinancially
}: ComponentEditRouteProps) {
  const navigate = useNavigate();

  function handleCloseComponentEditWizard() {
    navigate("../");
  }

  if (!product) {
    return null;
  }

  return (
    <ComponentEditWizardTs
      currentUserCanAssessManagerSiteFinancially={
        !!currentUserCanAssessManagerSiteFinancially
      }
      isOpen
      product={product}
      variantId={variantId}
      onClose={handleCloseComponentEditWizard}
    />
  );
}

interface CompanyProps {
  name: string;
  companyId: number;
}

function NameCell({ name, companyId }: CompanyProps) {
  const editPath = `./${companyId}`;

  return <Link to={editPath}>{name}</Link>;
}

function CompaniesPageWithRoutes(props: CompaniesPageProps) {
  return (
    <Routes>
      <Route
        element={<CompaniesPageWithSelectedCompany {...props} />}
        path=":companyId/*"
      />
      <Route element={<CompaniesPage {...props} showNewModal />} path="neu" />
      <Route element={<CompaniesPage {...props} />} index />
    </Routes>
  );
}

function CompaniesPageWithSelectedCompany(props: CompaniesPageProps) {
  const { companyId } = useParams();
  const parsedCompanyId = companyId ? parseInt(companyId, 10) : undefined;

  return <CompaniesPage selectedCompanyId={parsedCompanyId} {...props} />;
}

export { CompaniesPageWithRoutes as CompaniesPage };
