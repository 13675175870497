import type { Consumer, TenantTableData } from "../SiteSetupAssistant.types";

export function formatConsumersToFrontendTableData(
  consumer: Array<Consumer>
): Array<TenantTableData> {
  return consumer.map((consumer) => {
    return {
      tenantName: consumer.tenant?.name,
      name: consumer.name,
      tenantAddress: consumer.tenant?.address,
      type: consumer.consumer_type,
      billingAddressIsSameAsAddress:
        consumer.tenant?.billing_address_is_same_as_address,
      isParticipatingInMieterstrom:
        consumer.tenant?.is_participating_in_mieterstrom,
      meter: consumer.meter
    };
  });
}
