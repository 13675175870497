import { type ChartSeries } from "@mantine/charts";
import { seriesColors } from "../../../mantine/charts/mantine.constants";

export function getColoredSeries(series: Array<ChartSeries>) {
  return series.map((singleSeries, index) =>
    singleSeries.color
      ? singleSeries
      : { ...singleSeries, color: seriesColors[index % seriesColors.length] }
  ) as Array<ChartSeries & { color: string }>;
}
