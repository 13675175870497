import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import api from "../../../api";
import urls from "../../../urls";
import { getApiOptions } from "../utils/getApiOptions";
import type { useTodosOptions } from "./useTodos";

function useRevokeTodoDocumentMutation({
  mode,
  variantId,
  isRecurring
}: useTodosOptions) {
  const queryClient = useQueryClient();

  const apiOptions = useMemo(
    () => getApiOptions({ mode, variantId, isRecurring }),
    [mode, variantId, isRecurring]
  );

  const revokeTodoDocumentMutation = useMutation({
    mutationFn: (documentInfo: { todoId: number; documentId: number }) => {
      return api.post(urls.api.todoDocumentRevoke(documentInfo.documentId));
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["todos", apiOptions]
      });
    }
  });

  return {
    revokeTodoDocument: revokeTodoDocumentMutation.mutateAsync
  };
}

export { useRevokeTodoDocumentMutation };
