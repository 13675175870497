import {
  IconCircleCheck,
  IconCircleMinus,
  IconCircleX,
  IconInfoCircle
} from "@tabler/icons-react";
import type { ReactNode } from "react";
import React from "react";
import type { EnergyRevenuesDataVerificationItemState } from "../../../types/api.types";

export const DATA_VERIFICATION_STATES: Record<
  EnergyRevenuesDataVerificationItemState,
  {
    icon: ReactNode;
    label: string;
  }
> = {
  unset: {
    icon: <IconInfoCircle aria-label="Nicht gesetzt" color="var(--gray)" />,
    label: "Nicht gesetzt"
  },
  inProgress: {
    icon: <IconCircleMinus aria-label="In Bearbeitung" color="var(--yellow)" />,
    label: "In Bearbeitung"
  },
  objection: {
    icon: <IconCircleX aria-label="Einspruch" color="var(--danger)" />,
    label: "Einspruch"
  },
  verified: {
    icon: <IconCircleCheck aria-label="Geprüft" color="var(--success)" />,
    label: "Geprüft"
  }
};
