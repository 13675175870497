import type { FieldPath, FieldValues } from "react-hook-form";
import type {
  FormFieldData,
  FormInputData
} from "../../components/BuildingBlocks/Forms/Controllers/FormFieldController";
import type { FieldNameToLabelMap } from "../../components/HistoryTab/HistoryTab";
import { decamelize } from "../SnakeCamelConversion";

export function buildFieldNameToLabelMap<T extends FieldValues>(
  formFieldData: FormFieldData<T>
): FieldNameToLabelMap {
  /**
   * Takes form field data definition for a specific entity and builds a mapping from backend field names to frontend
   * field labels, which are displayed to the user.
   * Example:
   * {
   *     name: "Bezeichnung",
   *     type: "Typ"
   * }
   */
  return Object.values(formFieldData).reduce<FieldNameToLabelMap>(
    (mapping, field: FormInputData<T, FieldPath<T>>) => {
      mapping[decamelize(field.name)] = field.label;
      if (field.alias) mapping[decamelize(field.alias)] = field.label;
      return mapping;
    },
    {}
  );
}
