import { useQuery } from "@tanstack/react-query";
import type { DateTime } from "luxon";
import api from "../../../../api";
import urls from "../../../../urls";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { Unit } from "../../../../utils/enums";
import type { CounterData } from "../../../DataSheet/DataSheet";

// todo put into schema
interface RawEnergyDataFromLocationProps {
  selectedLocationId?: number;
  selectedDateRange: [DateTime<true>, DateTime<true>];
  selectedUnit: Unit;
}

function useRawEnergyDataFromLocation({
  selectedLocationId,
  selectedDateRange,
  selectedUnit
}: RawEnergyDataFromLocationProps) {
  const { data, isLoading, error } = useQuery({
    queryKey: [
      "energy-data-raw-data-from-location",
      {
        dateRange: selectedDateRange,
        location: selectedLocationId,
        unit: selectedUnit
      }
    ],
    queryFn: () =>
      fetchRawData(selectedDateRange, selectedLocationId, selectedUnit),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!selectedLocationId
  });

  return { data, isLoading, error };
}

async function fetchRawData(
  selectedDateRange: [DateTime<true>, DateTime<true>],
  locationId = -1,
  unit: Unit
) {
  const firstDateStr = luxonDateTimeToBackendDateOrDateTime(
    selectedDateRange[0],
    "ISO 8601"
  );
  const lastDateStr = luxonDateTimeToBackendDateOrDateTime(
    selectedDateRange[1],
    "ISO 8601"
  );
  const response = await api.get<CounterData>(
    urls.api.energyDataRawLocationData(
      locationId,
      true,
      firstDateStr,
      lastDateStr,
      unit
    )
  );

  return response.data;
}

export { useRawEnergyDataFromLocation };
