export function validateMeteringLocationNumber(
  meteringLocationNumber: string | null
): boolean {
  if (meteringLocationNumber === null) {
    return false;
  }

  return validateString(meteringLocationNumber);
}

function validateString(value: string) {
  return /^DE[a-zA-Z0-9]{31}$/.test(value);
}
