import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";

export function getMeteringLocationColumn<T extends MRT_RowData>({
  errorText,
  onChange
}: {
  errorText?: string | null;
  onChange?: (value: MRT_RowData) => void;
}): MRT_ColumnDef<T> {
  return {
    accessorKey: "metering_location",
    header: "Messlokation",
    mantineEditTextInputProps: ({ row }) => ({
      error: errorText,
      onChange: () => onChange?.(row)
    })
  };
}
