import type { RawEnergyDataPageState } from "../hooks/useRawEnergyDataPageState";

export function getSearchParamsFromRawEnergyDataPageState(
  pageState: RawEnergyDataPageState
) {
  const searchParams = new URLSearchParams();

  searchParams.set("firstDate", pageState.firstDate.toISODate());
  searchParams.set("lastDate", pageState.lastDate.toISODate());
  searchParams.set("selectedId", pageState.selectedId || "");

  return searchParams;
}
