import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { generatePath, Link, useParams } from "react-router-dom";

import { ROUTES } from "../../../../routes";
import urls, { OptiSupportEndpoints } from "../../../../urls";
import type {
  RegulatoryDuty,
  TodoCategory,
  TodoFile
} from "../../../../utils/backend-types";
import { Alert, AlertColor } from "../../../Alert/Alert";
import { AnchorLink } from "../../../AnchorLink/AnchorLink";
import { DeleteConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { Button } from "../../../Buttons/Button/Button";
import { TABS as ENERGY_DATA_TABS } from "../../../EnergyData/EnergyDataView/EnergyDataView";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import type { Mode } from "../../common";
import { useTodos } from "../../hooks/useTodos";
import {
  isTodoSpecificOnboardingTodo,
  OnboardingTodo
} from "../../onboarding-todos";
import { useTodoCanGenerateDocuments } from "../../utils/useTodoCanGenerateDocuments";
import { DocumentsDropzone } from "./DocumentsDropzone/DocumentsDropzone";
import { DocumentsList } from "./DocumentsList/DocumentsList";
import { GenerateDocuments } from "./GenerateDocuments/GenerateDocuments";

interface DocumentsWidgetProps {
  documents: Array<TodoFile>;
  mode: Mode;
  todoId: number;
  todoLabel: string;
  todoCategory: TodoCategory;
  variantId: number;
  showTodoStatus?: boolean;
  regulatoryDuty?: RegulatoryDuty | null;
  handleErrorMessage: (message: string) => void;
}

function DocumentsWidget({
  mode,
  todoId,
  todoLabel,
  variantId,
  showTodoStatus,
  regulatoryDuty,
  handleErrorMessage
}: DocumentsWidgetProps) {
  const {
    todos,
    storeTodo,
    addTodoDocuments,
    renameTodoDocument,
    removeTodoDocument,
    removeTodoDocuments,
    updateDocumentProvided,
    reloadTodo,
    notify
  } = useTodos({ mode, variantId });

  const { todoCanGenerateDocuments } = useTodoCanGenerateDocuments(
    todoId,
    handleErrorMessage
  );

  const documents = todos?.find((todo) => todo.id === todoId)?.todoFiles || [];
  const documentProvided = todos?.find(
    (todo) => todo.id === todoId
  )?.documentProvided;

  const [documentToDelete, setDocumentToDelete] = useState<TodoFile | null>(
    null
  );

  function handleClickRenameSingleDocument(
    document: TodoFile,
    newName: string
  ) {
    if (document.name !== newName) {
      renameTodoDocument({ todoId, documentId: document.id, newName }).catch(
        openErrorAlertPopup
      );
    }
  }

  function handleClickDeleteSingleDocument(document: TodoFile) {
    setDocumentToDelete(document);
  }

  function handleConfirmDeleteSingleDocument(
    documentToDelete: TodoFile | null
  ) {
    if (!documentToDelete) {
      return;
    }

    removeTodoDocument({
      todoId: todoId,
      documentId: documentToDelete.id
    })
      .then(() => {
        setDocumentToDelete(null);
      })
      .catch(openErrorAlertPopup);
  }

  function deleteMultipleDocuments(documentIds: Array<number>) {
    return removeTodoDocuments({ todoId, documentIds });
  }

  const showEnergyDataLink = isTodoSpecificOnboardingTodo(
    todoLabel,
    OnboardingTodo.HISTORISCHE_MESSDATEN_AUS_UNTERMESSSYSTEMEN_BEREITSTELLEN
  );
  const showDocumentsList = !showEnergyDataLink || documents.length > 0;

  return (
    <div className="documents">
      {todoCanGenerateDocuments &&
        todoCanGenerateDocuments?.warnings?.map((text, index) => (
          <Warning key={index} text={text} />
        ))}

      {showDocumentsList && (
        <DocumentsList
          deleteDocuments={deleteMultipleDocuments}
          documentProvided={documentProvided}
          documents={documents}
          mode={mode}
          regulatoryDuty={regulatoryDuty}
          showTodoStatus={showTodoStatus}
          todoId={todoId}
          updateDocumentProvided={updateDocumentProvided}
          variantId={variantId}
          onClickDelete={handleClickDeleteSingleDocument}
          onClickNotify={notify}
          onClickRename={handleClickRenameSingleDocument}
        />
      )}
      <GenerateDocuments
        handleErrorMessage={handleErrorMessage}
        reloadTodo={reloadTodo}
        storeTodo={storeTodo}
        todoId={todoId}
        todoLabel={todoLabel}
      />
      {showEnergyDataLink ? (
        <EnergyDataLinkWidget padded={showDocumentsList} />
      ) : (
        <DocumentsDropzone
          addTodoDocuments={addTodoDocuments}
          todoId={todoId}
        />
      )}
      <DeleteConfirmationModal
        isModalOpen={!!documentToDelete}
        name={documentToDelete?.name ?? "Unbekanntes Dokument"}
        toggleModal={() => setDocumentToDelete(null)}
        onClickConfirm={() =>
          handleConfirmDeleteSingleDocument(documentToDelete)
        }
      />
    </div>
  );
}

interface EnergyDataLinkWidgetProps {
  padded: boolean;
}

function EnergyDataLinkWidget({ padded }: EnergyDataLinkWidgetProps) {
  const { projectId } = useParams();
  const path = generatePath(ROUTES.energyData, {
    projectId: projectId ?? null
  });

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: padded ? "50px 0" : undefined
      }}
    >
      <p style={{ textAlign: "center" }}>
        Wir haben den Prozess zum Bereitstellen der Energiedaten für Sie
        automatisiert und sind daher umgezogen. Über den Button gelangen Sie
        direkt zum neuen Energiedatenimport. Als Hilfestellung finden Sie
        ebenfalls eine ausführliche Anleitung{" "}
        <AnchorLink
          href={urls.optiSupport(
            OptiSupportEndpoints.EnergiedatenBereitstellen
          )}
        >
          hier
        </AnchorLink>
        .
      </p>
      <Button
        color="brand"
        style={{ marginTop: "20px" }}
        tag={Link}
        to={`${path}${ENERGY_DATA_TABS.TAB_ENERGY_DATA_UPLOAD}`}
      >
        Jetzt Energiedaten bereitstellen
      </Button>
    </div>
  );
}

function Warning({ text }: { text: string }) {
  return (
    <Alert className="document-generation-warning" color={AlertColor.Warning}>
      <ReactMarkdown linkTarget="_blank">{text}</ReactMarkdown>
    </Alert>
  );
}

DocumentsWidget.propTypes = {
  mode: PropTypes.string.isRequired,
  todoId: PropTypes.number.isRequired,
  todoLabel: PropTypes.string.isRequired,
  variantId: PropTypes.number.isRequired
};

export { DocumentsWidget, DocumentsWidgetProps };
