import React, { useState } from "react";
import { type UseFormSetValue, type UseFormWatch } from "react-hook-form";
import { AlertColor } from "../../../Alert/Alert";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import {
  type Meter,
  type MeterWithSource,
  type SiteSetupProcessForForm
} from "../../SiteSetupAssistant.types";
import { MeterTable } from "../CustomFields/MeterTable/MeterTable";

interface MeterFormItemsProps {
  watch: UseFormWatch<SiteSetupProcessForForm>;
  onBlockNavigation: (blocked: boolean) => void;
  onSetFormValue: UseFormSetValue<SiteSetupProcessForForm>;
}

function MeterFormItems({
  watch,
  onBlockNavigation,
  onSetFormValue
}: MeterFormItemsProps) {
  const pvPlants = watch("pvPlants");
  const consumers = watch("consumers");
  const batteryStorage = watch("batteryStorage");
  const connectionName = watch("connectionName");
  const connectionMeasurementType = watch("connectionMeasurementType");
  const connectionMeteringDirection = watch("connectionMeteringDirection");
  const connectionMeteringServiceProvider = watch(
    "connectionMeteringServiceProvider"
  );
  const connectionMeteringLocation = watch("connectionMeteringLocation");
  const connectionMarketLocationFeedin = watch(
    "connectionMarketLocationFeedin"
  );
  const connectionMarketLocationFeedout = watch(
    "connectionMarketLocationFeedout"
  );
  const connectionNumber = watch("connectionNumber");
  const connectionIsCalibrated = watch("connectionIsCalibrated");

  // if no pvPlants are present, there might be a meter=undefined in the array
  const pvPlantMeters: Array<MeterWithSource> = pvPlants
    .filter((pvPlant) => !!pvPlant.meter)
    .map((pvPlant) => ({ ...pvPlant.meter, source: "pvPlant" }));
  const connectionMeter: MeterWithSource = {
    name: connectionName,
    measurement_type: connectionMeasurementType,
    metering_direction: connectionMeteringDirection,
    metering_service_provider: connectionMeteringServiceProvider,
    metering_location: connectionMeteringLocation,
    market_location_feedin: connectionMarketLocationFeedin,
    market_location_feedout: connectionMarketLocationFeedout,
    number: connectionNumber,
    is_calibrated: connectionIsCalibrated,
    source: "connection"
  };
  const batteryStorageMeter: MeterWithSource | undefined = batteryStorage?.meter
    ? { ...batteryStorage.meter, source: "batteryStorage" }
    : undefined;
  const consumerMeters: Array<MeterWithSource> = consumers.map((consumer) => ({
    ...consumer.meter,
    source: "consumer"
  }));

  const [meters, setMeters] = useState<Array<MeterWithSource>>(
    batteryStorageMeter
      ? [
          connectionMeter,
          batteryStorageMeter,
          ...pvPlantMeters,
          ...consumerMeters
        ]
      : [connectionMeter, ...pvPlantMeters, ...consumerMeters]
  );

  function handleChange(updatedMeters: Array<MeterWithSource>) {
    const changedMeter = updatedMeters.find((meter) =>
      Object.keys(meter).some(
        (key) => meter[key] !== meters.find((m) => m.name === meter.name)?.[key]
      )
    );
    const formattedChangedMeter = changedMeter
      ? (Object.keys(changedMeter)
          .filter((key) => key !== "source")
          .reduce(
            (result, key) => ({
              ...result,
              [key]: changedMeter[key] === "" ? null : changedMeter[key]
            }),
            {}
          ) as Meter)
      : null;

    if (!changedMeter || !formattedChangedMeter) {
      return;
    }

    const changedPvPlant = pvPlants.find((pvPlant) =>
      formattedChangedMeter.name.includes(pvPlant.see_number)
    );
    const changedConnection = formattedChangedMeter.name === connectionName;
    const changedConsumer = consumers.some((consumer) =>
      formattedChangedMeter.name.includes(
        consumer.name || "definitelyNotThisName"
      )
    );
    const changedBatteryStorage =
      formattedChangedMeter.name === batteryStorage?.meter?.name;

    if (changedPvPlant) {
      onSetFormValue(
        "pvPlants",
        pvPlants.map((pvPlant) =>
          pvPlant.see_number === changedPvPlant.see_number
            ? { ...pvPlant, meter: formattedChangedMeter }
            : pvPlant
        )
      );
    }
    if (changedConnection) {
      onSetFormValue(
        "connectionMeasurementType",
        formattedChangedMeter.measurement_type
      );
      onSetFormValue(
        "connectionMeteringLocation",
        formattedChangedMeter.metering_location
      );
      onSetFormValue(
        "connectionMarketLocationFeedin",
        formattedChangedMeter.market_location_feedin
      );
      onSetFormValue(
        "connectionMarketLocationFeedout",
        connectionMarketLocationFeedout
      );
      onSetFormValue(
        "connectionLocationType",
        formattedChangedMeter.metering_location
          ? "Messlokation"
          : formattedChangedMeter.market_location_feedin ||
              formattedChangedMeter.market_location_feedout
            ? "Marktlokation"
            : "Keine"
      );
      onSetFormValue("connectionNumber", formattedChangedMeter.number);
      onSetFormValue(
        "connectionIsCalibrated",
        formattedChangedMeter.is_calibrated
      );
    }
    if (changedConsumer) {
      onSetFormValue(
        "consumers",
        consumers.map((consumer) =>
          consumer.name === formattedChangedMeter.name.replace("Zähler ", "")
            ? { ...consumer, meter: formattedChangedMeter }
            : consumer
        )
      );
    }
    if (changedBatteryStorage) {
      onSetFormValue(
        "batteryStorage",
        batteryStorage
          ? { ...batteryStorage, meter: formattedChangedMeter }
          : null
      );
    }

    setMeters(updatedMeters);
  }

  return (
    <div className="MeterFormItems">
      <IconAlert color={AlertColor.Info}>
        Bitte tragen Sie hier für jede PV-Anlage und jeden Verbraucher die
        Informationen über den zugehörigen Zähler ein. Die Identifikationsnummer
        ist notwendig, um einen Zähler eindeutig identifizieren zu können.
        Sowohl für den Datenempfang (gilt nur für Messlokation und
        Marktlokation), als auch für das Ausweisen der Energiemengen auf der
        Rechnung. <br /> <br />
        Mehrfachnennungen einer Identifikationsnummer (Melo, Malo, Zählernummer)
        sind möglich. Die gleiche Identifikationsnummer führt dazu, dass die
        Verbraucher (PV-Anlagen) dem gleichen Zähler zugeordnet werden, also
        gemeinsam über einen Zähler gemessen werden.
      </IconAlert>
      <MeterTable
        meters={meters}
        onBlockNavigation={onBlockNavigation}
        onChange={handleChange}
      />
    </div>
  );
}

export { MeterFormItems };
