import React, { useCallback, useEffect, useState } from "react";
import { Col } from "reactstrap";
import api from "../../../../../api";
import { appendQueryString } from "../../../../../api/helpers";
import { useSupplierGenerators } from "../../../../../hooks/useSupplierGenerators";
import urls from "../../../../../urls";
import type {
  Contract,
  Delivery,
  Generator,
  Person,
  Site
} from "../../../../../utils/backend-types";
import { DeliveryType } from "../../../../../utils/backend-types";
import { showToast } from "../../../../../utils/toast";
import { AlertColor } from "../../../../Alert/Alert";
import { Checkbox } from "../../../../BuildingBlocks/Forms/Checkbox/Checkbox";
import { IconAlert } from "../../../../BuildingBlocks/IconAlert/IconAlert";
import { Row } from "../../../../BuildingBlocks/Layout/Row";
import { SwissArmyTable } from "../../../../SwissArmyTable/SwissArmyTable";
import { getCurrentFormula } from "../utils/getCurrentFormula";
import { getCurrentPrice } from "../utils/getCurrentPrice";
import "./ContractDeliverySubComponent.scss";

const DEFAULT_SORTED = [];

export interface ContractDeliverySubComponentProps {
  contract: Contract;
  deliveries: Array<Delivery>;
  sites: Array<Site>;
  persons: Array<Person>;
  onDeliveriesUpdated: () => void;
}

const SITE_NOT_FOUND_TEXT = "Liegenschaft nicht gefunden";

const DELIVERY_KIND_VALUE_TO_LABEL = {
  [DeliveryType.DirectDelivery]: "Direktlieferung",
  [DeliveryType.ForwardingDelivery]: "Weiterleitung"
};

function ContractDeliverySubComponent({
  contract: initialContract,
  deliveries: initialDeliveries,
  sites,
  persons,
  onDeliveriesUpdated
}: ContractDeliverySubComponentProps) {
  const [contract, setContract] = useState(initialContract);
  const [deliveries, setDeliveries] = useState(initialDeliveries);
  const endpointUrl = urls.api.contract(contract.id);
  const hasMissingDeliveries = !deliveries || deliveries.length === 0;

  const tableColumns = [
    {
      Header: "Art der Lieferung",
      accessor: "deliveryKind",
      Cell: (cell) => <DeliveryKindCell deliveryKind={cell.value} />
    },
    {
      Header: "Erzeuger",
      accessor: "generator",
      minHeight: 45,
      Cell: (data) =>
        data.original.generators[0] ? (
          <GeneratorCell
            contractId={contract.id}
            deliveryGenerators={data.original.generators}
            deliveryId={data.original.id}
            siteId={data.original.site}
          />
        ) : null,
      sortMethod: (a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true
        });
      }
    },
    {
      Header: "Liegenschaft",
      accessor: "site",
      minHeight: 45,
      Cell: (cell) => <SiteCell siteId={cell.value} sites={sites} />
    },
    {
      Header: "Preis (exkl. MWSt.)",
      accessor: "workingPrices",
      minHeight: 45,
      Cell: (cell) => (
        <div>
          {cell.original.useSpotMarketPrices
            ? getCurrentFormula(cell.original.spotMarketPrices)
            : getCurrentPrice(cell.value) + " ct/kWh"}
        </div>
      )
    }
  ];

  useEffect(() => {
    setDeliveries(initialDeliveries);
  }, [initialDeliveries]);

  function getDeliveryName(
    delivery: Delivery,
    sites: Array<Site>,
    contract: Contract,
    persons: Array<Person>
  ) {
    const site = sites.find((site) => site.id === delivery.site);
    const siteName = site ? site.name : SITE_NOT_FOUND_TEXT;
    const supplierId = contract ? contract.supplier : null;
    const supplier = persons.find((person) => person.id === supplierId);
    const supplierName = supplier ? supplier.name : "Unbekannt";
    const suppliedId = contract ? contract.supplied : null;
    const supplied = persons.find((person) => person.id === suppliedId);
    const suppliedName = supplied ? supplied.name : "Unbekannt";

    return `${siteName}: ${supplierName} → ${suppliedName}`;
  }

  const getNamesOfDeliveriesToDelete = useCallback(
    (deliveries) => {
      return deliveries.map((delivery) =>
        getDeliveryName(delivery, sites, contract, persons)
      );
    },
    [contract, persons, sites]
  );

  function handleDeliveriesDeleted(ids: Array<number | string>) {
    const newDeliveries = deliveries.filter(
      (delivery) => !ids.includes(delivery.id)
    );
    setDeliveries(newDeliveries);
    onDeliveriesUpdated();
  }

  async function handleUpdateAutomaticSendInvoice() {
    const newContract = { ...contract };
    newContract.automaticSendInvoiceActive =
      !contract.automaticSendInvoiceActive;
    if (await submitContract(newContract)) {
      setContract(newContract);
    }
  }

  async function handleUpdateAllowance() {
    const newContract = { ...contract };
    newContract.allowanceSendInvoiceViaMail =
      !contract.allowanceSendInvoiceViaMail;
    if (await submitContract(newContract)) {
      setContract(newContract);
    }
  }

  async function submitContract(data: Contract): Promise<boolean> {
    if (contract) {
      try {
        await api.put(endpointUrl, data);
      } catch (e) {
        if (e.response.data.automaticSendInvoiceActive) {
          e.response.data.automaticSendInvoiceActive.forEach((element) => {
            showToast("error", element);
          });
        }
        return false;
      }
    }

    return true;
  }
  return (
    <div className="ContractDeliverySubComponent">
      {hasMissingDeliveries && (
        <Row className="alert-warning-missing-deliveries-row">
          <IconAlert
            className="alert-warning-missing-deliveries"
            color={AlertColor.Warning}
          >
            <span>
              Dieser Vertrag enthält keine Stromlieferungen. Bitte fügen Sie
              diese hinzu, indem Sie auf den Button {`"Lieferung hinzufügen"`}{" "}
              klicken.
            </span>
          </IconAlert>
        </Row>
      )}
      <Row>
        <Col>
          <div>
            <Checkbox
              checked={contract.allowanceSendInvoiceViaMail}
              onChange={() => handleUpdateAllowance()}
            />
            <p className="contract-delivery-sub-component-checkbox">
              Zustimmung des Belieferten zum Rechnungsversand per E-Mail
              vorhanden
            </p>
          </div>
          <div>
            <Checkbox
              checked={contract.automaticSendInvoiceActive}
              disabled={!contract.allowanceSendInvoiceViaMail}
              onChange={() => handleUpdateAutomaticSendInvoice()}
            />
            <p className="contract-delivery-sub-component-checkbox">
              Automatischen Rechnungsversand aktivieren
            </p>
          </div>
        </Col>
      </Row>
      <SwissArmyTable
        data={deliveries}
        defaultSorted={DEFAULT_SORTED}
        options={{
          buttons: {
            create: {
              iconButton: true,
              isPrimary: false,
              isTertiary: true,
              path: appendQueryString("neue-lieferung/", {
                contract: contract.id
              })
            },
            delete: {
              iconButton: true
            },
            edit: {
              path: (id) => `./lieferung/${id}`
            }
          },
          deletion: {
            deleteEndpoint: urls.api.delivery,
            namesToDeleteCollectionFunction: getNamesOfDeliveriesToDelete,
            onDataDeleted: handleDeliveriesDeleted
          },
          pluralWord: "Lieferungen",
          singleWord: "Lieferung"
        }}
        tableColumns={tableColumns}
      />
    </div>
  );
}

interface SiteCellProps {
  siteId: number;
  sites: Array<Site>;
}

function SiteCell({ siteId, sites }: SiteCellProps) {
  const site = sites.find((site) => site.id === siteId);
  const displayName = site ? site.name : SITE_NOT_FOUND_TEXT;

  return <span>{displayName}</span>;
}

interface GeneratorCellProps {
  siteId: number;
  deliveryId: string;
  deliveryGenerators: Array<number>;
  contractId: string;
}

function GeneratorCell({
  siteId,
  deliveryId,
  deliveryGenerators,
  contractId
}: GeneratorCellProps) {
  const { data: generators = [] } = useSupplierGenerators(contractId, siteId, {
    enabled: contractId !== undefined && siteId !== undefined,
    gcTime: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });
  const supplierGeneratorsForDelivery = generators.filter((generator) =>
    deliveryGenerators.includes(generator.id)
  );

  return (
    <>
      {supplierGeneratorsForDelivery.map((generator: Generator) => {
        return <div key={generator.id + deliveryId}>{generator.name}</div>;
      })}
    </>
  );
}

interface DeliveryKindCellProps {
  deliveryKind: DeliveryType;
}
function DeliveryKindCell({ deliveryKind }: DeliveryKindCellProps) {
  return <span>{DELIVERY_KIND_VALUE_TO_LABEL[deliveryKind]}</span>;
}

export { ContractDeliverySubComponent };
