import { Buffer } from "buffer";
import type { EnergyDataPageState } from "../hooks/useEnergyDataPageState";

export function getSearchParamsFromEnergyDataPageState(
  pageState: EnergyDataPageState
) {
  const searchParams = new URLSearchParams();

  searchParams.set(
    "selectedAcquisitions",
    pageState.selectedAcquisitions.join(",")
  );

  searchParams.set(
    "acquisitionFilters",
    Buffer.from(JSON.stringify(pageState.acquisitionFilters), "utf8").toString(
      "base64"
    )
  );

  searchParams.set("filter", pageState.filter?.toString() ?? "");
  searchParams.set("firstDate", pageState.firstDate.toISODate());
  searchParams.set("lastDate", pageState.lastDate.toISODate());
  searchParams.set("frequency", pageState.frequency);
  searchParams.set("unit", pageState.unit);

  return searchParams;
}
