import type { RowSelectionState } from "@tanstack/react-table";
import type { MRT_RowData, MRT_TableOptions } from "mantine-react-table";
import React from "react";
import { IconName } from "../components/BuildingBlocks/Icon/types";
import { IconButton } from "../components/Buttons/IconButton/IconButton";

interface MRTTopToolbarCustomActionsOptions {
  create?: {
    text?: string;
    disabled?: boolean;
    onClick?: () => void;
    hide?: boolean;
  };
  delete?: {
    text?: string;
    hide?: boolean;
    iconName?: IconName;
    onClick?: (rowSelection: RowSelectionState) => void;
  };
}

/** Define Mantine-React-Table renderer for top toolbar custom actions */
export function getMRTTopToolbarCustomActionsRenderer<
  TData extends MRT_RowData
>(
  options: MRTTopToolbarCustomActionsOptions = {}
): Pick<
  MRT_TableOptions<TData>,
  "renderTopToolbarCustomActions" | "enableToolbarInternalActions"
> {
  const showCreateButton = !options.create?.hide;
  const showDeleteButton = !options.delete?.hide;

  return {
    enableToolbarInternalActions: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        {showCreateButton && (
          <IconButton
            className="mrt-create-button"
            color="brand"
            disabled={
              options.create?.disabled ||
              table.getState().creatingRow !== null ||
              table.getState().editingRow !== null
            }
            iconName={IconName.Plus}
            onClick={() =>
              options.create && options.create.onClick
                ? options.create.onClick()
                : table.setCreatingRow(true)
            }
          >
            {options.create?.text || "Erstellen"}
          </IconButton>
        )}
        {showDeleteButton && (
          <IconButton
            className="mrt-delete-button"
            disabled={Object.values(table.getState().rowSelection).length === 0}
            iconName={options.delete?.iconName || IconName.Trash}
            onClick={() =>
              options.delete?.onClick
                ? options.delete.onClick(table.getState().rowSelection)
                : undefined
            }
          >
            {options.delete?.text || "Löschen"}
          </IconButton>
        )}
      </>
    )
  };
}
