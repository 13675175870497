import { Buffer } from "buffer";

// explicitly not using `atob` because of known issues
// see the "Note" box at the end of this MDN section: https://developer.mozilla.org/en-US/docs/Glossary/Base64#javascript_support
export function base64toUtf8(base64String: string) {
  try {
    return Buffer.from(base64String, "base64").toString("utf8");
  } catch (_) {
    return null;
  }
}
