import { useMutation } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import { queryWithPollResponse } from "../../../utils/api-utils";
import { type SiteSetupProcessRaw } from "../SiteSetupAssistant.types";

export function useSiteSetupAssistantCreation(processId: string | undefined) {
  const creationMutation = useMutation({
    mutationFn: (newData: SiteSetupProcessRaw) => createSite(newData, processId)
  });

  return { creationMutation };
}

function createSite(
  siteData: SiteSetupProcessRaw,
  processId: string | undefined
) {
  if (!processId) {
    return Promise.reject();
  }
  return queryWithPollResponse<string>(
    (taskId?: string) =>
      apiWithoutCamelization.post(
        urls.apiWithoutCamelization.siteSetupAssistant.creation(
          processId,
          taskId
        ),
        siteData
      ),
    {
      queryAgainOnPollSuccess: true,
      queryAgainOnPollSuccessWithTaskId: true
    }
  );
}
