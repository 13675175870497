import { Group, Paper, Text } from "@mantine/core";
import React from "react";
import { numericFormatter } from "react-number-format";
import { getDefaultNumericFormatterProps } from "../../../utils/getDefaultNumericFormatterProps";

interface MantineBarChartPayloadItem {
  name: string;
  color: string;
  value: number;
  payload: {
    category: string;
  };
}

interface MantineBarChartTooltipProps {
  seriesLabels: Record<string, string>;
  title: string;
  payload: Array<MantineBarChartPayloadItem>;
}

/*  Mantine doesn't allow changes to the recharts tooltip: https://mantine.dev/charts/line-chart/#custom-tooltip */
function MantineBarChartTooltip({
  seriesLabels,
  title,
  payload
}: MantineBarChartTooltipProps) {
  if (!payload) return null;

  return (
    <Paper
      className="MantineBarChart ChartTooltip"
      px="md"
      py="sm"
      radius="md"
      shadow="md"
      withBorder
    >
      {Array.isArray(payload) && payload.length > 1 && (
        <Text fw={500} mb={5}>
          {title}
        </Text>
      )}
      {Array.isArray(payload) &&
        payload.map((item) => (
          <Group justify="space-between" key={item.name}>
            <Group gap="sm">
              <div className="dot" style={{ backgroundColor: item.color }} />
              <Text fz="sm">
                {payload.length === 1
                  ? item.payload.category
                  : seriesLabels[item.name] || "Unbekannt"}
              </Text>
            </Group>
            <Text fz="sm">
              {numericFormatter(
                item.value.toString(),
                getDefaultNumericFormatterProps()
              )}
            </Text>
          </Group>
        ))}
    </Paper>
  );
}

export { MantineBarChartTooltip, MantineBarChartPayloadItem };
