import { useMutation } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type {
  Consumer,
  SiteSetupProcessRaw
} from "../SiteSetupAssistant.types";

export function useSiteSetupConsumersMutations() {
  const postToGetConsumersMutation = useMutation({
    mutationFn: (newData: SiteSetupProcessRaw) => postToGetConsumers(newData)
  });

  return { postToGetConsumersMutation };
}

async function postToGetConsumers(newSiteData: SiteSetupProcessRaw) {
  if (!newSiteData) {
    return Promise.reject();
  }

  const response = await apiWithoutCamelization.post<Array<Consumer>>(
    urls.apiWithoutCamelization.siteSetupAssistant.createConsumers(),
    newSiteData
  );

  return response.data;
}
