import { Flex } from "@mantine/core";
import React, { useState } from "react";
import type { FormFieldLabelProps } from "../BuildingBlocks/Forms/FormField/FormFieldLabel/FormFieldLabel";
import { Button } from "../Buttons/Button/Button";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { HistoryDisplay } from "./HistoryDisplay/HistoryDisplay";
import { PAGE_SIZE } from "./HistoryTab.constants";
import "./HistoryTab.scss";
import type { EntityId, EntityType } from "./HistoryTab.types";
import { useHistory } from "./hooks/useHistory";

interface FieldNameToLabelMap {
  [key: string]: FormFieldLabelProps["label"];
}

interface HistoryTabProps {
  entityType: EntityType;
  entityId: EntityId;
  subEntityId?: EntityId;
  fieldNameToLabelMap: FieldNameToLabelMap;
}

function HistoryTab({
  entityId,
  subEntityId,
  entityType,
  fieldNameToLabelMap
}: HistoryTabProps) {
  const {
    data: historyData,
    isLoading,
    error
  } = useHistory({ entityId, entityType, subEntityId });
  const [page, setPage] = useState(1);

  const displayShowMoreButton =
    historyData &&
    historyData.length > PAGE_SIZE &&
    page * PAGE_SIZE < historyData.length;

  return (
    <LoadOrError error={error} loading={isLoading}>
      <HistoryDisplay
        fieldNameToLabelMap={fieldNameToLabelMap}
        historyLogs={historyData}
        page={page}
      />
      {displayShowMoreButton && (
        <Flex align="center" justify="center">
          <Button
            color="brand"
            onClick={() => setPage((prevPage: number) => prevPage + 1)}
          >
            Mehr anzeigen
          </Button>
        </Flex>
      )}
    </LoadOrError>
  );
}

export { FieldNameToLabelMap, HistoryTab, HistoryTabProps };
