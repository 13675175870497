import urls from "../../urls";
import { ObjectName } from "../../utils/enums";

export type EntityId = number | string;

export const HISTORY_API_URLS: {
  [key: string]: (entityId: EntityId, subEntityId?: EntityId) => string;
} = {
  [ObjectName.Generator]: urls.apiWithoutCamelization.generatorHistory,
  [ObjectName.Consumer]: urls.apiWithoutCamelization.consumerHistory,
  [ObjectName.Person]: urls.apiWithoutCamelization.personHistory,
  [ObjectName.Meter]: urls.apiWithoutCamelization.meterHistory,
  [ObjectName.MeteringLocation]:
    urls.apiWithoutCamelization.meteringOrMarketLocationsHistory,
  [ObjectName.MarketLocation]:
    urls.apiWithoutCamelization.meteringOrMarketLocationsHistory,
  meteringServiceProvider:
    urls.apiWithoutCamelization.meteringServiceProviderHistory,
  contract: urls.apiWithoutCamelization.contractHistory,
  delivery: urls.apiWithoutCamelization.deliveryHistory,
  paragraph6Contract: urls.apiWithoutCamelization.paragraph6ContractHistory,
  paragraph6Generator:
    urls.apiWithoutCamelization.paragraph6ErzeugerAnteilHistory,
  direktvermarktungContract:
    urls.apiWithoutCamelization.direktvermarktungContractHistory
};

export type EntityType = keyof typeof HISTORY_API_URLS;
