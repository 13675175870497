import React from "react";
import type { EnergyRevenuesViewProps } from "../EnergyRevenuesView";
import { EnergyRevenuesView } from "../EnergyRevenuesView";

function CompanySection({
  view,
  energyRevenuesData,
  personId
}: EnergyRevenuesViewProps) {
  return (
    <EnergyRevenuesView
      // dataVerificationColumns={["direktvermarktung_volume"]}
      energyRevenuesData={energyRevenuesData}
      personId={personId}
      view={view}
    />
  );
}

export { CompanySection };
