import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import type { HistoryLogs } from "../../../utils/backend-types";
import type { EntityId, EntityType } from "../HistoryTab.types";
import { HISTORY_API_URLS } from "../HistoryTab.types";

interface UseHistoryProps {
  entityId: EntityId;
  entityType: EntityType;
  subEntityId?: EntityId;
}

export function useHistory(
  { entityId, entityType, subEntityId }: UseHistoryProps,
  options?: Partial<UseQueryOptions<HistoryLogs>>
) {
  return useQuery({
    queryKey: ["historyData", { entityId }],
    queryFn: () => fetchHistoryData(entityType, entityId, subEntityId),
    ...options
  });
}

async function fetchHistoryData(
  entityType: EntityType,
  entityId: EntityId,
  subEntityId?: EntityId
) {
  const historyUrl = getHistoryUrl(entityType, entityId, subEntityId);
  const response = await apiWithoutCamelization.get<HistoryLogs>(historyUrl);
  return response.data;
}

function getHistoryUrl(
  entityType: EntityType,
  entityId: EntityId,
  subEntityId?: EntityId
) {
  return HISTORY_API_URLS[entityType](entityId, subEntityId);
}
