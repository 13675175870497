import { Button, Group, Menu, Stack, Tooltip } from "@mantine/core";
import React, { useState } from "react";
import { MantineRoundedNumberCell } from "../../../mantine/mrt/components/cell/MantineRoundedNumberCell";
import { EnergyRevenuesDataVerificationItemState } from "../../../types/api.types";
import { DATA_VERIFICATION_STATES } from "./DataVerificationCellConstants";

interface DataVerificationCellProps {
  value?: number;
  onChange?: (newState: EnergyRevenuesDataVerificationItemState) => void;
  initialState?: EnergyRevenuesDataVerificationItemState;
  lastChangeDate?: string;
  lastChangeAuthor?: string;
}

const DataVerificationCell: React.FC<DataVerificationCellProps> = ({
  value = 0,
  onChange,
  initialState = EnergyRevenuesDataVerificationItemState.unset,
  lastChangeDate,
  lastChangeAuthor
}) => {
  const [state, setState] =
    useState<EnergyRevenuesDataVerificationItemState>(initialState);

  const handleStateChange = (
    newState: EnergyRevenuesDataVerificationItemState
  ) => {
    onChange?.(newState);
    setState(newState);
  };

  const currentState = DATA_VERIFICATION_STATES[state];

  return (
    <Group>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button
            fullWidth
            justify="space-between"
            p={0}
            rightSection={
              <Tooltip
                label={
                  <div>
                    {lastChangeDate || lastChangeAuthor ? (
                      <>
                        Letze Änderung am: {lastChangeDate || "unbekannt"}
                        <br />
                        Geändert durch: {lastChangeAuthor || "unbekannt"}
                      </>
                    ) : (
                      "Keine Änderungen"
                    )}
                  </div>
                }
              >
                {currentState.icon}
              </Tooltip>
            }
            variant="transparent"
          >
            <MantineRoundedNumberCell value={value} />
          </Button>
        </Menu.Target>
        <Menu.Dropdown p={0}>
          <Stack align="stretch" gap="0">
            {Object.entries(DATA_VERIFICATION_STATES).map(
              ([key, { label, icon }]) => (
                <Menu.Item
                  aria-label={label}
                  key={key}
                  leftSection={icon}
                  onClick={() =>
                    handleStateChange(
                      key as EnergyRevenuesDataVerificationItemState
                    )
                  }
                >
                  {label}
                </Menu.Item>
              )
            )}
          </Stack>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

export { DataVerificationCell, DataVerificationCellProps };
