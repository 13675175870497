import { YearPickerInput } from "@mantine/dates";
import { IconExclamationCircle } from "@tabler/icons-react";
import React from "react";
import { useParams } from "react-router-dom";
import { useVariantSites } from "../../../../hooks/useVariantSites";
import { THIS_YEAR } from "../../../../utils/dates";
import { Alert, AlertColor } from "../../../Alert/Alert";
import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { EARLIEST_YEAR, INITIAL_YEAR } from "../../common";
import { AvailabilityWidget } from "./AvailabilityWidget/AvailabilityWidget";
import "./EnergyDataAvailabilityPage.scss";

interface EnergyDataAvailabilityPageProps {
  variantId: number;
  year: number;
  onYearChange: (newYear: number) => void;
}

function EnergyDataAvailabilityPage({
  variantId,
  year,
  onYearChange
}: EnergyDataAvailabilityPageProps) {
  const { projectId } = useParams();

  const { error, isLoading, sites } = useVariantSites(variantId);

  return (
    <LoadOrError error={error} loading={isLoading}>
      <div className="EnergyDataAvailabilityPage">
        {sites.length > 0 ? (
          <>
            <Portlet>
              <YearPickerInput
                className="year-picker"
                label="Meldejahr"
                maxDate={new Date(THIS_YEAR, 0, 1)}
                minDate={new Date(EARLIEST_YEAR, 0, 1)}
                value={new Date(year, 0, 1)}
                onChange={(newValue) =>
                  onYearChange(newValue?.getFullYear() || INITIAL_YEAR)
                }
              />
            </Portlet>
            {sites.map((site) => (
              <AvailabilityWidget
                key={site.id}
                projectId={projectId}
                siteId={site.id}
                siteName={site.name}
                toggleInitial={sites.length === 1}
                year={year}
              />
            ))}
          </>
        ) : (
          <Alert color={AlertColor.Warning} icon={<IconExclamationCircle />}>
            Es sind noch keine Liegenschaften konfiguriert.
          </Alert>
        )}
      </div>
    </LoadOrError>
  );
}

export { EnergyDataAvailabilityPage, EnergyDataAvailabilityPageProps };
