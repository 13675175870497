import { ScatterChart, type ScatterChartSeries } from "@mantine/charts";
import { type DateTime } from "luxon";
import React from "react";
import { Icon } from "../../../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../../../BuildingBlocks/Icon/types";
import "./DataGapsChart.scss";
import { DataGapsChartTooltip } from "./DataGapsChartTooltip/DataGapsChartTooltip";

export interface DataGapsChartProps {
  data: Array<ScatterChartSeries>;
  selectedDateRange: [DateTime<true>, DateTime<true>];
}

function DataGapsChart({ data, selectedDateRange }: DataGapsChartProps) {
  const amountOfDaysInTimeRange = selectedDateRange[1].diff(
    selectedDateRange[0],
    "days"
  ).days;

  const dateFromSelectedDateAndDay = (day: number) => {
    const date = selectedDateRange[0];
    return date
      .plus({ days: day - 1 })
      .toJSDate()
      .toLocaleDateString("de-DE");
  };

  return (
    <div className="DataGapsChart" data-testid="data-gaps-chart-wrapper">
      {data.length === 0 && (
        <div className="no-data-display">
          <Icon
            className="no-data-display-check-circle"
            name={IconName.CheckCircle}
          />
          Keine Datenlücken!
        </div>
      )}
      <ScatterChart
        data={data}
        data-testid="data-gaps-chart"
        dataKey={{ x: "day", y: "dataGaps" }}
        h={450}
        labels={{ x: "Datum", y: "Anzahl Datenlücken" }}
        legendProps={{ verticalAlign: "bottom", height: 20 }}
        tooltipProps={{
          content: ({ payload }) => {
            const label = payload?.[0]?.payload?.name;
            const dataGaps = payload?.find(
              (obj) => obj.name === "dataGaps"
            )?.value;
            const day: number | undefined = payload?.find(
              (obj) => obj.name === "day"
            )?.value;
            return (
              day && (
                <DataGapsChartTooltip
                  dataGaps={dataGaps}
                  dateString={dateFromSelectedDateAndDay(day)}
                  label={label}
                />
              )
            );
          }
        }}
        valueFormatter={{
          x: (value) => dateFromSelectedDateAndDay(value)
        }}
        withLegend
        xAxisLabel="Datum"
        xAxisProps={{ domain: [1, 1 + amountOfDaysInTimeRange] }}
        yAxisLabel="Anzahl Datenlücken"
        yAxisProps={{ domain: [0, 96] }}
      />
    </div>
  );
}

export { DataGapsChart };
