import { ConsumerType } from "../../../types/api.types";
export function getLabelFromConsumerType(consumerType?: ConsumerType | null) {
  switch (consumerType) {
    case ConsumerType.Industry:
      return "Industrie";
    case ConsumerType.NonProducingIndustry:
      return "Nicht produzierendes Gewerbe";
    case ConsumerType.PublicProperty:
      return "Öffentliche Liegenschaft";
    case ConsumerType.Agriculture:
      return "Landwirtschaft";
    case ConsumerType.PrivateHousehold:
      return "Privathaushalt(e)";
    case ConsumerType.ElectricChargingStation:
      return "Elektro-Ladesäule(n)";
    case ConsumerType.HeatPump:
      return "Wärmepumpe";
    case ConsumerType.NightStorageHeating:
      return "Nachtspeicherheizung";
    case ConsumerType.Other:
      return "Sonstige";
    default:
      return "Unbekannt";
  }
}
