import { SiteSetupProcessDataStep } from "../../../types/api.types";
import { STEP_TITLE } from "../SiteSetupAssistant.types";

export function getTitleFromStep(step: SiteSetupProcessDataStep) {
  switch (step) {
    case SiteSetupProcessDataStep.name_and_pv_plants:
      return STEP_TITLE.step1;
    case SiteSetupProcessDataStep.address_and_supply_type:
      return STEP_TITLE.step2;
    case SiteSetupProcessDataStep.connection:
      return STEP_TITLE.step3;
    case SiteSetupProcessDataStep.consumers:
      return STEP_TITLE.step4;
    case SiteSetupProcessDataStep.tenants:
      return STEP_TITLE.step5;
    case SiteSetupProcessDataStep.meters:
      return STEP_TITLE.step6;
    default:
      return "Unbekannter Schritt";
  }
}
