import { Accordion } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import urls from "../../../../urls";
import { THEME_VARS } from "../../../../utils/constants";
import { Alert, AlertColor } from "../../../Alert/Alert";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import type {
  Paragraph6ContractResponse,
  Paragraph6CreditError
} from "../../Paragraph6.types";
import { EnergyErrors } from "./ErrorMessages/EnergyErrors";
import { ExactlyOneOperatorErrors } from "./ErrorMessages/ExactlyOneOperatorErrors";
import { GeneratorsWithoutDirektvermarktungErrors } from "./ErrorMessages/GeneratorsWithoutDirektvermarktungErrors";
import { MasterErrors } from "./ErrorMessages/MasterErrors";
import { NetztransparenzValuesError } from "./ErrorMessages/NetztransparenzValuesError";
import "./Paragraph6CreditErrorMessage.scss";

export interface Paragraph6CreditErrorMessageProps {
  paragraph6CreditErrors: Array<Paragraph6CreditError>;
  contracts?: Array<Paragraph6ContractResponse>;
}

export interface Paragraph6CreditErrorMessageDetailProps {
  paragraph6CreditErrors: Array<Paragraph6CreditError>;
  contract?: Paragraph6ContractResponse;
}

function Paragraph6CreditErrorMessage({
  paragraph6CreditErrors,
  contracts
}: Paragraph6CreditErrorMessageProps) {
  const contractsWithErrors =
    contracts
      ?.map((contract) => {
        const contractErrors = paragraph6CreditErrors.filter(
          (error) => error.billing_period.contract === contract.id
        );
        return {
          contract,
          errors: contractErrors
        };
      })
      .filter((contract) => contract.errors.length > 0) || [];

  return (
    <div className="Paragraph6CreditErrorMessage">
      <Alert color={AlertColor.Warning}>
        <div className="credit-error-header-container">
          <Icon
            className="credit-error-icon"
            name={IconName.Warning}
            style={{ color: THEME_VARS.warningColor }}
          />
          <h5 className="credit-error-header">
            Mindestens eine Gutschrift kann nicht erstellt werden
          </h5>
        </div>
        <p>
          Einige Ihrer Verträge benötigen noch Informationen, um Gutschriften zu
          erstellen.
        </p>
        <p>Bitte überprüfen Sie:</p>

        <ul>
          {contractsWithErrors.slice(0, 3).map(({ contract, errors }) => {
            return (
              <ErrorContent
                contract={contract}
                errors={errors}
                key={contract.id}
              />
            );
          })}
        </ul>

        {contractsWithErrors.length > 3 && (
          <Accordion chevronPosition="left" mb="sm" variant="contained">
            <Accordion.Item value="Weitere Verträge">
              <Accordion.Control>Weitere Verträge</Accordion.Control>
              <Accordion.Panel>
                <ul>
                  {contractsWithErrors.slice(3).map(({ contract, errors }) => {
                    return (
                      <ErrorContent
                        contract={contract}
                        errors={errors}
                        key={contract.id}
                      />
                    );
                  })}
                </ul>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )}

        <p>
          Bei Fragen stehen Ihnen unsere{" "}
          <a
            href={urls.optiSupport("gutschrift-erstellen-paragraph-6")}
            rel="noreferrer"
            target="_blank"
          >
            opti.node-Hilfe
          </a>{" "}
          und unser{" "}
          <a href={urls.optiSupport()} rel="noreferrer" target="_blank">
            Support-Team
          </a>{" "}
          gerne zur Verfügung.
        </p>
      </Alert>
    </div>
  );
}

function ErrorContent({
  contract,
  errors
}: {
  contract: Paragraph6ContractResponse;
  errors: Array<Paragraph6CreditError>;
}) {
  return (
    <li key={contract.id}>
      <Link to={"../vertraege/" + contract.id}>{contract.name}: </Link>
      <ul>
        <MasterErrors paragraph6CreditErrors={errors} />
        <ExactlyOneOperatorErrors paragraph6CreditErrors={errors} />
        <EnergyErrors paragraph6CreditErrors={errors} />
        <NetztransparenzValuesError paragraph6CreditErrors={errors} />
        <GeneratorsWithoutDirektvermarktungErrors
          paragraph6CreditErrors={errors}
        />
      </ul>
    </li>
  );
}

export { Paragraph6CreditErrorMessage };
