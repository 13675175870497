import { Checkbox, Group } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";

import { THEME_VARS } from "../../../../utils/constants";
import { showToast } from "../../../../utils/toast";

import { Alert } from "../../../Alert/Alert";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import {
  FinancialDocumentType,
  type Paragraph6CreatableCredit
} from "../../Paragraph6.types";
import { CreatableCreditRow } from "./CreatableCreditRow";
import "./Paragraph6AvailableCreditsModal.scss";

const DATA_COMPLETE_WARNING_THRESHOLD = 100;
export interface Paragraph6AvailableCreditsModalProps {
  variantId: number;
  creatableCredits?: Array<Paragraph6CreatableCredit>;
  contractNames?: Array<{ id: string; name: string }>;
  createParagraph6Credits: (
    credits: Array<Paragraph6CreatableCredit>,
    preview?: boolean
  ) => Promise<void>;
  fetchDraftCreditStatus: (
    credit: Paragraph6CreatableCredit
  ) => Promise<string>;
  previewMode?: boolean;
  isOpen?: boolean;
  onClose: () => void;
  contentType?: FinancialDocumentType;
}

function Paragraph6AvailableCreditsModal({
  variantId,
  contractNames,
  creatableCredits,
  previewMode,
  isOpen,
  createParagraph6Credits,
  fetchDraftCreditStatus,
  onClose,
  contentType = FinancialDocumentType.Credit
}: Paragraph6AvailableCreditsModalProps) {
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isAllLoading, setIsAllLoading] = useState(false);
  const [isWarningAccepted, setIsWarningAccepted] = useState(false);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  function getContractName(contractId: string) {
    return (
      contractNames?.find((contract) => contract.id === contractId)?.name ??
      "Unbekannt"
    );
  }

  const nonWarningCredits = creatableCredits?.filter(
    (credit) =>
      credit.data_completeness_percentage !== undefined &&
      !(credit.data_completeness_percentage < DATA_COMPLETE_WARNING_THRESHOLD)
  );

  const isSomeWarning = creatableCredits?.some(
    (credit) =>
      credit.data_completeness_percentage !== undefined &&
      credit.data_completeness_percentage < DATA_COMPLETE_WARNING_THRESHOLD
  );

  const allCreditsCount =
    (isWarningAccepted
      ? creatableCredits?.length
      : nonWarningCredits?.length) ?? 0;

  function handleClickCreateAll() {
    const creditsToCreate = isWarningAccepted
      ? creatableCredits
      : nonWarningCredits;

    if (!creditsToCreate) {
      return;
    }
    setIsAllLoading(true);
    createParagraph6Credits(creditsToCreate, previewMode)
      .then(() => {
        onClose();
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        queryClient.invalidateQueries({
          queryKey: ["paragraph6Credits", { variantId }]
        });
        setIsAllLoading(false);
      });
  }

  return (
    <Modal
      className="Paragraph6AvailableCreditsModal"
      isOpen={isModalOpen}
      size="lg"
    >
      <ModalHeader toggle={onClose}>
        {contentType === FinancialDocumentType.Credit
          ? "Verfügbare Gutschriften"
          : "Verfügbare Zahlungsinformationen"}
      </ModalHeader>
      <ModalBody>
        {isSomeWarning && !previewMode && (
          <>
            <Alert
              color="yellow"
              icon={
                <Icon
                  name={IconName.Warning}
                  style={{ color: THEME_VARS.warningColor }}
                />
              }
              mb="md"
              title="Eingeschränkte Datenverfügbarkeit"
            >
              Bitte beachten Sie, dass für mindestens eine der Lokationen, die
              in die Berechnung einbezogen werden,{" "}
              <strong>weniger als 100% der Energiedaten</strong> verfügbar sind.
              <br />
              <br />
              Sie haben die Möglichkeit, durch Setzen eines Hakens die
              Erstellung Ihrer Gutschriften dennoch zu veranlassen. Wir möchten
              Sie jedoch darauf hinweisen, dass in diesem Fall das Risiko
              besteht, dass die Energiedaten im Falle einer Rückerstattung nicht
              vollständig übereinstimmen könnten.
              <br />
              <br />
              <Group justify="flex-end">
                <Checkbox
                  checked={isWarningAccepted}
                  label="Ich habe die Warnung gelesen und akzeptiere sie."
                  onChange={() => setIsWarningAccepted(!isWarningAccepted)}
                />
              </Group>
            </Alert>
          </>
        )}
        {creatableCredits && (
          <table>
            <tbody>
              {creatableCredits.map((credit) => {
                return (
                  <CreatableCreditRow
                    contentType={contentType}
                    creatableCredit={credit}
                    createParagraph6Credits={createParagraph6Credits}
                    fetchDraftCreditStatus={fetchDraftCreditStatus}
                    isLoading={isAllLoading}
                    isWarning={
                      credit.data_completeness_percentage !== undefined &&
                      credit.data_completeness_percentage <
                        DATA_COMPLETE_WARNING_THRESHOLD
                    }
                    isWarningAccepted={isWarningAccepted}
                    key={credit.contract + credit.start_date + credit.end_date}
                    name={getContractName(credit.contract)}
                    previewMode={previewMode}
                    variantId={variantId}
                    onClose={onClose}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Schließen</Button>
        {!previewMode && (
          <SpinButton
            color="brand"
            disabled={allCreditsCount === 0}
            spin={isAllLoading}
            onClick={handleClickCreateAll}
          >
            {`Alle ${allCreditsCount} ${
              contentType === FinancialDocumentType.Credit
                ? "Gutschriften"
                : "Zahlungsinformationen"
            } erstellen`}
          </SpinButton>
        )}
      </ModalFooter>
    </Modal>
  );
}

export { Paragraph6AvailableCreditsModal };
