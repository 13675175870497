import { colorsTuple, createTheme, rem } from "@mantine/core";
import { THEME_VARS } from "../utils/constants";

const mantineTheme = createTheme({
  scale: 1.23077, // needed to compensate for legacy 13px font-size on body (from Metronic/Bootstrap styles)
  colors: {
    brand: colorsTuple(THEME_VARS.brandColor),
    secondary: colorsTuple(THEME_VARS.primaryColor),
    danger: colorsTuple(THEME_VARS.dangerColor),
    customGrey: colorsTuple(THEME_VARS.customGrey)
  },
  components: {
    Pagination: {
      defaultProps: {
        size: "sm"
      }
    },
    Popover: {
      defaultProps: {
        shadow: "tooltip"
      }
    },
    Tabs: {
      defaultProps: {
        color: THEME_VARS.primaryColor
      }
    },
    Text: {
      defaultProps: {
        size: "sm"
      }
    },
    List: {
      defaultProps: {
        fz: "sm"
      }
    },
    Checkbox: {
      defaultProps: {
        variant: "outline"
      }
    },
    Tooltip: {
      defaultProps: {
        color: "white",
        zIndex: 1060 // more than metronic modal
      },
      styles: {
        tooltip: {
          boxShadow: "var(--mantine-shadow-tooltip)"
        }
      }
    },
    NumberFormatter: {
      defaultProps: {
        decimalSeparator: ",",
        thousandSeparator: "."
      }
    },
    Stepper: {
      styles: {
        steps: {
          rowGap: "var(--mantine-spacing-sm)"
        },
        separator: {
          marginInline: "var(--mantine-spacing-sm)"
        }
      }
    },
    NumberInput: {
      defaultProps: {
        allowedDecimalSeparators: [".", ","],
        decimalSeparator: ",",
        hideControls: true,
        thousandSeparator: ".",
        withKeyboardEvents: false
      }
    }
  },
  fontFamily: "Montserrat, sans-serif",
  fontSizes: {
    // md-xl are Mantine defaults, only need to reduce xs & sm by 1px so that we have our legacy base 13px available
    xs: rem(11),
    sm: rem(13)
  },
  primaryColor: "brand",
  shadows: {
    md: "1px 1px 3px rgba(0, 0, 0, .25)",
    xl: "5px 5px 3px rgba(0, 0, 0, .25)",
    // should be kept in sync with old Tooltip.scss until that file is deleted
    // rgba(98, 95, 107, 0.1) is equivalent to rgba(color.scale(#c5c2cc, $lightness: -50%), 0.1)
    tooltip: "0 1px 15px 1px rgba(98, 95, 107, 0.1)"
  }
});

export { mantineTheme };
