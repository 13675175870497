import { Userpilot } from "userpilot";
import type { CurrentUser, SiteCategories } from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";

declare const USERPILOT_ID: string;

export function loadUserpilot(
  currentUser: CurrentUser,
  siteCategories: SiteCategories
) {
  Userpilot.initialize(USERPILOT_ID);
  Userpilot.identify(currentUser.id.toString(), {
    name: currentUser.name,
    email: currentUser.email,
    user_type: currentUser.is_staff ? "internal" : "external",
    ee_plus: siteCategories.is_eigenerzeugung_plus ? "yes" : "no",
    premium: siteCategories.is_premium ? "yes" : "no",
    partial_feedin_site: siteCategories.is_partial_feedin_site ? "yes" : "no",
    date_joined: backendDateOrDateTimeToLuxonDateTime(
      currentUser.date_joined
    ).toISO()
  });
}
