import { type NumericFormatProps } from "react-number-format";

export function getDefaultNumericFormatterProps(
  defaults?: NumericFormatProps
): NumericFormatProps {
  return {
    decimalSeparator: ",",
    thousandSeparator: ".",
    decimalScale: 2,
    fixedDecimalScale: false,
    ...defaults
  };
}
