import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";

export function getMeterNumberColumn<
  T extends MRT_RowData
>(): MRT_ColumnDef<T> {
  return {
    accessorKey: "number",
    header: "Zählernummer"
  };
}
