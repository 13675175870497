import React from "react";
import { RoundedNumberFormat } from "../../../../utils/RoundedNumberFormat";

interface MantineRoundedNumberCellProps {
  decimalScale?: number;
  unit?: string;
  value: number;
  fixedDecimalScale?: boolean;
}

function MantineRoundedNumberCell({
  decimalScale = 2,
  unit,
  value,
  fixedDecimalScale = true
}: MantineRoundedNumberCellProps) {
  return (
    <RoundedNumberFormat
      decimalScale={decimalScale}
      decimalSeparator=","
      displayType="text"
      fixedDecimalScale={fixedDecimalScale}
      suffix={unit}
      thousandSeparator="."
      value={value}
    />
  );
}

export { MantineRoundedNumberCell, MantineRoundedNumberCellProps };
