import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type {
  DirektvermarktungContract,
  DirektvermarktungContractResponse
} from "../Direktvermarktung.types";
import type { UseDirektvermarktungUnassignedGeneratorsProps } from "./useDirektvermarktungUnassignedGenerators";

interface EditMutationData extends DirektvermarktungContract {
  id: DirektvermarktungContractResponse["id"];
}

export function useDirektvermarktungContractMutations() {
  const queryClient = useQueryClient();

  const direktvermarktungContractCreateMutation = useMutation({
    mutationFn: (contract: DirektvermarktungContract) =>
      createDirektvermarktungContract(contract),
    onSuccess: (contract) => {
      queryClient.invalidateQueries({
        queryKey: ["direktvermarktungContracts"]
      });
      queryClient.invalidateQueries({
        queryKey: [
          "direktvermarktungContract",
          { contractId: contract.data.id }
        ]
      });
      queryClient.invalidateQueries({
        queryKey: ["energyRevenuesData"]
      });
    }
  });

  const direktvermarktungContractEditMutation = useMutation({
    mutationFn: (data: EditMutationData) => editDirektvermarktungContract(data),
    onSuccess: (_, data) => {
      queryClient.invalidateQueries({
        queryKey: ["direktvermarktungContract", { contractId: data.id }]
      });
      queryClient.invalidateQueries({
        queryKey: ["direktvermarktungContracts"]
      });
      queryClient.invalidateQueries({
        queryKey: [
          "direktvermarktungUnassignedGenerators",
          {
            contractUuid: data.id
          } satisfies UseDirektvermarktungUnassignedGeneratorsProps
        ]
      });
      queryClient.invalidateQueries({
        queryKey: ["energyRevenuesData"]
      });
    }
  });

  const direktvermarktungContractDeleteMutation = useMutation({
    mutationFn: (contractId: string) =>
      deleteDirektvermarktungContract(contractId),
    onSuccess: (_, contractId) => {
      queryClient.invalidateQueries({
        queryKey: ["direktvermarktungContract", { contractId }]
      });
      queryClient.invalidateQueries({
        queryKey: ["direktvermarktungContracts"]
      });
      queryClient.invalidateQueries({
        queryKey: ["energyRevenuesData"]
      });
    }
  });

  return {
    direktvermarktungContractCreateMutation,
    direktvermarktungContractEditMutation,
    direktvermarktungContractDeleteMutation
  };
}

function createDirektvermarktungContract(contract: DirektvermarktungContract) {
  const direktvermarktungContractsUrl =
    urls.apiWithoutCamelization.direktvermarktungContracts();
  return apiWithoutCamelization.post<DirektvermarktungContractResponse>(
    direktvermarktungContractsUrl,
    contract
  );
}

function editDirektvermarktungContract(data: EditMutationData) {
  const direktvermarktungContractsUrl =
    urls.apiWithoutCamelization.direktvermarktungContract(data.id);
  return apiWithoutCamelization.put<DirektvermarktungContractResponse>(
    direktvermarktungContractsUrl,
    data
  );
}

function deleteDirektvermarktungContract(contractId: string) {
  const direktvermarktungContractsUrl =
    urls.apiWithoutCamelization.direktvermarktungContract(contractId);
  return apiWithoutCamelization.delete(direktvermarktungContractsUrl);
}
