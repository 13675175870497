import React from "react";
import urls from "../../../../../../urls";
import { splitNameExtension } from "../../../../../../utils/files/splitNameExtension";
import { Icon } from "../../../../../BuildingBlocks/Icon/Icon";
import { getIconNameByFileExtension } from "../../../../../BuildingBlocks/Icon/utils/getIconNameByFileExtension";
import { DownloadLink } from "../../../../../DownloadLink/DownloadLink";
import { RenameControls } from "./RenameControls";

interface DocumentNameCellProps {
  documentId: number;
  documentName: string;
  showRenameControls: boolean;
  setShowRenameControls: (show: boolean) => void;
  onRename: (newName: string) => void;
}

function DocumentNameCell({
  documentId,
  documentName,
  showRenameControls,
  setShowRenameControls,
  onRename
}: DocumentNameCellProps) {
  const [fileName, fileExtension] = splitNameExtension(documentName);
  const fileIconName = getIconNameByFileExtension(fileExtension);

  return (
    <>
      {showRenameControls ? (
        <RenameControls
          nameWithExtension={documentName}
          onCancel={() => setShowRenameControls(false)}
          onRename={(newName) => {
            setShowRenameControls(false);
            onRename(newName);
          }}
        />
      ) : (
        <>
          <DownloadLink
            className="file-type"
            filename={`${fileName}.${fileExtension}`}
            url={urls.api.todoDocumentDownload(documentId)}
          >
            <Icon name={fileIconName} />
          </DownloadLink>
          <DownloadLink
            className="name"
            filename={`${fileName}.${fileExtension}`}
            url={urls.api.todoDocumentDownload(documentId)}
          >
            {getDocumentLabel(fileName, fileExtension)}
          </DownloadLink>
        </>
      )}
    </>
  );
}

function getDocumentLabel(name: string, extension: string) {
  let extensionText = "";
  if (extension && extension.length > 0) {
    extensionText = `.${extension}`;
  }

  return `${name}${extensionText}`;
}

export { DocumentNameCell };
