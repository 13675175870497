import { Group } from "@mantine/core";
import React, { useMemo } from "react";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { sortBackendDates } from "../../../utils/dates/sortBackendDates";
import type { ChartTableSwitchState } from "../ChartTableSwitch/ChartTableSwitch";
import type {
  EnergyRevenuesListItem,
  EnergyRevenuesListResponse
} from "../RevenueMonitoring.types";
import { EnergyVolumeOverviewChart } from "./EnergyRevenuesCharts/EnergyVolumeOverviewChart";
import { RevenueOverviewChart } from "./EnergyRevenuesCharts/RevenueOverviewChart";
import { EnergyRevenuesTable } from "./EnergyRevenuesTable/EnergyRevenuesTable";
import { sumByKeys } from "./utils/sumByKeys";

export interface EnergyRevenuesViewProps {
  view: ChartTableSwitchState;
  energyRevenuesData: EnergyRevenuesListResponse;
  dataVerificationColumns?: Array<keyof EnergyRevenuesListItem>;
  personId?: number;
}

function EnergyRevenuesView({
  view,
  energyRevenuesData,
  dataVerificationColumns,
  personId
}: EnergyRevenuesViewProps) {
  const formattedData = useMemo(
    () =>
      [...energyRevenuesData]
        .sort((revenuesA, revenuesB) =>
          sortBackendDates(revenuesA.period_start, revenuesB.period_start)
        )
        .map((data) => ({
          ...data,
          period_start: backendDateOrDateTimeToLuxonDateTime(
            data.period_start
          ).toFormat("MMM yyyy")
        })),
    [energyRevenuesData]
  );

  const energyRevenuesDataWithoutPeriodStart = energyRevenuesData.map(
    ({ period_start, missing_data_sources, ...rest }) => rest
  );
  const totals = sumByKeys(energyRevenuesDataWithoutPeriodStart);

  return (
    <>
      {view === "chart" && (
        <Group gap="lg">
          <RevenueOverviewChart formattedData={formattedData} totals={totals} />
          <EnergyVolumeOverviewChart
            formattedData={formattedData}
            totals={totals}
          />
        </Group>
      )}
      {view === "table" && (
        <EnergyRevenuesTable
          dataVerificationColumns={dataVerificationColumns}
          energyRevenuesData={energyRevenuesData}
          personId={personId}
        />
      )}
    </>
  );
}

export { EnergyRevenuesView };
