import { merge } from "lodash";
import type {
  MRT_Column,
  MRT_ColumnDef,
  MRT_Row,
  MRT_SortingState
} from "mantine-react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useMemo } from "react";
import { useCurrentUserQuery } from "../../../../hooks/useCurrentUserQuery";
import { getDefaultMRTOptions } from "../../../../mantine/getDefaultMRTOptions";
import { MantineRoundedNumberCell } from "../../../../mantine/mrt/components/cell/MantineRoundedNumberCell";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { showToast } from "../../../../utils/toast";
import { DataVerificationCell } from "../../DataVerificationCell/DataVerificationCell";
import { usePersonEnergyRevenuesVerificationMutations } from "../../hooks/usePersonEnergyRevenuesVerificationMutations";
import type {
  EnergyRevenuesListItem,
  EnergyRevenuesListResponse
} from "../../RevenueMonitoring.types";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "period",
    desc: true
  }
];

interface EnergyRevenuesTableProps {
  energyRevenuesData: EnergyRevenuesListResponse;
  dataVerificationColumns?: Array<keyof EnergyRevenuesListItem>;
  personId?: number;
}

function EnergyRevenuesTable({
  energyRevenuesData,
  dataVerificationColumns,
  personId
}: EnergyRevenuesTableProps) {
  const totals = useMemo(() => {
    return energyRevenuesData.reduce(
      (acc, curr) => {
        acc.direktvermarktung_volume += curr.direktvermarktung_volume;
        acc.direktvermarktung_net_revenue += curr.direktvermarktung_net_revenue;
        acc.negative_price_volume += curr.negative_price_volume || 0;
        acc.direktvermarktung_fee += curr.direktvermarktung_fee || 0;
        acc.net_revenue_minus_fee += curr.net_revenue_minus_fee || 0;
        acc.market_premium_volume += curr.market_premium_volume;
        acc.market_premium_revenue += curr.market_premium_revenue;
        return acc;
      },
      {
        direktvermarktung_volume: 0,
        direktvermarktung_net_revenue: 0,
        negative_price_volume: 0,
        direktvermarktung_fee: 0,
        net_revenue_minus_fee: 0,
        market_premium_volume: 0,
        market_premium_revenue: 0
      }
    );
  }, [energyRevenuesData]);

  const columns = useMemo<Array<MRT_ColumnDef<EnergyRevenuesListItem>>>(
    () => [
      {
        accessorKey: "period",
        header: "Zeitraum",
        Cell: ({ row }) =>
          backendDateOrDateTimeToLuxonDateTime(
            row.original.period_start
          ).toFormat("MMM yy"),
        footer: "Summe",
        sortingFn: (rowA, rowB) =>
          sortBackendDates(
            rowA.original.period_start,
            rowB.original.period_start
          )
      },
      {
        accessorKey: "direktvermarktung_volume",
        header: "Menge Direktvermarktung (kWh)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={personId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" kWh"
            value={totals.direktvermarktung_volume}
          />
        )
      },
      {
        accessorKey: "negative_price_volume",
        header: "§ 51 Menge mit negativem Preis (kWh)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={personId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" kWh"
            value={totals.negative_price_volume}
          />
        )
      },
      {
        accessorKey: "direktvermarktung_net_revenue",
        header: "Erlös Direktvermarktung netto (EUR)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={personId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.direktvermarktung_net_revenue}
          />
        )
      },
      {
        accessorKey: "direktvermarktung_fee",
        header: "Direktvermarktungsentgelt (EUR)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={personId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.direktvermarktung_fee}
          />
        )
      },
      {
        accessorKey: "net_revenue_minus_fee",
        header: "Direktvermarktung abzgl. Entgelt (EUR)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={personId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.net_revenue_minus_fee}
          />
        )
      },
      {
        accessorKey: "market_premium_volume",
        header: "Menge Marktprämie (kWh)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={personId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" kWh"
            value={totals.market_premium_volume}
          />
        )
      },
      {
        accessorKey: "market_premium_revenue",
        header: "Erlös Marktprämie (EUR)",
        Cell: ({ row, column }) => (
          <CustomTableCell
            column={column}
            dataVerificationColumns={dataVerificationColumns}
            personId={personId}
            row={row}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.market_premium_revenue}
          />
        )
      },
      {
        accessorKey: "revenue_per_kwh",
        header: "∅ Erlös in EUR je kWh",
        Cell: ({ row }) =>
          !row.original.net_revenue_minus_fee ||
          !row.original.direktvermarktung_volume ? (
            ""
          ) : (
            <MantineRoundedNumberCell
              decimalScale={3}
              value={
                row.original.net_revenue_minus_fee /
                row.original.direktvermarktung_volume
              }
            />
          )
      }
    ],
    [totals, dataVerificationColumns, personId]
  );

  const defaultOptions = getDefaultMRTOptions<EnergyRevenuesListItem>({
    emptyRowsFallbackText: "Keine Daten vorhanden."
  });

  const tableOptions = merge({}, defaultOptions, {
    enableColumnActions: true,
    enableTopToolbar: false,
    initialState: {
      pagination: {
        pageSize: 15
      },
      sorting: DEFAULT_SORTED
    }
  });

  const table = useMantineReactTable({
    ...tableOptions,
    columns,
    data: energyRevenuesData
  });

  return <MantineReactTable table={table} />;
}

function CustomTableCell({
  row,
  column,
  dataVerificationColumns,
  personId
}: {
  row: MRT_Row<EnergyRevenuesListItem>;
  column: MRT_Column<EnergyRevenuesListItem>;
  personId?: number;
  dataVerificationColumns?: Array<string>;
}) {
  if (!column.id) return null;
  if (dataVerificationColumns?.includes(column.id) && personId) {
    return (
      <DataVerificationTableCell
        column={column}
        personId={personId}
        row={row}
      />
    );
  }
  return <MantineRoundedNumberCell value={row.original[column.id]} />;
}

type DataVerificationTableCellProps = {
  row: MRT_Row<EnergyRevenuesListItem>;
  column: MRT_Column<EnergyRevenuesListItem>;
  personId: number;
};

function DataVerificationTableCell({
  row,
  column,
  personId
}: DataVerificationTableCellProps) {
  const { currentUser, error: userError } = useCurrentUserQuery();

  const { personEnergyRevenuesVerificationMutation } =
    usePersonEnergyRevenuesVerificationMutations(personId);

  function handleChange(newValue) {
    if (!currentUser) return showToast("error", userError);

    personEnergyRevenuesVerificationMutation
      .mutateAsync({
        state: newValue,
        period_start: row.original.period_start,
        lastChangeAuthor: currentUser.name,
        lastChangeDate: new Date().toISOString(),
        [column.id as keyof EnergyRevenuesListItem]:
          row.original[column.id as keyof EnergyRevenuesListItem]
      })
      .catch((error) => {
        showToast("error", error);
      });
  }

  return (
    <DataVerificationCell
      value={Number(row.original[column.id as keyof EnergyRevenuesListItem])}
      onChange={handleChange}
    />
  );
}
export { EnergyRevenuesTable, EnergyRevenuesTableProps };
