import { useQuery } from "@tanstack/react-query";
import type { DateTime } from "luxon";
import api from "../../../../api";
import urls from "../../../../urls";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { Unit } from "../../../../utils/enums";
import type { CounterData } from "../../../DataSheet/DataSheet";

// todo put into schema
interface RawEnergyDataFromMeterProps {
  selectedMeterId?: number;
  selectedDateRange: [DateTime<true>, DateTime<true>];
  selectedUnit: Unit;
}

function useRawEnergyDataFromMeter({
  selectedMeterId,
  selectedDateRange,
  selectedUnit
}: RawEnergyDataFromMeterProps) {
  const { data, isLoading, error } = useQuery({
    queryKey: [
      "energy-data-raw-data-from-meter",
      {
        dateRange: selectedDateRange,
        meter: selectedMeterId,
        unit: selectedUnit
      }
    ],
    queryFn: () =>
      fetchRawData(selectedDateRange, selectedMeterId, selectedUnit),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!selectedMeterId
  });

  return { data, isLoading, error };
}

async function fetchRawData(
  selectedDateRange: [DateTime<true>, DateTime<true>],
  meterId = -1,
  unit: Unit
) {
  const firstDateStr = luxonDateTimeToBackendDateOrDateTime(
    selectedDateRange[0],
    "ISO 8601"
  );
  const lastDateStr = luxonDateTimeToBackendDateOrDateTime(
    selectedDateRange[1],
    "ISO 8601"
  );
  const response = await api.get<CounterData>(
    urls.api.energyDataRawData(meterId, true, firstDateStr, lastDateStr, unit)
  );

  return response.data;
}

export { useRawEnergyDataFromMeter };
