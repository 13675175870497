import { MixKind } from "../../../../../types/api.types";
import type { Delivery } from "../../../../../utils/backend-types";
import type { FormFieldData } from "../../../../BuildingBlocks/Forms/Controllers/FormFieldController";

export const DELIVERY_FORM_HELP_TEXTS: {
  [key in keyof Delivery]?: string;
} = {
  contract:
    "\nWählen Sie aus den bereits konfigurierten Verträgen denjenigen Vertrag aus, welchen Sie \num die Lieferkonditionen ergänzen möchten. Sie können jedem Vertrag mehrere Lieferungen \nhinzufügen, insbesondere dann, wenn Sie Strom aus unterschiedlichen Quellen \n(Netzstromweiterleitung und vor Ort erzeugter PV-Strom) zu unterschiedlichen Konditionen \nliefern möchten. \n",
  site: "\nWählen Sie aus den bestehenden Liegenschaften diejenige Liegenschaft, für welche die \nLieferkonditionen gelten sollen. Insbesondere die Kosten für Netzstrom können je nach \nLiefergebiet unterschiedlich ausfallen.  \n",
  generators:
    "\nWählen Sie aus den angelegten Erzeugungsanlagen der Liegenschaft diejenige Erzeugungsanlage \nbzw. diejenigen Erzeugungsanlagen aus, welche Sie für die aktuell zu definierende \nStromlieferung heranziehen möchten.\n",
  deliveryKind:
    "\n_Direktlieferung_ umfasst die Lieferung von Strom aus den Erzeugungsanlagen innerhalb \nder Liegenschaft an Verbraucher vor Ort. Wählen Sie demnach Direktlieferung, wenn Sie \ndie Konditionen einer vor Ort Belieferung mit PV-Strom definieren möchten. \n_Weiterleitung_ bezieht sich auf die Weiterleitung von Strom aus dem öffentlichen \nStromnetz an die Verbraucher innerhalb einer Liegenschaft. Wählen Sie also Weiterleitung, \nwenn Sie die Konditionen für die Reststromlieferung von Netzstrom, bspw. zur Ergänzung \nder lokalen Stromlieferung, hinzufügen möchten. \n",
  priceModel:
    "\nMit Weitere Abgaben definieren Sie den Arbeitspreis für die Stromlieferungen, dieser wird \nergänzt um ggf. anfallende Abgaben, Umlagen und Steuern. Eine Änderung dieser staatlich \ndefinierten Abgaben hat somit eine Anpassung des Strompreises in Ihren Lieferungen zur \nFolge. Wählen Sie Fixpreis wenn Sie den Lieferpreis definieren möchten, Sie tragen dann \ndie Chancen und Risiken von Änderungen der staatlich vorgegebenen Preisbestandteile selbst.  \n",
  workingPrices:
    "\nLetztverbraucher sind über Preisänderungen spätestens 2 Wochen, bei Haushaltskunden spätestens 1 Monat, vor Eintritt \nder beabsichtigten Änderung zu unterrichten (§ 41 Abs. 5 EnWG). Im Regelfall ist eine Preisänderung mit einem \nSonderkündigungsrecht verbunden. Insofern keine Zustimmung zur Kommunikation per Email vorliegt, muss die \nBenachrichtigung des Letztverbrauchers per Brief erfolgen.\n",
  useSpotMarketPrices:
    "\nDie gelieferte Strommenge wird mit dem jeweils gültigen stündlichen Börsenstrompreis bewertet.\n\nDer genutzte Börsenstrompreis in opti.node ist der Day-Ahead Auktionspreis für stündliche Einheiten an der EPEX SPOT \nfür die Preiszone Deutschland (inkl. Luxemburg). Produktname: DE epex DA MCP 60min. Der Preis wird um 12 Uhr des \nVortags an der Strombörse Epex Spot gebildet.\n\nBsp. 12 Uhr 20 kWh zum Preis von 10 cent/kWh und 13 Uhr 30 kWh zum Preis von 5 cent/kWh. (20 kWh * 10 cent/kWh + \n30 kWh * 5 cent/kWh) / (20 kWh + 30 kWh) = 7 cent/kWh für den gesamten Zeitraum 12 und 13 Uhr.\n",
  spotMarketPrices:
    '\nFür eine individuelle Berechnung können Sie +, -, *, /, sowie die Funktionen Min und Max verwenden und miteinander \nkombinieren.\nBeispielformeln:\n- Stündlichen Börsenstrompreis unverändert übernehmen: "SPOT"\n- Börsenstrompreis um 1 cent/kWh erhöhen: "SPOT + 1"\n- Börsenstrompreis verwenden, immer, wenn er nicht kleiner als 0 cent/kWh ist, ansonsten 0: "Max(0, SPOT)"\n- Es sollen mindestens 0 und maximal 10 cent/kWh berechnet werden: "Min(10, Max(0, SPOT))"\n',
  workingPricesPrognosis:
    "\nStromtarife von Energieversorgern haben in aller Regel eine Netznutzungs-Komponente, welche \nsich u.a. (siehe Leistungspreis anteilig weitergeben) auf die Menge der bezogenen elektrischen \nArbeit beziehen. Diese variiert je nach Netzgebiet und Netzebene. Hinterlegen Sie hier den \nArbeitspreis für die Netznutzung, insofern Sie diesen weiterreichen möchten. \n\nLetztverbraucher sind über Preisänderungen spätestens 2 Wochen, bei Haushaltskunden spätestens 1 Monat, vor Eintritt \nder beabsichtigten Änderung zu unterrichten (§ 41 Abs. 5 EnWG). Im Regelfall ist eine Preisänderung mit einem \nSonderkündigungsrecht verbunden. Insofern keine Zustimmung zur Kommunikation per Email vorliegt, muss die \nBenachrichtigung des Letztverbrauchers per Brief erfolgen.\n",
  concessionFees:
    "\nDie Konzessionsabgabe kompensiert Gemeinden dafür, dass diese das Recht einräumen, (Strom-) \nLeitungen auf deren Gemeindegebiet zu betreiben. Die Konzessionsabgabe kann von den Gemeinden \nindividuell gestaltet werden, der maximal mögliche Höchstsatz wird in § 2 KAV definiert und \nhängt maßgeblich von der Einwohnerzahl der entsprechenden Gemeinde ab.\n\nLetztverbraucher sind über Preisänderungen spätestens 2 Wochen, bei Haushaltskunden spätestens 1 Monat, vor Eintritt \nder beabsichtigten Änderung zu unterrichten (§ 41 Abs. 5 EnWG). Im Regelfall ist eine Preisänderung mit einem \nSonderkündigungsrecht verbunden. Insofern keine Zustimmung zur Kommunikation per Email vorliegt, muss die \nBenachrichtigung des Letztverbrauchers per Brief erfolgen.\n",
  lossesTransfer:
    "\nAchtung! Alle nicht gemessenen Verbräuche werden als Leitungsverluste auf der Rechnung \nausgewiesen.",
  gridUsagePowerPrices:
    "\nBenennen Sie den jährlichen Leistungspreis für die Netznutzung. Geben Sie den Endwert an, \nalso den Leistungspreis nach Anwendung der maximal bezogenen Strommenge (Spitzenlast) der \nLiegenschaft.\n\nLetztverbraucher sind über Preisänderungen spätestens 2 Wochen, bei Haushaltskunden spätestens 1 Monat, vor Eintritt \nder beabsichtigten Änderung zu unterrichten (§ 41 Abs. 5 EnWG). Im Regelfall ist eine Preisänderung mit einem \nSonderkündigungsrecht verbunden. Insofern keine Zustimmung zur Kommunikation per Email vorliegt, muss die \nBenachrichtigung des Letztverbrauchers per Brief erfolgen.\n",
  automaticallyDetermineGridFees:
    "Wenn Sie die Option Automatisch ermitteln auswählen, ermittelt opti.node jedes Jahr Ihre Netzentgelte auf Basis von Netzgebiet, Spannungsebene und Vollbenutzungsstunden. Diese Werte passt opti.node jedes Jahr automatisch für die Rechnungserstellung an.",
  minimumPurchaseQuantityApplicable:
    "Eine Mindestabnahmemenge bedeutet, dass diese Strommenge mindestens in Rechnung gestellt wird. Unabhängig davon, ob er die Strommenge in vollem Umfang vom Belieferten verbraucht wurde.",
  minimumPurchaseQuantity:
    "Mindestabnahmemengen werden auf das (Kalender-) Jahr definiert. Wird nur ein Teil des Jahres abgerechnet (Inbetriebnahme, Ein- Auszug), so wird auch nur der entsprechende Teil abgerechnet - die Gewichtung geschieht nach Tagen, unabhängig von der Jahreszeit.\n" +
    "\n\n" +
    "Bei monatlicher Spitzabrechnung wird bei Unterschreitung der Mindestabnahmemenge eine jährliche Rechnungskorrektur notwendig."
};

export const DELIVERY_FORM_FIELD_DATA = {
  contract: {
    name: "contract",
    type: "dropdown",
    required: true,
    label: "Vertrag",
    helpText: DELIVERY_FORM_HELP_TEXTS.contract,
    disabled: true,
    choices: []
  },
  site: {
    name: "site",
    type: "dropdown",
    required: true,
    label: "Liegenschaft",
    helpText: DELIVERY_FORM_HELP_TEXTS.site,
    choices: []
  },
  generators: {
    name: "generators",
    type: "dropdown",
    required: false,
    label: "Erzeuger",
    helpText: DELIVERY_FORM_HELP_TEXTS.generators,
    choices: [],
    multiselect: true
  },
  deliveryKind: {
    name: "deliveryKind",
    type: "dropdown",
    required: true,
    label: "Art der Lieferung",
    helpText: DELIVERY_FORM_HELP_TEXTS.deliveryKind,
    choices: [
      {
        value: "direct_delivery",
        displayName: "Direktlieferung"
      },
      {
        value: "forwarding_delivery",
        displayName: "Weiterleitung"
      }
    ]
  },
  priceModel: {
    name: "priceModel",
    type: "dropdown",
    required: true,
    label: "Preismodell",
    helpText: DELIVERY_FORM_HELP_TEXTS.priceModel,
    choices: [
      {
        value: "passing_on",
        displayName: "Weitergabe Abgaben"
      },
      {
        value: "fixed_price",
        displayName: "Fixpreis"
      }
    ]
  },
  useSpotMarketPrices: {
    name: "useSpotMarketPrices",
    type: "boolean",
    required: false,
    label: "Börsenpreise (Day-Ahead Preiszone Deutschland) hinzufügen",
    helpText: DELIVERY_FORM_HELP_TEXTS.useSpotMarketPrices
  },
  spotMarketPrices: {
    name: "spotMarketPrices",
    type: "text",
    label: "Börsenpreis [ct/kWh]"
  },
  electricityTax: {
    name: "electricityTax",
    type: "boolean",
    required: false,
    label: "Ist Ihre Anlage stromsteuerpflichtig?",
    infoText:
      "Anlagen mit einer installierten Leistung von bis zu 2 MW sind in der Regel nicht stromsteuerpflichtig. Wenn Sie mehrere Anlagen kleiner oder gleich 2 MW betreiben, können diese aber vom Hauptzollamt als eine stromsteuerliche Anlage zusammengefasst werden. Bitte kontaktieren Sie das für Sie zuständige Hauptzollamt, falls Sie nicht wissen, ob Ihre Anlage stromsteuerpflichtig ist oder Ihre Anlagen stromsteuerlich zusammengefasst wurden. Eine Übersicht aller Hauptzollämter finden Sie hier."
  },
  lossesTransfer: {
    name: "lossesTransfer",
    type: "boolean",
    required: false,
    label: "Leitungsverlust anteilig weitergeben",
    helpText: DELIVERY_FORM_HELP_TEXTS.lossesTransfer,
    value: false
  },
  mixKind: {
    name: "mixKind",
    type: "dropdown",
    required: false,
    label: "Stromkennzeichnung",
    choices: [
      {
        value: MixKind.Green,
        displayName: "Grünstrom"
      },
      {
        value: MixKind.Grey,
        displayName: "Graustrom"
      },
      {
        value: MixKind.Individual,
        displayName: "Individueller Mix"
      },
      {
        value: MixKind.SubsidisedTenantSupply,
        displayName: "geförderter Mieterstrom"
      }
    ]
  },
  greenCountries: {
    name: "greenCountries",
    type: "text",
    required: false,
    label:
      "In welchen Ländern wurden die entwerteten Herkunftsnachweise erstellt?"
  },
  nuclearPercentage: {
    name: "nuclearPercentage",
    type: "number",
    required: false,
    label: "Kernkraft",
    inputGroupText: "%",
    min: 0,
    max: 100
  },
  coalPercentage: {
    name: "coalPercentage",
    type: "number",
    required: false,
    inputGroupText: "%",
    label: "Kohle",
    min: 0,
    max: 100
  },
  gasPercentage: {
    name: "gasPercentage",
    type: "number",
    inputGroupText: "%",
    required: false,
    label: "Erdgas",
    min: 0,
    max: 100
  },
  otherFossilPercentage: {
    name: "otherFossilPercentage",
    type: "number",
    inputGroupText: "%",
    required: false,
    label: "Sonstige fossile Energieträger",
    min: 0,
    max: 100
  },
  tenantPercentage: {
    name: "tenantPercentage",
    type: "number",
    inputGroupText: "%",
    required: false,
    label: "Mieterstrom, gefördert nach dem EEG",
    min: 0,
    max: 100
  },
  renewableNotPromotedPercentage: {
    name: "renewableNotPromotedPercentage",
    type: "number",
    required: false,
    inputGroupText: "%",
    label:
      "Erneuerbare Energien mit Herkunftsnachweis, nicht gefördert nach dem EEG",
    min: 0,
    max: 100
  },
  renewablePromotedPercentage: {
    name: "renewablePromotedPercentage",
    type: "number",
    required: false,
    inputGroupText: "%",
    label: "Erneuerbaren Energien, gefördert nach dem EEG",
    min: 0,
    max: 100
  },
  yearMix: {
    name: "yearMix",
    type: "text",
    required: false,
    label: "Basisjahr"
  },
  emissionCo2: {
    name: "emissionCo2",
    type: "number",
    required: false,
    label: "CO2-Emissionen (g/kWh)",
    min: 0
  },
  radioactiveWaste: {
    name: "radioactiveWaste",
    type: "number",
    required: false,
    label: "Radioaktiver Abfall (g/kWh)",
    min: 0
  },
  gridUsageBillingModel: {
    name: "gridUsageBillingModel",
    type: "dropdown",
    required: true,
    label: "Abrechnungsmodell Netznutzung",
    choices: [
      {
        value: "slp",
        displayName:
          "SLP Netznutzungentgelte - Nur den Arbeitspreis (Netz) ausweisen"
      },
      {
        value: "rlm",
        displayName:
          "RLM Netznutzungentgelte - Leistungspreis und Arbeitspreis ausweisen"
      }
    ]
  },
  automaticallyDetermineGridFees: {
    name: "automaticallyDetermineGridFees",
    type: "boolean",
    required: true,
    label: "Netznutzungsentgelte automatisch ermitteln",
    helpText: DELIVERY_FORM_HELP_TEXTS.automaticallyDetermineGridFees
  },
  workingPrices: {
    name: "workingPrices",
    type: "number",
    label: "Arbeitspreis [ct/kWh]"
  },
  workingPricesPrognosis: {
    name: "workingPricesPrognosis",
    type: "number",
    label: "Arbeitspreis Netznutzung (Prognose) [ct/kWh]"
  },
  gridUsagePowerPrices: {
    name: "gridUsagePowerPrices",
    type: "number",
    label: "Leistungspreis Netznutzung [€/kW]"
  },
  concessionFees: {
    name: "concessionFees",
    type: "number",
    label: "Konzessionsabgabe [ct/kWh]"
  },
  minimumPurchaseQuantityApplicable: {
    name: "minimumPurchaseQuantityApplicable",
    type: "boolean",
    required: false,
    label: "Mindestabnahmemenge hinzufügen",
    helpText: DELIVERY_FORM_HELP_TEXTS.minimumPurchaseQuantityApplicable
  },
  minimumPurchaseQuantity: {
    name: "minimumPurchaseQuantity",
    type: "number",
    label: "Mindestabnahmemenge pro Jahr",
    inputGroupText: "kWh",
    helpText: DELIVERY_FORM_HELP_TEXTS.minimumPurchaseQuantity
  }
} as const satisfies FormFieldData<Delivery>;
