import { useMutation } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import type { components } from "../../../types/api.types";
import urls from "../../../urls";

export type EnergyRevenuesDataVerificationItem =
  components["schemas"]["EnergyRevenuesDataVerificationItem"];

export function usePersonEnergyRevenuesVerificationMutations(personId: number) {
  const personEnergyRevenuesVerificationMutation = useMutation({
    mutationFn: (data: EnergyRevenuesDataVerificationItem) =>
      updatePersonEnergyRevenueVerification(personId, data)
  });

  return {
    personEnergyRevenuesVerificationMutation
  };
}

function updatePersonEnergyRevenueVerification(
  personId: number,
  data: EnergyRevenuesDataVerificationItem
) {
  const paragraph6CreditUpdateUrl =
    urls.apiWithoutCamelization.personEnergyRevenueVerificationUpdate(personId);
  return apiWithoutCamelization.put<EnergyRevenuesDataVerificationItem>(
    paragraph6CreditUpdateUrl,
    data
  );
}
