import type { Consumer, TenantTableData } from "../SiteSetupAssistant.types";

export function formatFrontendTableDataToConsumers(
  data: Array<TenantTableData>
): Array<Consumer> {
  return data.map((consumer) => {
    return {
      name: consumer.name,
      tenant: {
        name: consumer.tenantName,
        address: consumer.tenantAddress ?? {
          address: null,
          zip: null,
          city: null
        },
        billing_address_is_same_as_address:
          consumer.billingAddressIsSameAsAddress,
        is_participating_in_mieterstrom: consumer.isParticipatingInMieterstrom
      },
      consumer_type: consumer.type,
      meter: consumer.meter
    };
  });
}
