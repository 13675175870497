import React, { useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";
import { splitNameExtension } from "../../../../../../utils/files/splitNameExtension";
import { Button } from "../../../../../Buttons/Button/Button";

interface RenameControlsProps {
  nameWithExtension: string;
  onRename: (newName: string) => void;
  onCancel: () => void;
}

function RenameControls({
  nameWithExtension,
  onRename,
  onCancel
}: RenameControlsProps) {
  const [name, extension] = splitNameExtension(nameWithExtension);
  const [inputValue, setInputValue] = useState(name);

  function handleSubmit() {
    const newName = extension ? `${inputValue}.${extension}` : inputValue;
    onRename(newName);
  }

  return (
    <Form inline onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          bsSize="sm"
          className="mr-sm-2"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button color="secondary" role="button" size="sm" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button
          className="mr-sm-2 rename-submit-button"
          color="primary"
          size="sm"
        >
          Speichern
        </Button>
      </FormGroup>
    </Form>
  );
}

export { RenameControls };
