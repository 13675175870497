import React, { useCallback, useEffect } from "react";
import {
  Controller,
  type Control,
  type FieldErrors,
  type UseFormSetValue,
  type UseFormWatch
} from "react-hook-form";
import { getErrorText } from "../../../../utils/get-error-text";
import { showToast } from "../../../../utils/toast";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { useSiteSetupConsumersMutations } from "../../hooks/useSiteSetupConsumersMutations";
import {
  type Consumer,
  type Meter,
  type SiteSetupProcessForForm
} from "../../SiteSetupAssistant.types";
import { formatFrontendDataToBackend } from "../../utils/formatFrontendDataToBackend";
import { TenantTable } from "../CustomFields/TenantTable/TenantTable";
import { SITE_SETUP_ASSISTANT_FORM } from "../SiteSetupAssistantForms.constants";

export interface TenantFormItemsProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  watch: UseFormWatch<SiteSetupProcessForForm>;
  onBlockNavigation: (blocked: boolean) => void;
  onSetFormValue: UseFormSetValue<SiteSetupProcessForForm>;
}

function TenantFormItems({
  formControl,
  formErrors,
  watch,
  onBlockNavigation,
  onSetFormValue
}: TenantFormItemsProps) {
  const { postToGetConsumersMutation } = useSiteSetupConsumersMutations();
  const watchParticipation = watch("allTenantsParticipatingInMieterstrom");
  const watchSameAddress = watch("allTenantAddressesAreSameAsSiteAddress");

  const participationAndAddressSet =
    typeof watchParticipation !== "undefined" &&
    watchParticipation !== null &&
    typeof watchSameAddress !== "undefined" &&
    watchSameAddress !== null;
  const creationHasBeenTriggered =
    postToGetConsumersMutation.isError ||
    postToGetConsumersMutation.isSuccess ||
    postToGetConsumersMutation.isPending;

  const getConsumers = useCallback(
    async (data) => {
      try {
        const newConsumers: Array<Consumer> =
          await postToGetConsumersMutation.mutateAsync(
            formatFrontendDataToBackend(data)
          );
        const consumers: Array<Consumer & { meter: Meter }> = newConsumers.map(
          (consumer) => {
            return {
              ...consumer,
              meter: {
                ...consumer.meter,
                name: consumer.meter?.name || "Zähler " + consumer.name
              }
            };
          }
        );
        onSetFormValue("consumers", consumers);
      } catch (e) {
        showToast("error", getErrorText(e));
      }
    },
    [onSetFormValue, postToGetConsumersMutation]
  );

  useEffect(() => {
    if (
      participationAndAddressSet &&
      (!formControl._formValues.consumers ||
        formControl._formValues.consumers.length === 0) &&
      !creationHasBeenTriggered
    ) {
      getConsumers(formControl._formValues as SiteSetupProcessForForm);
    }
  }, [
    creationHasBeenTriggered,
    formControl._formValues,
    getConsumers,
    participationAndAddressSet
  ]);

  return (
    <div className="TenantFormItems">
      <FormFieldController
        control={formControl}
        data={{
          ...SITE_SETUP_ASSISTANT_FORM.allTenantAddressesAreSameAsSiteAddress,
          allowInput: !participationAndAddressSet
        }}
        error={formErrors.allTenantAddressesAreSameAsSiteAddress}
      />
      <FormFieldController
        control={formControl}
        data={{
          ...SITE_SETUP_ASSISTANT_FORM.allTenantsParticipatingInMieterstrom,
          allowInput: !participationAndAddressSet
        }}
        error={formErrors.allTenantsParticipatingInMieterstrom}
      />
      {participationAndAddressSet && (
        <Controller
          control={formControl}
          name="consumers"
          render={({ field }) => {
            return (
              <TenantTable
                consumers={field.value}
                defaultMeasurementType={
                  watch("connectionMeasurementType") || null
                }
                onBlockNavigation={onBlockNavigation}
                onChange={field.onChange}
              />
            );
          }}
        />
      )}
    </div>
  );
}

export { TenantFormItems };
