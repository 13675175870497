import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import type { ApiError } from "../../../api";
import api from "../../../api";
import urls from "../../../urls";
import type { Todo, TodoFile } from "../../../utils/backend-types";
import { RegulatoryCluster, TodoGroup } from "../../../utils/backend-types";
import { uploadFileOrFiles } from "../../../utils/files/uploadFileOrFiles";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";
import type { Mode } from "../common";
import { getApiOptions } from "../utils/getApiOptions";

export interface useTodosOptions {
  mode: Mode;
  variantId?: number;
  isRecurring?: boolean;
  onUpdateError?: (error: ApiError) => void;
}

function useTodos({
  mode,
  variantId,
  isRecurring,
  onUpdateError
}: useTodosOptions) {
  const apiOptions = useMemo(
    () => getApiOptions({ mode, variantId, isRecurring }),
    [mode, variantId, isRecurring]
  );

  const todosUrl = urls.api.todos(apiOptions);
  const {
    data: todos,
    isLoading,
    error
  } = useQuery({
    queryKey: ["todos", apiOptions],
    queryFn: () => fetchTodos(todosUrl),
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  const sortedTodos: Array<Todo> | undefined = useMemo(() => {
    if (!todos) {
      return undefined;
    }

    const todosByLabelAndId = todos
      .sort((todoA, todoB) => todoB.id - todoA.id)
      .sort((todoA, todoB) => todoA.label.localeCompare(todoB.label));
    const groupOrder: Array<TodoGroup> = [
      TodoGroup.ProjectPreparation,
      TodoGroup.RegulatoryDuties
    ];
    const groupedTodos: Array<Array<Todo>> = groupOrder.map((group) =>
      todosByLabelAndId.filter((todo) => todo.group === group)
    );
    const clusterOrder: Array<RegulatoryCluster> = [
      RegulatoryCluster.Onboarding,
      RegulatoryCluster.RegulatoryDuty,
      RegulatoryCluster.Other,
      RegulatoryCluster.AssessmentProfitabilityAndFeasibility,
      RegulatoryCluster.PlanningAndRealisation,
      RegulatoryCluster.MeteringConceptAndOperations,
      RegulatoryCluster.DirectMarketing,
      RegulatoryCluster.PowerSupplyToTenantAndCustomer,
      RegulatoryCluster.ProjectFinalisation
    ];
    const clusteredTodos: Array<Array<Array<Todo>>> = groupedTodos.map(
      (todosGroup) =>
        clusterOrder.map((cluster) =>
          todosGroup.filter((todo) => todo.cluster === cluster)
        )
    );

    return clusteredTodos.flat().flat();
  }, [todos]);

  const queryClient = useQueryClient();

  const updateTodoStatusMutation = useMutation({
    mutationFn: (newTodo: Todo) => {
      return api.post(urls.api.updateTodoStatus(newTodo.id), {
        status: newTodo.status
      });
    },
    onMutate: handleAddOrUpdateMutate,
    onError: handleAddOrUpdateError,
    onSettled: handleSettled
  });

  const updateTodoPriorityMutation = useMutation({
    mutationFn: (newTodo: Todo) => {
      return api.post(urls.api.updateTodoPriority(newTodo.id), {
        priority: newTodo.priority
      });
    },
    onMutate: handleAddOrUpdateMutate,
    onError: handleAddOrUpdateError,
    onSettled: handleSettled
  });

  const updateTodoDueDateMutation = useMutation({
    mutationFn: (newTodo: Todo) => {
      return api.post(urls.api.updateTodoDueDate(newTodo.id), {
        dueDate: newTodo.dueDate
      });
    },
    onMutate: handleAddOrUpdateMutate,
    onError: handleAddOrUpdateError,
    onSettled: handleSettled
  });

  async function updateTodoPerson(todoId: number, personId: number) {
    try {
      await api.post(urls.api.updateTodoPerson(todoId), {
        person: personId
      });
    } catch (error) {
      openErrorAlertPopup(error);
    }
    handleSettled();
  }

  const updateTodoOverdueMutation = useMutation({
    mutationFn: (newTodo: Todo) => {
      if (newTodo.overdue) {
        return api
          .post(urls.api.updateTodoDueDate(newTodo.id), {
            dueDate: null
          })
          .then(() => {
            return api.post(urls.api.updateTodoOverdue(newTodo.id), {
              overdue: newTodo.overdue
            });
          });
      } else {
        return api.post(urls.api.updateTodoOverdue(newTodo.id), {
          overdue: newTodo.overdue
        });
      }
    },
    onMutate: handleAddOrUpdateMutate,
    onError: handleAddOrUpdateError,
    onSettled: handleSettled
  });

  const updateTodoResponsibleMutation = useMutation({
    mutationFn: (newTodo: Todo) => {
      return api.post(urls.api.updateTodoResponsible(newTodo.id), {
        responsible: newTodo.responsible
      });
    },
    onMutate: handleAddOrUpdateMutate,
    onError: handleAddOrUpdateError,
    onSettled: handleSettled
  });

  const removeTodoMutation = useMutation({
    mutationFn: (removedTodo: Todo) => {
      return api.delete(urls.api.todosDetail(removedTodo.id));
    },
    onMutate: async (removedTodo: Todo) => {
      await queryClient.cancelQueries({
        queryKey: ["todos", apiOptions]
      });

      const previousTodos = queryClient.getQueryData<Array<Todo>>([
        "todos",
        apiOptions
      ]);

      if (previousTodos) {
        const newTodos = previousTodos.filter(
          (todo) => todo.id !== removedTodo.id
        );
        queryClient.setQueryData(["todos", apiOptions], newTodos);
      }

      return { previousTodos };
    },
    onSettled: handleSettled
  });

  const removeTodosMutation = useMutation({
    mutationFn: (removedTodos: Array<Todo>) => {
      return Promise.all(
        removedTodos.map((removedTodo) =>
          api.delete(urls.api.todosDetail(removedTodo.id))
        )
      );
    },
    onMutate: async (removedTodos: Array<Todo>) => {
      await queryClient.cancelQueries({
        queryKey: ["todos", apiOptions]
      });

      const previousTodos = queryClient.getQueryData<Array<Todo>>([
        "todos",
        apiOptions
      ]);

      if (previousTodos) {
        const newTodos = previousTodos.filter(
          (todo) =>
            !removedTodos.find((removedTodo) => removedTodo.id === todo.id)
        );
        queryClient.setQueryData(["todos", apiOptions], newTodos);
      }

      return { previousTodos };
    },
    onSettled: handleSettled
  });

  const addTodoDocumentsMutations = useMutation({
    mutationFn: (documentsInfo: {
      todoId: number;
      newFiles: Array<File>;
      asSystemUser: boolean;
    }) => {
      const uploadPromises = documentsInfo.newFiles.map((file) =>
        uploadFileOrFiles<TodoFile>(
          file,
          urls.api.todoDocuments(),
          "data_file",
          {
            todo: documentsInfo.todoId,
            as_system_user: documentsInfo.asSystemUser
          }
        )
      );

      return Promise.allSettled(uploadPromises);
    },
    onSuccess: async (
      results,
      documentsInfo: {
        todoId: number;
        newFiles: Array<File>;
        asSystemUser: boolean;
      }
    ) => {
      const uploadedFileResponses: Array<TodoFile> = [];

      results.forEach((result) => {
        if (result.status === "fulfilled") {
          uploadedFileResponses.push(result.value.data);
        }
      });

      await queryClient.cancelQueries({
        queryKey: ["todos", apiOptions]
      });

      const previousTodos = queryClient.getQueryData<Array<Todo>>([
        "todos",
        apiOptions
      ]);
      const todo = previousTodos?.find(
        (todo) => todo.id === documentsInfo.todoId
      );

      if (previousTodos && todo) {
        const newTodo = {
          ...todo,
          todoFiles: [...todo.todoFiles, ...uploadedFileResponses]
        };

        handleAddOrUpdateMutate(newTodo);
      }
    },
    onSettled: handleSettled
  });

  const renameTodoDocumentMutation = useMutation({
    mutationFn: (documentInfo: {
      todoId: number;
      documentId: number;
      newName: string;
    }) => {
      return api.post(urls.api.todoDocumentRename(documentInfo.documentId), {
        name: documentInfo.newName
      });
    },
    onMutate: async (documentInfo: {
      todoId: number;
      documentId: number;
      newName: string;
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["todos", apiOptions]
      });

      const previousTodos = queryClient.getQueryData<Array<Todo>>([
        "todos",
        apiOptions
      ]);
      const todoIndex = previousTodos?.findIndex(
        (todo) => todo.id === documentInfo.todoId
      );

      if (previousTodos && typeof todoIndex !== "undefined" && todoIndex >= 0) {
        const todoFileIndex = previousTodos[todoIndex].todoFiles.findIndex(
          (file) => file.id === documentInfo.documentId
        );

        if (typeof todoFileIndex !== "undefined" && todoFileIndex >= 0) {
          const newTodoFiles = [...previousTodos[todoIndex].todoFiles];
          const newTodo = {
            ...previousTodos[todoIndex],
            todoFiles: newTodoFiles
          };

          newTodo.todoFiles[todoFileIndex] = {
            ...newTodo.todoFiles[todoFileIndex],
            name: documentInfo.newName
          };

          handleAddOrUpdateMutate(newTodo);
        }
      }
    },
    onSettled: handleSettled
  });

  const removeTodoDocumentMutation = useMutation({
    mutationFn: (documentInfo: { todoId: number; documentId: number }) => {
      return api.delete(urls.api.todoDocument(documentInfo.documentId));
    },
    onMutate: async (documentInfo: { todoId: number; documentId: number }) => {
      await queryClient.cancelQueries({
        queryKey: ["todos", apiOptions]
      });

      const previousTodos = queryClient.getQueryData<Array<Todo>>([
        "todos",
        apiOptions
      ]);
      const todo = previousTodos?.find(
        (todo) => todo.id === documentInfo.todoId
      );

      if (todo) {
        handleAddOrUpdateMutate({
          ...todo,
          todoFiles: todo.todoFiles.filter(
            (file) => file.id !== documentInfo.documentId
          )
        });
      }
    },
    onSettled: handleSettled
  });

  const removeTodoDocumentsMutation = useMutation({
    mutationFn: (documentsInfo: {
      todoId: number;
      documentIds: Array<number>;
    }) => {
      return api.post(
        urls.api.todoDeleteSelectedDocuments(documentsInfo.todoId),
        {
          todoFileIds: documentsInfo.documentIds
        }
      );
    },
    onMutate: async (documentsInfo: {
      todoId: number;
      documentIds: Array<number>;
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["todos", apiOptions]
      });

      const previousTodos = queryClient.getQueryData<Array<Todo>>([
        "todos",
        apiOptions
      ]);
      const todo = previousTodos?.find(
        (todo) => todo.id === documentsInfo.todoId
      );

      if (todo) {
        handleAddOrUpdateMutate({
          ...todo,
          todoFiles: todo.todoFiles.filter(
            (file) => !documentsInfo.documentIds.includes(file.id)
          )
        });
      }
    },
    onSettled: handleSettled
  });

  async function fetchTodos(url: string) {
    const response = await api.get<Array<Todo>>(url);
    return response.data;
  }

  async function handleAddOrUpdateMutate(newTodo: Todo) {
    await queryClient.cancelQueries({
      queryKey: ["todos", apiOptions]
    });

    const previousTodos = queryClient.getQueryData<Array<Todo>>([
      "todos",
      apiOptions
    ]);
    const previousTodoIndex = previousTodos?.findIndex(
      (todo) => todo.id === newTodo.id
    );

    if (previousTodos) {
      const newTodos = [...previousTodos];

      if (typeof previousTodoIndex !== "undefined" && previousTodoIndex >= 0) {
        newTodos[previousTodoIndex] = newTodo;
      } else {
        newTodos.push(newTodo);
      }

      queryClient.setQueryData(["todos", apiOptions], newTodos);
    }

    return { previousTodos };
  }

  function handleAddOrUpdateError(
    error: ApiError,
    newTodo: Todo,
    context: { previousTodos?: Array<Todo> }
  ) {
    queryClient.setQueryData(["todos", apiOptions], context.previousTodos);

    if (onUpdateError) {
      onUpdateError(error);
    }
  }

  function handleSettled() {
    return Promise.all([
      queryClient.invalidateQueries({
        queryKey: ["todos"]
      }),
      queryClient.invalidateQueries({
        queryKey: urls.startPage.regulatoryTodos.key()
      }),
      queryClient.invalidateQueries({
        queryKey: urls.startPage.onboardingProgress.key()
      })
    ]);
  }

  const updateDocumentProvidedMutation = useMutation({
    mutationFn: (todoId: number) =>
      api.post(urls.api.todoDocumentProvided(todoId), {}),
    onError: (error) => {
      openErrorAlertPopup(error);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["todos", apiOptions]
      });
    }
  });

  const notifyMutation = useMutation({
    mutationFn: (todoId: number) => api.post(urls.api.todoNotify(todoId), {}),
    onError: (error) => {
      openErrorAlertPopup(error);
    }
  });

  return {
    todos,
    sortedTodos,
    isLoading,
    error,
    updateDocumentProvided: updateDocumentProvidedMutation.mutate,
    notify: notifyMutation.mutateAsync,
    updateTodoStatus: updateTodoStatusMutation.mutate,
    updateTodoPriority: updateTodoPriorityMutation.mutate,
    updateTodoResponsible: updateTodoResponsibleMutation.mutate,
    updateTodoDueDate: updateTodoDueDateMutation.mutate,
    updateTodoOverdue: updateTodoOverdueMutation.mutate,
    updateTodoPerson: updateTodoPerson,
    storeTodo: handleAddOrUpdateMutate,
    addTodoDocuments: addTodoDocumentsMutations.mutateAsync,
    reloadTodo: handleSettled,
    renameTodoDocument: renameTodoDocumentMutation.mutateAsync,
    removeTodo: removeTodoMutation.mutateAsync,
    removeTodos: removeTodosMutation.mutateAsync,
    removeTodoDocument: removeTodoDocumentMutation.mutateAsync,
    removeTodoDocuments: removeTodoDocumentsMutation.mutateAsync
  };
}

export { useTodos };
