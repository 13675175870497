import { LineChart, type LineChartProps } from "@mantine/charts";
import { Group, Stack } from "@mantine/core";
import { range } from "lodash";
import React, { useState } from "react";
import { numericFormatter } from "react-number-format";
import { ReferenceArea } from "recharts";
import { THEME_VARS } from "../../../utils/constants";
import { getDefaultNumericFormatterProps } from "../../../utils/getDefaultNumericFormatterProps";
import { Button } from "../../Buttons/Button/Button";
import { getColoredSeries } from "../utils/getColoredSeries";
import "./MantineLineChart.scss";
import { MantineLineChartLegend } from "./MantineLineChartLegend";
import {
  type MantineLineChartPayloadItem,
  MantineLineChartTooltip
} from "./MantineLineChartTooltip";

type ZoomState = {
  refAreaLeft?: string | number;
  refAreaRight?: string | number;
  left: string | number;
  right: string | number;
  top: string | number;
  bottom: string | number;
};
const initialZoomState: ZoomState = {
  refAreaLeft: undefined,
  refAreaRight: undefined,
  left: "dataMin",
  right: "dataMax",
  top: "dataMax",
  bottom: "dataMin"
};

interface MantineLineChartProps extends LineChartProps {
  zoomable?: boolean;
}

function MantineLineChart({
  data,
  dataKey,
  h = 450,
  lineChartProps,
  lineProps,
  series,
  withLegend,
  xAxisProps,
  yAxisProps,
  zoomable = true,
  ...props
}: MantineLineChartProps) {
  const [zoomState, setZoomState] = useState<ZoomState>(initialZoomState);
  const [activeSeries, setActiveSeries] = useState<Array<string>>([]);

  const xAxisTypeIsNumber = xAxisProps?.type === "number";
  const reallyZoomable = zoomable && xAxisTypeIsNumber;

  const coloredSeries = getColoredSeries(series);

  function handleLegendToggle(seriesId: string) {
    setActiveSeries((prev) =>
      prev.includes(seriesId)
        ? prev.filter((id) => id !== seriesId)
        : [...prev, seriesId]
    );
  }

  function zoomIn() {
    if (
      !zoomState.refAreaLeft ||
      !zoomState.refAreaRight ||
      zoomState.refAreaLeft === zoomState.refAreaRight ||
      zoomState.refAreaRight === ""
    ) {
      setZoomState({ ...zoomState, refAreaLeft: "", refAreaRight: "" });
      return;
    }

    // xAxis domain
    if (
      zoomState.refAreaLeft &&
      zoomState.refAreaRight &&
      zoomState.refAreaLeft > zoomState.refAreaRight
    )
      [zoomState.refAreaLeft, zoomState.refAreaRight] = [
        zoomState.refAreaRight,
        zoomState.refAreaLeft
      ];

    // yAxis domain
    const allYKeys = Object.keys(data[0]).filter((key) => key !== dataKey);
    const indexLeft = data.findIndex(
      (entry) => entry[dataKey] === zoomState.refAreaLeft
    );
    const indexRight = data.findIndex(
      (entry) => entry[dataKey] === zoomState.refAreaRight
    );
    const allVisibleValues = data
      .slice(indexLeft, indexRight + 1)
      .map((entry) => [...allYKeys.map((key) => entry[key])])
      .flat();
    const bottom = Math.min(...allVisibleValues);
    const top = Math.max(...allVisibleValues);

    setZoomState({
      ...zoomState,
      refAreaLeft: undefined,
      refAreaRight: undefined,
      left: zoomState.refAreaLeft,
      right: zoomState.refAreaRight,
      top: top,
      bottom: bottom
    });
  }

  function zoomOut() {
    setZoomState(initialZoomState);
  }

  // This is for discrete x-axis values - would be unnecessary if Mantine supported this
  // (offered tickGap instead of only minTickGap)
  const isZoomed =
    zoomState.left !== "dataMin" || zoomState.right !== "dataMax";
  const allManualXAxisValues = xAxisTypeIsNumber
    ? data
        .map((dataEntry) => dataEntry[dataKey])
        .filter((x) =>
          isZoomed ? x >= zoomState.left && x <= zoomState.right : true
        )
    : [];
  const tickGap = Math.floor(
    allManualXAxisValues.length / (xAxisProps?.tickCount || 5)
  );
  let densedManualXAxisValues = xAxisTypeIsNumber
    ? range(xAxisProps.tickCount || 5)
        .map((i) => allManualXAxisValues[i * tickGap])
        .concat(allManualXAxisValues.slice(-1))
    : [];
  if (densedManualXAxisValues.length > allManualXAxisValues.length) {
    densedManualXAxisValues = allManualXAxisValues;
  }

  return (
    <Group align="start" className="MantineLineChart" gap={0}>
      <Stack w={withLegend ? "80%" : "100%"}>
        {reallyZoomable && (
          <Group>
            <Button
              className="zoom-out-btn"
              disabled={
                zoomState.left === "dataMin" && zoomState.right === "dataMax"
              }
              size="small"
              onClick={zoomOut}
            >
              Zoom zurücksetzen
            </Button>
          </Group>
        )}
        <LineChart
          connectNulls={false}
          curveType="stepAfter"
          data={data}
          dataKey={dataKey}
          h={h}
          series={coloredSeries}
          withDots={false}
          {...props}
          lineChartProps={
            reallyZoomable
              ? {
                  onMouseDown: (e) => {
                    setZoomState({
                      ...zoomState,
                      refAreaLeft: e.activeLabel || "",
                      refAreaRight: e.activeLabel || ""
                    });
                  },
                  onMouseMove: (e) =>
                    zoomState.refAreaLeft &&
                    setZoomState({
                      ...zoomState,
                      refAreaRight: e.activeLabel || ""
                    }),
                  onMouseUp: zoomIn,
                  ...lineChartProps
                }
              : lineChartProps
          }
          lineProps={(series) => ({
            ...lineProps,
            hide: activeSeries.includes(series.name)
          })}
          referenceLines={[
            { x: zoomState.refAreaLeft },
            { x: zoomState.refAreaRight },
            { y: 0, stroke: THEME_VARS.customGrey }
          ]}
          tooltipProps={{
            content: ({
              label,
              payload
            }: {
              label: string;
              payload: Array<MantineLineChartPayloadItem>;
            }) => {
              const title: string =
                label && xAxisProps?.tickFormatter
                  ? xAxisProps.tickFormatter(label, 0)
                  : label;
              return (
                <MantineLineChartTooltip
                  payload={payload}
                  seriesLabels={coloredSeries.reduce(
                    (result, singleSeries) => ({
                      ...result,
                      [singleSeries.name]:
                        singleSeries.label || singleSeries.name
                    }),
                    {}
                  )}
                  title={title}
                />
              );
            }
          }}
          withLegend={false}
          xAxisProps={{
            tickFormatter: (value) =>
              xAxisProps?.type === "number"
                ? numericFormatter(
                    value.toString(),
                    getDefaultNumericFormatterProps()
                  )
                : value,
            ...xAxisProps,
            allowDataOverflow: true,
            domain: [zoomState.left, zoomState.right],
            ticks: xAxisTypeIsNumber ? densedManualXAxisValues : undefined
          }}
          yAxisProps={{
            tickFormatter: (value) =>
              numericFormatter(
                value.toString(),
                getDefaultNumericFormatterProps()
              ),
            ...yAxisProps,
            allowDataOverflow: true,
            domain: ([dataMin, dataMax]) => [
              Math.min(0, dataMin),
              Math.max(0, dataMax)
            ]
          }}
        >
          <ReferenceArea
            x1={zoomState.refAreaLeft}
            x2={zoomState.refAreaRight}
            yAxisId={"left"}
          />
        </LineChart>
      </Stack>
      {withLegend && (
        <MantineLineChartLegend
          activeSeries={activeSeries}
          series={coloredSeries}
          onClick={handleLegendToggle}
        />
      )}
    </Group>
  );
}

export { MantineLineChart, MantineLineChartProps };
