import { type MRT_ColumnDef, type MRT_RowData } from "mantine-react-table";
import { MeterMeasurementType } from "../../../../../../types/api.types";

const MEASUREMENT_TYPE_CHOICES = [
  { value: MeterMeasurementType.Single, label: "Lastgang" },
  {
    value: MeterMeasurementType.ArbitraryCumulative,
    label: "Zählerstände (selbst abgelesen)"
  },
  { value: MeterMeasurementType.NoMeter, label: "Kein Zähler vorhanden" }
];

export function getMeterMeasurementTypeColumn<
  T extends MRT_RowData
>(): MRT_ColumnDef<T> {
  return {
    accessorKey: "measurement_type",
    header: "Wie wird gemessen?",
    editVariant: "select",
    Cell: ({ cell }) =>
      MEASUREMENT_TYPE_CHOICES.find(
        (choice) => choice.value === cell.getValue()
      )?.label,
    mantineEditSelectProps: () => ({
      "aria-label": "Wie wird gemessen?",
      data: MEASUREMENT_TYPE_CHOICES
    })
  };
}
