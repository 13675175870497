import { Tooltip } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDownload } from "../../../../hooks/useDownload";
import urls from "../../../../urls";
import { FINANCIAL_DOCUMENT_TYPES } from "../../../../utils/constants";
import { showToast } from "../../../../utils/toast";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { buttonColors } from "../../../Buttons/Button/Button";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import type { Paragraph6CreatableCredit } from "../../Paragraph6.types";
import { FinancialDocumentType } from "../../Paragraph6.types";

interface CreatableCreditRowProps {
  variantId: number;
  creatableCredit: Paragraph6CreatableCredit;
  name: string;
  createParagraph6Credits: (
    credits: Array<Paragraph6CreatableCredit>,
    preview?: boolean
  ) => Promise<unknown>;
  fetchDraftCreditStatus: (
    credit: Paragraph6CreatableCredit
  ) => Promise<string>;
  onClose: () => void;
  isLoading?: boolean;
  previewMode?: boolean;
  isWarning?: boolean;
  isWarningAccepted?: boolean;
  contentType?: FinancialDocumentType;
}
function CreatableCreditRow({
  variantId,
  creatableCredit,
  name,
  createParagraph6Credits,
  fetchDraftCreditStatus,
  onClose,
  isLoading,
  previewMode,
  isWarning,
  isWarningAccepted,
  contentType = FinancialDocumentType.Credit
}: CreatableCreditRowProps) {
  const queryClient = useQueryClient();
  const { download } = useDownload();

  const [creditIsLoading, setCreditIsLoading] = useState(isLoading);

  function handleClickCreate() {
    setCreditIsLoading(true);
    createParagraph6Credits([creatableCredit], previewMode)
      .then(async () => {
        if (previewMode) {
          const creditId = await fetchDraftCreditStatus(creatableCredit);
          download({
            downloadUrl:
              urls.apiWithoutCamelization.paragraph6CreditDocument(creditId)
          });
        } else {
          onClose();
        }
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        if (!previewMode) {
          queryClient.invalidateQueries({
            queryKey: ["paragraph6Credits", { variantId }]
          });
        }
        setCreditIsLoading(false);
      });
  }

  useEffect(() => {
    setCreditIsLoading(isLoading);
  }, [isLoading]);

  const valueWarning = creatableCredit.data_completeness_percentage
    ? `Es sind nur ${Math.floor(creatableCredit.data_completeness_percentage)} % vorhanden.`
    : "Es sind keine Energiedaten vorhanden.";

  return (
    <tr className="CreatableCreditRow">
      <td>{name}</td>
      <td>
        {creatableCredit.start_date} – {creatableCredit.end_date}
      </td>
      <td>
        <span>
          {isWarning && (
            <Tooltip label={`Unvollständige Energiedaten! ${valueWarning}`}>
              <IconAlertTriangle />
            </Tooltip>
          )}
          {previewMode ? (
            <IconButton
              color={buttonColors.link}
              disabled={creditIsLoading}
              iconColor={buttonColors.primary}
              iconName={
                creditIsLoading ? IconName.SpinnerSpinning : IconName.Eye
              }
              noBackground
              noBorder
              type="button"
              onClick={handleClickCreate}
            >
              Anschauen
            </IconButton>
          ) : (
            <IconButton
              color={buttonColors.brand}
              disabled={creditIsLoading || (isWarning && !isWarningAccepted)}
              iconName={
                creditIsLoading ? IconName.SpinnerSpinning : IconName.Plus
              }
              noBackground
              noBorder
              type="button"
              onClick={handleClickCreate}
            >
              {`${FINANCIAL_DOCUMENT_TYPES[contentType]} erstellen`}
            </IconButton>
          )}
        </span>
      </td>
    </tr>
  );
}

export { CreatableCreditRow };
