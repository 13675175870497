import { Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useManagerVariant } from "../../hooks/useManagerVariant";
import { ROUTES } from "../../routes";
import { SiteSetupProcessDataStep } from "../../types/api.types";
import { showToast } from "../../utils/toast";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { SiteSetupAssistantFormControls } from "./Forms/SiteSetupAssistantFormControls/SiteSetupAssistantFormControls";
import { useSiteSetupAssistant } from "./hooks/useSiteSetupAssistant";
import { useSiteSetupAssistantCreation } from "./hooks/useSiteSetupAssistantCreation";
import { useSiteSetupAssistantMutations } from "./hooks/useSiteSetupAssistantMutations";
import "./SiteSetupAssistant.scss";
import { type SiteSetupProcessForForm } from "./SiteSetupAssistant.types";
import { SiteSetupAssistantNavigation } from "./SiteSetupAssistantNavigation/SiteSetupAssistantNavigation";
import { formatBackendDataToFrontend } from "./utils/formatBackendDataToFrontend";
import { formatFrontendDataToBackend } from "./utils/formatFrontendDataToBackend";
import { getTitleFromStep } from "./utils/getTitleFromStep";

function SiteSetupAssistant() {
  const navigate = useNavigate();
  const { projectId, siteSetupProcessId } = useParams();
  const {
    variantId,
    isLoading: variantIdIsLoading,
    error: variantIdError
  } = useManagerVariant(projectId);
  const {
    data: siteSetupAssistantData,
    isLoading,
    error
  } = useSiteSetupAssistant(siteSetupProcessId);
  const [activeStep, setActiveStep] = useState<
    SiteSetupProcessDataStep | undefined
  >(undefined);
  const defaultStep = SiteSetupProcessDataStep.name_and_pv_plants;
  const [navigationBlocked, setNavigationBlocked] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { control, formState, watch, getValues, setValue } =
    useForm<SiteSetupProcessForForm>({ mode: "onBlur" });

  useEffect(() => {
    if (siteSetupAssistantData) {
      // manual form initialization since the data is not available at the time of form creation
      const frontendData = formatBackendDataToFrontend(siteSetupAssistantData);
      if (frontendData) {
        Object.keys(frontendData).forEach((key) => {
          let newValue = frontendData[key];
          if (key === "pvPlants" && (!newValue || newValue.length === 0)) {
            const newPvPlant = { see_number: "", is_calibrated: true };
            newValue = [newPvPlant];
          }
          if (key === "siteHasBatteryStorage" && !newValue) {
            newValue = false;
          }
          if (key === "isBatteryStorageMeasuredSeparately" && !newValue) {
            newValue = false;
          }
          if (
            key === "connectionIsCalibrated" &&
            typeof newValue !== "boolean"
          ) {
            newValue = true;
          }

          setValue(key as keyof SiteSetupProcessForForm, newValue);
        });
        if (activeStep === undefined && frontendData.step) {
          setActiveStep(frontendData.step);
        }
      }
    }
    // need the following line to avoid triggering twice due to the form being set up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSetupAssistantData]);

  const { updateMutation: updateSiteSetupAssistant } =
    useSiteSetupAssistantMutations(siteSetupProcessId);

  const { creationMutation: createSite } =
    useSiteSetupAssistantCreation(siteSetupProcessId);

  async function handleStep(stepTo: SiteSetupProcessDataStep) {
    try {
      await saveProgress();
      setActiveStep(stepTo);
    } catch (error) {
      //Should be fitted when error handling is implemented
      console.error(error);
    }
  }

  async function handleSaveProgress() {
    await saveProgress();
    //This will run even if the saveProgress function throws an error
    showToast("success", "Ihre Liegenschaftskonfiguration wurde gespeichert.");
    navigate(ROUTES.managerProjectList + projectId);
  }

  async function saveProgress() {
    setIsSubmitting(true);

    try {
      await updateSiteSetupAssistant.mutateAsync(
        formatFrontendDataToBackend({
          ...getValues(),
          step: activeStep || defaultStep
        })
      );
    } catch (error) {
      showToast(
        "error",
        "Ihre Liegenschaftskonfiguration konnte nicht gespeichert werden."
      );
      return Promise.reject(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleCreateSite() {
    await saveProgress();
    setIsCreating(true);

    try {
      const response = await createSite.mutateAsync(
        formatFrontendDataToBackend({
          ...getValues()
        })
      );
      const siteId = response.data;
      if (!!siteId && !!projectId) {
        showToast("success", "Ihre Liegenschaft wurde angelegt.");
        navigate(
          generatePath(ROUTES.managerVariantStructure, {
            projectId,
            siteId
          })
        );
      }
    } catch (error) {
      showToast(
        "error",
        "Ihre Liegenschaftskonfiguration konnte nicht erstellt werden."
      );
      return Promise.reject(error);
    } finally {
      setIsCreating(false);
    }
  }

  return (
    <LoadOrError
      error={error || variantIdError}
      loading={isLoading || variantIdIsLoading}
    >
      {siteSetupAssistantData && variantId && (
        <Portlet
          bodyClassName="site-setup-assistant-portlet-body"
          className="SiteSetupAssistant"
          footer={
            <SiteSetupAssistantFormControls
              activeStep={activeStep || defaultStep}
              isCreating={isCreating}
              isSubmitting={isSubmitting}
              navigationBlocked={navigationBlocked || !formState.isValid}
              onCancel={() => navigate(ROUTES.managerProjectList + projectId)}
              onCreateSite={handleCreateSite}
              onSaveProgress={handleSaveProgress}
              onStep={handleStep}
            />
          }
        >
          <Title
            className="SiteSetupAssistantTitle"
            fw="500"
            mb="lg"
            mt="sm"
            order={3}
            ta="center"
          >
            {getTitleFromStep(activeStep || defaultStep)}
          </Title>
          <form className="site-setup-form">
            <SiteSetupAssistantNavigation
              formControl={control}
              formErrors={formState.errors}
              step={activeStep || defaultStep}
              watch={watch}
              onBlockNavigation={setNavigationBlocked}
              onChangeStep={(step) => handleStep(step)}
              onSetFormValue={setValue}
            />
          </form>
        </Portlet>
      )}
    </LoadOrError>
  );
}

export { SiteSetupAssistant };
