import { Flex } from "@mantine/core";
import { merge } from "lodash";
import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_SortingState,
  useMantineReactTable
} from "mantine-react-table";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGenerationShareMutations } from "../../../../hooks/useGenerationShareMutations";
import type { GenerationShareResponse } from "../../../../hooks/useGenerationShares";
import { useGenerationShares } from "../../../../hooks/useGenerationShares";
import { useMeteringOrMarketLocations } from "../../../../hooks/useMeteringOrMarketLocations";
import { useSuggestedMeteringOrMarketLocation } from "../../../../hooks/useSuggestedMeteringOrMarketLocation";
import { getDefaultMRTOptions } from "../../../../mantine/getDefaultMRTOptions";
import { getDefaultMRTRowEditModeOptions } from "../../../../mantine/getDefaultMRTRowEditModeOptions";
import { getMRTTopToolbarCustomActionsRenderer } from "../../../../mantine/getMRTTopToolbarCustomActionsRenderer";
import { EditButton } from "../../../../mantine/mrt/components/rowActions/EditButton";
import { EraseButton } from "../../../../mantine/mrt/components/rowActions/EraseButton";
import { convertApiErrorsToMrtErrors } from "../../../../mantine/mrt/utils/convertApiErrorsToMrtErrors";
import type { ValidationErrors } from "../../../../types/inlineEditTable.types";
import { showToast } from "../../../../utils/toast";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { MultiConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { getWeightingColumn } from "../../../Direktvermarktung/DirektvermarktungContractInformation/DirektvermarktungGeneratorShareTable/Columns/getWeightingColumn";
import { getZaehlpunktColumn } from "../../../Direktvermarktung/DirektvermarktungContractInformation/DirektvermarktungGeneratorShareTable/Columns/getZaehlpunktColumn";
import { validateDirektvermarktungGeneratorShareFormValues } from "../../../Direktvermarktung/DirektvermarktungContractInformation/DirektvermarktungGeneratorShareTable/utils/validateDirektvermarktungGeneratorShareFormValues";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "generator_display_name",
    desc: false
  }
];

export interface WeightingTabProps {
  siteId: number;
}

function WeightingTab({ siteId }: WeightingTabProps) {
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );

  const [weightingToBeDeleted, setWeightingToBeDeleted] = useState<
    Array<GenerationShareResponse>
  >([]);
  const [selectedGeneratorId, setSelectedGeneratorId] = useState<
    number | undefined
  >(undefined);

  const {
    generationShares = [],
    isLoading,
    error
  } = useGenerationShares(siteId);

  const {
    meteringOrMarketLocations: meloMalosForSelectedGenerator,
    isLoading: meloMalosForSelectedGeneratorLoading
  } = useMeteringOrMarketLocations(siteId);

  const {
    suggestedMeteringOrMarketLocation,
    isLoading: suggestedMeteringOrMarketLocationLoading
  } = useSuggestedMeteringOrMarketLocation(selectedGeneratorId);

  const { editGenerationShare, deleteGenerationShare } =
    useGenerationShareMutations();

  const isPending =
    deleteGenerationShare.isPending || editGenerationShare.isPending;

  function onDelete(generatorShareId) {
    deleteGenerationShare.mutateAsync(generatorShareId).catch((error) => {
      showToast("error", error);
    });
  }

  async function onSubmit(generatorShare) {
    return await editGenerationShare
      .mutateAsync(generatorShare)
      .catch((error) => {
        const validationErrors = convertApiErrorsToMrtErrors(
          error.response.data
        );
        if (Object.keys(validationErrors).length > 0) {
          setValidationErrors(validationErrors);
        } else {
          showToast("error", error);
        }
      });
  }

  const columns = useMemo<Array<MRT_ColumnDef<GenerationShareResponse>>>(
    () => [
      {
        accessorKey: "generator_display_name",
        header: "Erzeuger",
        enableEditing: false
      },
      {
        accessorKey: "current_operator",
        header: "Aktueller Betreiber",
        enableEditing: false
      },
      getZaehlpunktColumn<GenerationShareResponse>({
        validationErrors: validationErrors,
        meloMalos: meloMalosForSelectedGenerator,
        suggestedMeloMaloId: suggestedMeteringOrMarketLocation?.id,
        isLoading:
          meloMalosForSelectedGeneratorLoading ||
          suggestedMeteringOrMarketLocationLoading
      }),
      getWeightingColumn<GenerationShareResponse>({ validationErrors })
    ],
    [
      validationErrors,
      meloMalosForSelectedGenerator,
      suggestedMeteringOrMarketLocation,
      meloMalosForSelectedGeneratorLoading,
      suggestedMeteringOrMarketLocationLoading
    ]
  );

  async function handleEditingRowSave({
    values
  }: {
    values: MRT_Row<GenerationShareResponse>["_valuesCache"];
  }) {
    const newValidationErrors =
      validateDirektvermarktungGeneratorShareFormValues(values, t);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});

    const currentlyEditingGeneratorId =
      table.getState().editingRow?.original.generator_id;

    const submitResult = await onSubmit({
      ...values,
      generator_id: currentlyEditingGeneratorId || values.generator_id,
      metering_or_market_location_id: Number(
        values.metering_or_market_location_id
      )
    });

    if (!submitResult) {
      return;
    }

    table.setEditingRow(null);
    setSelectedGeneratorId(undefined);
  }

  const defaultOptions = getDefaultMRTOptions<GenerationShareResponse>({
    hideInternalToolbar: true
  });

  const rowEditModeOptions =
    getDefaultMRTRowEditModeOptions<GenerationShareResponse>();

  const tableOptions = merge({}, defaultOptions, rowEditModeOptions, {
    ...getMRTTopToolbarCustomActionsRenderer<GenerationShareResponse>({
      create: {
        hide: true
      },
      delete: {
        text: "Erzeugungsanteile zurücksetzen",
        iconName: IconName.Eraser,
        onClick: () => {
          const generationShares = table
            .getSelectedRowModel()
            .rows.map((row) => row.original)
            .filter((generationShare) => generationShare.weighting !== "");
          setWeightingToBeDeleted(generationShares);
        }
      }
    }),
    defaultColumn: {
      size: 250,
      minSize: 180
    },
    enablePagination: false,
    enableBottomToolbar: false,
    displayColumnDefOptions: {
      "mrt-row-select": {
        visibleInShowHideMenu: false,
        size: 40,
        minSize: 40
      },
      "mrt-row-actions": {
        visibleInShowHideMenu: false,
        size: 40,
        minSize: 40
      }
    },
    initialState: {
      sorting: DEFAULT_SORTED
    },
    state: {
      isLoading: isLoading,
      isSaving: isPending,
      showAlertBanner: !!error
    },
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <EditButton
          label="Erzeugungsanteil bearbeiten"
          onClick={() => {
            setSelectedGeneratorId(row.original.generator_id);
            table.setEditingRow(row);
          }}
        />
        <EraseButton
          label="Erzeugungsanteil zurücksetzen"
          onClick={() => {
            setWeightingToBeDeleted([row.original]);
          }}
        />
      </Flex>
    ),
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleEditingRowSave
  });

  const table = useMantineReactTable<GenerationShareResponse>({
    ...tableOptions,
    columns,
    data: generationShares
  });

  const namesOfWeightingToBeDeleted = weightingToBeDeleted.map(
    (generatorShare) => generatorShare.generator_display_name
  );
  const isSingular = namesOfWeightingToBeDeleted.length === 1;

  return (
    <LoadOrError
      error={error}
      loading={isLoading}
      loadingMessage="Erzeugerdaten werden geladen ..."
    >
      <div className="WeightingTable">
        <MantineReactTable table={table} />
        <MultiConfirmationModal
          actionName="zurücksetzen"
          actionObjects={namesOfWeightingToBeDeleted}
          confirmationText={`Möchten Sie ${isSingular ? "den" : "die"} Erzeugungsanteil${isSingular ? " des" : "e der"} folgenden Erzeuger${isSingular && "s"} zurücksetzen? Dieser Schritt kann nicht rückgängig gemacht werden.`}
          isModalOpen={weightingToBeDeleted.length > 0}
          objectName="Erzeugungsanteil"
          toggleModal={() => setWeightingToBeDeleted([])}
          onAction={async () => {
            await Promise.all(
              weightingToBeDeleted.map((generatorShare) =>
                onDelete(generatorShare.generator_id)
              )
            );

            setWeightingToBeDeleted([]);
            table.setRowSelection({}); // MRT doesn't auto-clear the selected rows after deletion
          }}
        />
      </div>
    </LoadOrError>
  );
}

export { WeightingTab };
