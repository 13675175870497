import { Stepper } from "@mantine/core";
import React from "react";
import {
  type Control,
  type FieldErrors,
  type UseFormSetValue,
  type UseFormWatch
} from "react-hook-form";
import { SiteSetupProcessDataStep } from "../../../types/api.types";
import { AddressFormItems } from "../Forms/FormItems/AddressFormItems";
import { ConnectionFormItems } from "../Forms/FormItems/ConnectionFormItems";
import { ConsumerFormItems } from "../Forms/FormItems/ConsumerFormItems";
import { MeterFormItems } from "../Forms/FormItems/MeterFormItems";
import { NameAndPvPlantsFormItems } from "../Forms/FormItems/NameAndPvPlantsFormItems";
import { TenantFormItems } from "../Forms/FormItems/TenantFormItems";
import type { SiteSetupProcessForForm } from "../SiteSetupAssistant.types";
import { getOrderFromStep } from "../utils/getOrderFromStep";
import { getStepFromOrder } from "../utils/getStepFromOrder";
import "./SiteSetupAssistantNavigation.scss";

interface SiteSetupAssistantNavigationProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  step: SiteSetupProcessDataStep;
  onBlockNavigation: (blocked: boolean) => void;
  onChangeStep: (step: SiteSetupProcessDataStep) => void;
  onSetFormValue: UseFormSetValue<SiteSetupProcessForForm>;
  watch: UseFormWatch<SiteSetupProcessForForm>;
}

function SiteSetupAssistantNavigation({
  formControl,
  formErrors,
  step,
  onBlockNavigation,
  onChangeStep,
  onSetFormValue,
  watch
}: SiteSetupAssistantNavigationProps) {
  const activeStep = getOrderFromStep(step);

  return (
    <Stepper
      active={activeStep}
      className="SiteSetupAssistantNavigation"
      completedIcon={({ step }) => step + 1}
      contentPadding="lg"
      mt="lg"
      size="sm"
      onStepClick={(newStepOrder) => {
        const newStep = getStepFromOrder(newStepOrder);
        if (newStep) {
          onChangeStep(newStep);
        }
      }}
    >
      <Stepper.Step label="Bezeichnung & PV-Anlage(n)">
        {activeStep ===
          getOrderFromStep(SiteSetupProcessDataStep.name_and_pv_plants) && (
          <NameAndPvPlantsFormItems
            formControl={formControl}
            formErrors={formErrors}
            onBlockNavigation={onBlockNavigation}
          />
        )}
      </Stepper.Step>
      <Stepper.Step label="Adresse und Art der Versorgung">
        {activeStep ===
          getOrderFromStep(
            SiteSetupProcessDataStep.address_and_supply_type
          ) && (
          <AddressFormItems
            formControl={formControl}
            formErrors={formErrors}
            watch={watch}
            onSetFormValue={onSetFormValue}
          />
        )}
      </Stepper.Step>
      <Stepper.Step label="Netzverknüpfungspunkt">
        {activeStep ===
          getOrderFromStep(SiteSetupProcessDataStep.connection) && (
          <ConnectionFormItems
            formControl={formControl}
            formErrors={formErrors}
            watch={watch}
            onSetFormValue={onSetFormValue}
          />
        )}
      </Stepper.Step>
      <Stepper.Step label="Verbraucherdaten">
        {activeStep ===
          getOrderFromStep(SiteSetupProcessDataStep.consumers) && (
          <ConsumerFormItems
            formControl={formControl}
            formErrors={formErrors}
            watch={watch}
          />
        )}
      </Stepper.Step>
      <Stepper.Step label="Belieferte">
        {activeStep === getOrderFromStep(SiteSetupProcessDataStep.tenants) && (
          <TenantFormItems
            formControl={formControl}
            formErrors={formErrors}
            watch={watch}
            onBlockNavigation={onBlockNavigation}
            onSetFormValue={onSetFormValue}
          />
        )}
      </Stepper.Step>
      <Stepper.Step label="Zählerdaten">
        {activeStep === getOrderFromStep(SiteSetupProcessDataStep.meters) && (
          <MeterFormItems
            watch={watch}
            onBlockNavigation={onBlockNavigation}
            onSetFormValue={onSetFormValue}
          />
        )}
      </Stepper.Step>
    </Stepper>
  );
}

export { SiteSetupAssistantNavigation, SiteSetupAssistantNavigationProps };
