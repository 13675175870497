import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import type { Column } from "react-table";
import { useMachineAccounts } from "../../../../hooks/useMachineAccounts";
import type { MachineAccountConfiguration } from "../../../../utils/backend-types";
import { MachineAccountReimportStatus } from "../../../../utils/backend-types";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { MultiConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import { Button } from "../../../Buttons/Button/Button";
import { DeleteIcon } from "../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../Buttons/EditIcon";
import { CopyToClipboard } from "../../../CopyToClipboard/CopyToClipboard";
import { CustomReactTable } from "../../../CustomReactTable/CustomReactTable";
import type { TypedCellInfo } from "../../../CustomReactTable/CustomReactTable.types";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import { EditMachineAccountModal } from "./EditMachineAccountModal";
import { ReimportMachineAccountModal } from "./ReimportMachineAccountModal";
import "./MachineAccountPage.scss";

enum TableColumn {
  Email = "email",
  ParameterSet = "parameterSetName"
}

enum ModalType {
  New = "New",
  Edit = "Edit",
  Delete = "Delete",
  Reimport = "Reimport"
}

interface ActiveModal {
  type: ModalType;
  item: MachineAccountConfiguration | null;
}

type MachineAccountCellData = TypedCellInfo<
  string | null,
  MachineAccountConfiguration
>;

export interface Props {
  variantId: number;
}

function MachineAccountPage({ variantId }: Props) {
  const {
    machineAccountConfiguration,
    isLoading,
    error,
    deleteMachineAccountConfiguration,
    reimportForMachineAccountConfiguration
  } = useMachineAccounts(variantId);

  const shouldShowStaffView = useShouldShowStaffView();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const [reimportingIds, setReimportingIds] = useState<number[]>([]);

  const handleEmailClick = (e, cellData) => {
    e.preventDefault();

    setActiveModal({
      type: ModalType.Edit,
      item: cellData.original
    });
  };

  const onAfterReimport = useCallback(
    (id: number) => {
      setReimportingIds(reimportingIds.filter((currentId) => currentId !== id));
    },
    [reimportingIds]
  );

  const onReimport = useCallback(
    async (id: number) => {
      setReimportingIds([...reimportingIds, id]);
      await reimportForMachineAccountConfiguration(id, variantId);
      onAfterReimport(id);
    },
    [
      onAfterReimport,
      reimportForMachineAccountConfiguration,
      reimportingIds,
      variantId
    ]
  );

  const tableColumns: Column[] = useMemo(() => {
    const columns = [
      {
        Header: "E-Mail",
        accessor: TableColumn.Email,
        Cell: (cellData: MachineAccountCellData) => {
          const disabled =
            cellData.original.status ===
            MachineAccountReimportStatus.InProgress;

          return (
            <CopyToClipboard
              className={classNames("email", { ["email-disabled"]: disabled })}
              label={cellData.original.email || ""}
            >
              <a href="#" onClick={(e) => handleEmailClick(e, cellData)}>
                {cellData.original.email}
              </a>
            </CopyToClipboard>
          );
        }
      },
      {
        Header: "Dateiformat",
        accessor: TableColumn.ParameterSet
      },
      {
        Cell: (cellData: MachineAccountCellData) => (
          <EditIcon
            disabled={
              cellData.original.status ===
              MachineAccountReimportStatus.InProgress
            }
            tooltipText="Machine account bearbeiten"
            onClick={() =>
              setActiveModal({ type: ModalType.Edit, item: cellData.original })
            }
          />
        ),
        width: 30,
        sortable: false,
        resizable: false
      },
      {
        Cell: (cellData: MachineAccountCellData) => (
          <DeleteIcon
            disabled={
              cellData.original.status ===
              MachineAccountReimportStatus.InProgress
            }
            tooltipText="Machine account löschen"
            onClick={() =>
              setActiveModal({
                type: ModalType.Delete,
                item: cellData.original
              })
            }
          />
        ),
        width: 30,
        sortable: false,
        resizable: false
      }
    ];

    if (shouldShowStaffView) {
      columns.push({
        Cell: (cellData: MachineAccountCellData) => {
          const { status, id } = cellData.original;

          const inProgress =
            reimportingIds.includes(id) ||
            status === MachineAccountReimportStatus.InProgress;

          return (
            <Icon
              className={classNames("reimport-icon", {
                ["reimport-icon-spinner"]: inProgress
              })}
              name={
                inProgress ? IconName.SpinnerSpinning : IconName.RotateRight
              }
              tooltipText={
                inProgress ? undefined : "Machine account files reimport"
              }
              onClick={
                inProgress
                  ? undefined
                  : () =>
                      setActiveModal({
                        type: ModalType.Reimport,
                        item: cellData.original
                      })
              }
            />
          );
        },
        width: 30,
        sortable: false,
        resizable: false
      });
    }

    return columns;
  }, [reimportingIds, shouldShowStaffView]);

  const closeModal = useCallback(() => setActiveModal(null), []);

  const handleClickDelete = useCallback(async () => {
    try {
      await deleteMachineAccountConfiguration(activeModal?.item?.id);
    } catch (e) {
      openErrorAlertPopup(e);
    }
  }, [activeModal?.item?.id, deleteMachineAccountConfiguration]);

  const renderModal = useCallback(() => {
    if (activeModal === null) {
      return;
    }

    switch (activeModal.type) {
      case ModalType.Edit:
        return (
          <EditMachineAccountModal
            machineAccount={activeModal.item}
            variantId={variantId}
            onClose={closeModal}
          />
        );
      case ModalType.New:
        return (
          <EditMachineAccountModal
            machineAccount={null}
            variantId={variantId}
            onClose={closeModal}
          />
        );
      case ModalType.Delete:
        return (
          <MultiConfirmationModal
            actionName="löschen"
            actionObjects={[activeModal.item?.email || ""]}
            confirmationText="Möchten Sie die folgende Adresse wirklich löschen?"
            isModalOpen
            objectName="Machine Account"
            toggleModal={closeModal}
            onAction={handleClickDelete}
          />
        );
      case ModalType.Reimport:
        return (
          <ReimportMachineAccountModal
            machineAccount={activeModal.item}
            onClose={closeModal}
            onSubmit={onReimport}
          />
        );
      default:
        console.error(`Modal type ${activeModal.type} not implemented`);
        return null;
    }
  }, [activeModal, closeModal, handleClickDelete, onReimport, variantId]);

  return (
    <Portlet className="MachineAccountPage">
      <div className="control-panel">
        <Button
          color="brand"
          onClick={() => setActiveModal({ type: ModalType.New, item: null })}
        >
          Neue Adresse einrichten
        </Button>
      </div>
      <LoadOrError error={error} loading={isLoading}>
        <CustomReactTable
          columns={tableColumns}
          data={machineAccountConfiguration}
          defaultSorted={[{ id: TableColumn.ParameterSet, desc: false }]}
          minRows={0}
          pageSize={machineAccountConfiguration?.length}
        />
      </LoadOrError>
      {renderModal()}
    </Portlet>
  );
}

MachineAccountPage.propTypes = {
  variantId: PropTypes.number.isRequired
};

export { MachineAccountPage };
