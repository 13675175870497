import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getLocationIdFromSelectedId } from "../utils/getLocationIdFromSelectedId";
import { getMeterIdFromSelectedId } from "../utils/getMeterIdFromSelectedId";
import { getSearchParamsFromRawEnergyDataPageState } from "../utils/getSearchParamsFromRawEnergyDataPageState";

export interface RawEnergyDataPageState {
  firstDate: DateTime<true>;
  lastDate: DateTime<true>;
  selectedId?: string;
}

const initialPageState: RawEnergyDataPageState = {
  firstDate: DateTime.now().startOf("year"),
  lastDate: DateTime.now().endOf("month"),
  selectedId: undefined
};

export function useRawEnergyDataPageState() {
  const [searchParams, setSearchParams] = useSearchParams();

  const validatedPageState = initialPageState;
  const pageStateKeys = Object.keys(validatedPageState);

  pageStateKeys.forEach((param) => {
    // on render, get search params & do safety constraint checks on them
    const searchParamValue = searchParams.get(param);

    // for any of the desired state props, do basic validation if there's a query param provided
    if (searchParamValue !== null) {
      // eslint-disable-next-line default-case
      switch (param) {
        case "firstDate": {
          const potentialFirstDate = DateTime.fromISO(searchParamValue);
          if (potentialFirstDate.isValid) {
            validatedPageState.firstDate = potentialFirstDate;
            validatedPageState.firstDate =
              validatedPageState.firstDate.startOf("month");
          }
          break;
        }

        case "lastDate": {
          const potentialLastDate = DateTime.fromISO(searchParamValue);
          if (potentialLastDate.isValid) {
            if (potentialLastDate > validatedPageState.firstDate) {
              validatedPageState.lastDate = potentialLastDate;
            } else {
              validatedPageState.lastDate = validatedPageState.firstDate.plus({
                days: 1
              });
            }
            validatedPageState.lastDate =
              validatedPageState.lastDate.endOf("month");
          }
          break;
        }

        case "selectedId": {
          const potentialSelectedId = searchParamValue;

          const potentialSelectedMeterId =
            getMeterIdFromSelectedId(potentialSelectedId);
          const potentialSelectedLocationId =
            getLocationIdFromSelectedId(potentialSelectedId);

          if (
            potentialSelectedMeterId &&
            !isNaN(potentialSelectedMeterId) &&
            potentialSelectedMeterId > 0
          ) {
            validatedPageState.selectedId = potentialSelectedId;
          }
          if (
            potentialSelectedLocationId &&
            !isNaN(potentialSelectedLocationId) &&
            potentialSelectedLocationId > 0
          ) {
            validatedPageState.selectedId = potentialSelectedId;
          }
          break;
        }
      }
    }
  });

  const [pageState, setPageState] = useState(validatedPageState);

  useEffect(() => {
    const updatedSearchParams =
      getSearchParamsFromRawEnergyDataPageState(pageState);
    setSearchParams(updatedSearchParams, { replace: true });
  }, [pageState, setSearchParams]);

  return {
    pageState,
    setPageState
  };
}
