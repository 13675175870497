import { Group, Pill, Stack, Text } from "@mantine/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import urls from "../../../../../urls";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { EnergyDataAcquisitionSelectionModal } from "../../EnergyDataAcquisitionSelectionModal/EnergyDataAcquisitionSelectionModal";
import type { Acquisition, AcquisitionFilter } from "../../EnergyDataView";

interface AcquisitionsProps {
  acquisitions: Array<Acquisition>;
  selectedAcquisitions: Array<number>;
  acquisitionFilters: Array<AcquisitionFilter>;
  definedFilterId: number | null;
  variantId: number;
  onSelectedAcquisitionsChanged: (
    selectedAcquisitions: Array<number>,
    acquisitionFilters: Array<AcquisitionFilter>,
    definedFilterId: number | null
  ) => void;
}

function Acquisitions({
  acquisitions,
  selectedAcquisitions,
  acquisitionFilters,
  definedFilterId,
  variantId,
  onSelectedAcquisitionsChanged
}: AcquisitionsProps) {
  const { projectId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(true);

  function removeAcquisition(acquisitionId: number): void {
    onSelectedAcquisitionsChanged(
      selectedAcquisitions.filter(
        (selectedAcquisitionId) => selectedAcquisitionId !== acquisitionId
      ),
      acquisitionFilters,
      definedFilterId
    );
  }

  const selectedAcquisitionObjects = acquisitions.filter((acquisition) =>
    selectedAcquisitions.includes(acquisition.id)
  );
  const dataUrls = {
    filterOptions: urls.api.energyDataAcquisitionFilterOptions(projectId),
    definedFilters: urls.api.energyDataAcquisitionFilters(projectId)
  };

  return (
    <>
      <Stack
        align={selectedAcquisitions.length === 0 ? "center" : "flex-start"}
        className="Acquisitions"
        gap="md"
      >
        {selectedAcquisitions.length === 0 && (
          <Text>
            Es wurden noch keine Daten für die Anzeige ausgewählt. Wählen Sie
            hier einen Betrachtungszeitraum sowie die gewünschten Energiedaten
            aus.
          </Text>
        )}
        <SpinButton
          color="brand"
          size={selectedAcquisitions.length === 0 ? "lg" : undefined}
          spin={isModalLoading}
          onClick={() => setIsModalOpen(true)}
        >
          Energiedaten auswählen
        </SpinButton>
        {selectedAcquisitionObjects.length > 0 && (
          <Group gap="xs">
            {selectedAcquisitionObjects.map((acquisition) => (
              <Pill
                key={acquisition.id}
                withRemoveButton
                onRemove={() => removeAcquisition(acquisition.id)}
              >
                {acquisition.label}
              </Pill>
            ))}
          </Group>
        )}
      </Stack>
      <EnergyDataAcquisitionSelectionModal
        dataUrls={dataUrls}
        initialAcquisitionFilters={acquisitionFilters}
        initialDefinedFilterId={definedFilterId}
        initialSelectedAcquisitions={selectedAcquisitions}
        isOpen={isModalOpen}
        projectId={projectId}
        variantId={variantId}
        onLoadingDone={() => setIsModalLoading(false)}
        onSelectedAcquisitionsChanged={onSelectedAcquisitionsChanged}
        onToggle={() => setIsModalOpen(!isModalOpen)}
      />
    </>
  );
}

export { Acquisitions };
